import React from 'react';
// import store from 'store'

// @import-components ----------------------------------------------------------
      import { EmptyLayout, MainLayout } from 'components/Layout';
      import PageSpinner from 'components/PageSpinner';
// -----------------------------------------------------------------------------

// @import hooks && others ---------------------------------------------------

      import { QodrrProvider } from 'hooks/QodrrContext';
      import GlobalExceptionHandler from 'hooks/GlobalExceptionHandler';

      import componentQueries from 'react-component-queries';
      import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
      import { PublicRoute, SecureRoute } from 'Routes/Routing';
      import 'styles/reduction.scss';
      import Query from './query';

// ---------------------------------------------------------------------------

class App extends React.Component {

  render() { return (
      <QodrrProvider>
          <BrowserRouter>
              <Switch>
                  <Route path={['/login','/forgot-password','/error-500']}>
                      <EmptyLayout>
                          <GlobalExceptionHandler>
                          <Switch>
                              <Route component={PublicRoute} exact path="/login"></Route>
                              <Route component={PublicRoute} exact path="/forgot-password"></Route>
                              <Route component={PublicRoute} exact path="/error-500"></Route>
                              <Route path="*"> <Redirect to="/dashboard"></Redirect></Route>
                          </Switch>
                          </GlobalExceptionHandler>
                      </EmptyLayout>
                  </Route>
            <Route
              path={[
                '/dashboard',
                '/secure-page',
                '/manage-users',
                '/deployed-strategy',
                '/public-strategy-payment',
                '/active-subscribers',
                '/manage-strategy',
                '/build-strategy',
                '/broker-configuration',
                '/trade-logs',
                '/convergence-status',
                '/rsi-screener',
                '/coi-screener',
                '/manage-subscription',
                '/marketplace',
                '/payment-history',
                '/profile',
                '/contact-us',
                '/quick-resources',
                '/admin-ops',
                '/membership-plans',
              ]}
            >
              <MainLayout breakpoint={this.props.breakpoint}>
                <React.Suspense fallback={<PageSpinner />}>
                  <GlobalExceptionHandler>
                  <Switch>
                    <Route
                      exact
                      path="/dashboard"
                      component={PublicRoute}
                    ></Route>
                    <Route
                      exact
                      path="/secure-page"
                      component={SecureRoute}
                    ></Route>
                    <Route
                      exact
                      path="/manage-users"
                      component={SecureRoute}
                    ></Route>


                    <Route
                      exact
                      path="/deployed-strategy"
                      component={SecureRoute}
                    ></Route>
                    <Route
                      exact
                      path="/public-strategy-payment"
                      component={SecureRoute}
                    ></Route>
                    <Route
                      exact
                      path="/active-subscribers"
                      component={SecureRoute}
                    ></Route>
                    <Route
                      exact
                      path="/manage-strategy"
                      component={SecureRoute}
                    ></Route>
                    <Route
                      exact
                      path="/quick-resources"
                      component={SecureRoute}
                    ></Route>
                    <Route
                      exact
                      path="/build-strategy"
                      component={SecureRoute}
                    ></Route>
                    <Route
                      exact
                      path="/broker-configuration"
                      component={SecureRoute}
                    ></Route>
                    <Route
                      exact
                      path="/convergence-status"
                      component={SecureRoute}
                    ></Route>
                    <Route
                      exact
                      path="/trade-logs"
                      component={SecureRoute}
                    ></Route>
                    <Route
                      exact
                      path="/rsi-screener"
                      component={SecureRoute}
                    ></Route>
                    <Route
                      exact
                      path="/coi-screener"
                      component={SecureRoute}
                    ></Route>
                    <Route
                      exact
                      path="/manage-subscription"
                      component={SecureRoute}
                    ></Route>
                    <Route
                      exact
                      path="/marketplace"
                      component={SecureRoute}
                    ></Route>
                    <Route
                      exact
                      path="/payment-history"
                      component={SecureRoute}
                    ></Route>
                    <Route
                      exact
                      path="/profile"
                      component={SecureRoute}
                    ></Route>
                    <Route
                      exact
                      path="/contact-us"
                      component={SecureRoute}
                    ></Route>
                    <Route
                      exact
                      path="/admin-ops"
                      component={SecureRoute}
                    ></Route>
                    <Route
                       exact
                       path="/membership-plans"
                       component={SecureRoute}
                    ></Route>
                    <Route path="*">
                      <Redirect to="/dashboard"></Redirect>
                    </Route>
                  </Switch>
                  </GlobalExceptionHandler>
                </React.Suspense>
              </MainLayout>
            </Route>

            <Route path="*">
              <Redirect to="/dashboard"></Redirect>
            </Route>
          </Switch>
        </BrowserRouter>
        
      </QodrrProvider>
    )}
}

export default componentQueries(Query)(App);
