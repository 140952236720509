import Cycle from 'assets/img/icon/cycle.svg';
import LoadingOverlay from 'react-loading-overlay';
import Ticon from 'assets/img/icon/table-icon.svg';
import React from 'react';
import Popover from 'components/Popover';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import NotificationSystem from 'react-notification-system';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { tradeService } from 'services/trade_service';
import store from 'store';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';
import Moment from 'react-moment';

class TradeLogs extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      auth: undefined,
      filterPlaceholder: 'Filter Data',
      entry_order_id: '',
      exit_order_id: '',
      entry_exchange_order_id: '',
      exit_exchange_order_id: '',
      modal: false,
      strategyId: '',
      modals: false,
      strategy_name: '',
      trade_side: 0,
      trade_status: '',
      trade_symbol: '',
      trade_exec_type: 0,
      trade_pnl: 0,
      trade_qty: 0,
      trade_exechange_id: '',
      trade_order_id: '',
      trade_broker: '',
      trade_entry_price: 0,
      trade_entry_date: '',
      trade_exit_date: '',
      trade_exit_price: 0,
      point_pnl: 0,
      close_message: '',
      data: [],
      export: true,
      exportHeaders: true,
      fileName: "Trade-log",
      columns: [
        {
          name: 'SR.',
          selector: 'record_id',
          sortable: true,
          width: '80px',
          export: false,
        },
        {
          name: 'Strategy',
          selector: 'strategy_name',
          sortable: true,
        },
        {
          name: 'Symbol',
          selector: 'symbol',
          sortable: true,
        },
        {
          name: 'Execution Type',
          selector: 'exec_type',
          sortable: true,
          cell: d => this.execformatter(d.exec_type),
        },
        {
          name: 'Quantity',
          selector: 'qty',
          sortable: true,
          id: "trade-log-export-column",
        },
        {
          name: 'Broker',
          selector: 'broker',
          sortable: false,
          id: "trade-log-export-column",
        },
        {
          name: 'Trade Side',
          selector: 'signal',
          sortable: true,
          cell: d => this.signalFormatter(d.signal),
        },
        {
          name: 'Entry Exchange ID',
          selector: 'entry_exchange_order_id',
          sortable: true,
          id: "trade-log-export-column",
        },
        {
          name: 'Entry Order ID',
          selector: 'entry_order_id',
          sortable: true,
          id: "trade-log-export-column",
        },
        {
          name: 'Entry Price',
          selector: 'entry_price',
          sortable: true,
          id: "trade-log-export-column",
        },
        {
          name: 'Entry Datetime',
          sortable: true,
          cell: data => {

            if(data.segment === 'fut-opt' || data.segment === 'opt'){

                if(typeof(data.option_entry) !== "object"){ data.option_entry = {} }

                return this.timeFormatterMoment(data.option_entry.date)
            }

            return this.timeFormatterMoment(data.entry_date)
          },
        },
        {
          name: 'Exit Exchange ID',
          selector: 'exit_exchange_order_id',
          sortable: true,
          id: "trade-log-export-column",
        },
        {
          name: 'Exit Order ID',
          selector: 'exit_order_id',
          sortable: true,
          id: "trade-log-export-column",
        },
        {
          name: 'Exit Price',
          selector: 'exit_price',
          sortable: true,
          id: "trade-log-export-column",
        },
        {
          name: 'Exit Datetime',
          sortable: true,
          cell: data => this.timeFormatterMoment(data.exit_date),
          id: "trade-log-export-column",
        },
        {
          name: 'Points PnL',
          selector: 'point_pnl',
          sortable: true,
          cell: d => this.pnlFormatter(d.point_pnl),
          id: "trade-log-export-column",
        },
        {
          name: 'Trade PnL',
          selector: 'trade_pnl',
          sortable: true,
          cell: d => this.pnlFormatter(d.trade_pnl),
        },
        {
          name: 'Status',
          selector: 'status',
          id: "trade-log-export-column"
        },
        {
          name: 'Close Note',
          selector: 'close_message',
          id: "trade-log-export-column",
        },
        {
          name: 'Actions',
          sortable: true,
          export: false,
          cell: d => {

            return (
              <div>
                <button
                  className="btn btn-group"
                  onClick={() =>
                    this.toggle(
                      d.strategy_name,
                      d.signal,
                      d.status,
                      d.symbol,
                      d.exec_type,
                      d.trade_pnl,
                      d.qty,
                      d.exechange_id,
                      d.broker,
                      d.entry_price,
                      d.entry_date,
                      d.exit_date,
                      d.exit_price,
                      d.point_pnl,
                      d.close_message,
                      d.entry_exchange_order_id,
                      d.exit_exchange_order_id,
                      d.entry_order_id,
                      d.exit_order_id,
                      d
                    )
                  }
                >
                  <i
                    className="fa fa-eye"
                    aria-hidden="true"
                    style={{ color: '#0EA5E9' }}
                  ></i>
                </button>

                {d.status === 'OPEN' && (
                  <button
                    className="btn btn-group"
                    onClick={() => this.popmodal(d.strategy_name,d.segment)}
                  >
                    <i
                      className="fa fa-lock"
                      aria-hidden="true"
                      style={{ color: '#000' }}
                    ></i>
                  </button>
                )}
              </div>
            );
          },
        },
      ],
    };
    this.toggle = this.toggle.bind(this);
    this.timeFormatterMoment = this.timeFormatterMoment.bind(this);
    this.popmodal = this.popmodal.bind(this);
    this.userData = store.get('user_data');
    this.handleClickOK = this.handleClickOK.bind(this);
    this.getTradeLogs = this.getTradeLogs.bind(this);
  }

  timeFormatterMoment(cell, row) {
    if (cell !== null || cell !== undefined) {
      return (
        <Moment parse="YYYY-MM-DD HH:mm:ss">{cell}</Moment>
      );
    } else {
      return (
        'NA'
      )
    }
  }

  execformatter(cell) {
    if (cell === 1) {
      return <p>Paper Trade</p>;
    }
    if (cell === 2) {
      return <p>Auto Trade</p>;
    }
    if (cell === 3) {
      return <p>Notify Trade</p>;
    }
  }

  signalFormatter(cell, row) {
    if (cell === 1) {
      return <p style={{ color: 'green' }}>Long</p>;
    } else {
      return <p style={{ color: 'red' }}>Short</p>;
    }
  }
  pnlFormatter(cell, row) {
    if (cell > 0) {
      return <p style={{ color: 'green' }}>{cell}</p>;
    }
    if (cell < 0) {
      return <p style={{ color: 'red' }}>{cell}</p>;
    }

    return 'NA';
  }
  toggle(
    strategy_name = undefined,
    trade_side = undefined,
    trade_status = undefined,
    trade_symbol = undefined,
    trade_exec_type = undefined,
    trade_pnl = undefined,
    trade_qty = undefined,
    trade_exechange_id = undefined,
    trade_broker = undefined,
    trade_entry_price = undefined,
    trade_entry_date = undefined,
    trade_exit_date = undefined,
    trade_exit_price = undefined,
    point_pnl = undefined,
    close_message = undefined,
    entry_exchange_order_id = undefined,
    exit_exchange_order_id = undefined,
    entry_order_id = undefined,
    exit_order_id = undefined,
    object = {}

  ) {


    trade_symbol = object.instrument // default instrument

    if(object.segment === 'fut-opt' || object.segment === 'opt'){

        if(typeof(object.option_entry) !== "object"){ object.option_entry = {} }
        if(typeof(object.option_exit)  !== "object"){ object.option_exit = {} }

        trade_symbol = object.option_entry.instrument
      
        trade_entry_price       =  object.option_entry?.price
        trade_entry_date        =  object.option_entry?.date
        entry_order_id          =  object.option_entry?.order_id
        entry_exchange_order_id =  object.option_entry?.exchange_order_id

        trade_exit_price        =  object.option_exit?.price
        trade_exit_date         =  object.option_exit?.date
        exit_order_id           =  object.option_exit?.order_id
        exit_exchange_order_id  =  object.option_exit?.exchange_order_id
    }

    this.setState({
      modal: true,
      strategy_name,
      trade_side,
      trade_status,
      trade_symbol,
      trade_exec_type,
      trade_pnl,
      trade_qty,
      trade_exechange_id,
      trade_broker,
      trade_entry_price,
      trade_entry_date,
      trade_exit_date,
      trade_exit_price,
      point_pnl,
      close_message,
      entry_exchange_order_id,
      entry_order_id,
      exit_exchange_order_id,
      exit_order_id,
    });
  }
  popmodal(strategyId = undefined,segment =undefined) {
    this.setState({
      modals: !this.state.modals,
      strategyId:
        typeof strategyId === 'string' ? strategyId : this.state.strategyId,
      segment : segment

    });
  }


  getTradeLogs() {

    this.setState({ loading: true });

    tradeService.getTradeLog({ username: this.userData.username }).then(result => {

      if (result.statusCode === '200') this.setState({ loading: false, data: result.response_data });

    }).catch(function (error) { console.log('error occurred while calling API'); });

    this.setState({ loading :false })
  }

  componentDidMount() { this.getTradeLogs(); }



  async handleClickOK() /**  Action close trade */ {

    const notification = { message: 'Trade Closed Successfully!', }
    const strategyName = this.state.strategyId;
    const payload = {
      username: this.userData.username,
      strategy_name: strategyName,
      segment : this.state.segment
    };

    this.setState({ loading: true, strategyId: null, segment : null ,modals: false });

    try {

      const serverResponse = await tradeService.closeTrade(payload)

      if (serverResponse.statusCode === '200') {

        const tradeLogs = await tradeService.getTradeLog({ username: this.userData.username })

        if (tradeLogs.statusCode === '200') this.setState({ data: tradeLogs.response_data });

      } else notification.message = 'Something Went Wrong!'

    } catch (error) { notification.message = 'Something Went Wrong!' }

    this.setState({ loading: false, modals: false });
    this.notificationSystem.addNotification({
      title: <></>,
      message: notification.message,
      level: 'info',
    });
  }

  dataTableCell(data) { return this.setState({ data }); }

  dateFormatter(cell, row) {

    const regexp = /\d/g;

    if (regexp.test(cell)) {

      const date = new Date(cell);

      return (<span>{date.getDate()}/0{date.getMonth() + 1}/{date.getFullYear()}</span>);
    }

    return 'Na';

    // console.clear(); console.clear(); console.log(cell);

    // if (cell !== null || cell !== undefined || cell !== 'NA' || cell !== 'Na' || cell !== 'na') {
    //   const date = new Date(cell);
    //   return (
    //     <span>{date.getDate()}/0{date.getMonth()+1}/{date.getFullYear()}</span>        
    //   );
    // } else {
    //   return (
    //     'NA'
    //   )
    // }
  }

  timeFormatter(cell, row) {

    const regexp = /\d/g;

    if (regexp.test(cell)) {

      const date = new Date(cell);

      return (<span>{date.getHours()}:{date.getMinutes()}:{date.getSeconds()} {(date.getHours() >= 12) ? 'PM' : 'AM'}</span>);
    }

    return 'Na';


    // if (cell !== null || cell !== undefined) {
    //   const date = new Date(cell);
    //   return (
    //     <span>{date.getHours()}:{date.getMinutes()}:{date.getSeconds()} {(date.getHours() >= 12)? 'PM' : 'AM'}</span>        
    //   );
    // } else {
    //   return (
    //     'NA'
    //   )
    // }
  }
  render() {
    return (
      <LoadingOverlay active={this.state.loading} spinner text="Loading content...">
        <div className="container-fluid">
          <div className="data-table">
            <div className="table-head">
              <div className="row">
                <div className="col-md-6 repo-head">
                  <div className="head">
                    <h4>
                      <img className="table-icon" src={Ticon} alt="..." />
                      Trade logs <Popover message="Moving Average Convergence status on Nifty 500 stocks." />
                    </h4>
                  </div>
                </div>
                <div className="col-md-6 repo-head1">
                  <div className="cycle-btn">
                    <button className="btn btn-group" onClick={this.getTradeLogs}>
                      <img src={Cycle} alt="..." />
                    </button>
                  </div>
                </div>
              </div>
              <div className="log-content">
                <p>Information of different trades excuted from our platform for your different strategies.</p>
              </div>
            </div>
            <div className="trade-log">
              <div className="main">
                <DataTableExtensions {...this.state}>
                  <DataTable
                    columns={this.state.columns}
                    data={this.state.data}
                    noHeader
                    defaultSortField="id"
                    paginationRowsPerPageOptions={[10, 20, 50, 100]}
                    defaultSortAsc={false}
                    pagination
                    highlightOnHover
                  />
                </DataTableExtensions>
              </div>
            </div>
          </div>


          <Modal isOpen={this.state.modal} toggle={() => this.setState({ modal: false })} className="modal-lg">
            <ModalHeader toggle={() => this.setState({ modal: false })}>Log Details</ModalHeader>

            <ModalBody className="modal-lg">
              <div className="trade-detail">

                <div className="row">

                  <div className="col-md-4">
                    <div className="log">
                      <div className="detail-one">
                        <div className="d-one">
                          <span>Strategy:</span>
                        </div>
                        <div className="d-two">
                          <span>{this.state.strategy_name}</span>
                        </div>
                      </div>
                      <div className="detail-one">
                        <div className="d-one">
                          <span>Symbol:</span>
                        </div>
                        <div className="d-two">
                          <span>{this.state.trade_symbol}</span>
                        </div>
                      </div>
                    </div>
                  </div>



                  <div className="col-md-4">
                    <div className="log">
                      <div className="detail-one">
                        <div className="d-one">
                          <span>Trade side:</span>
                        </div>
                        <div className="d-two">
                          {this.signalFormatter(this.state.trade_side)}
                        </div>
                      </div>
                      <div className="detail-one">
                        <div className="d-one">
                          <span>Execution Type:</span>
                        </div>
                        <div className="d-two">
                          {this.execformatter(this.state.trade_exec_type)}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="log">
                      <div className="detail-one">
                        <div className="d-one">
                          <span>Status:</span>
                        </div>
                        <div className="d-two">
                          <span className="">
                            {this.state.trade_status}
                          </span>
                        </div>
                      </div>
                      <div className="detail-one">
                        <div className="d-one">
                          <span>Quantity:</span>
                        </div>
                        <div className="d-two">
                          <span>{this.state.trade_qty}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="trade-detail">
                <div className="row">
                  <div className="col-md-4">
                    <div className="log">
                      <div className="detail-one">
                        <div className="d-one">
                          <span>Exchange ID:</span>
                        </div>
                        <div className="d-two">
                          <span>{this.state.entry_exchange_order_id}</span>
                        </div>
                      </div>
                      <div className="detail-one">
                        <div className="d-one">
                          <span>Entry Price</span>
                        </div>
                        <div className="d-two">
                          <span>{this.state.trade_entry_price}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="log">
                      <div className="detail-one">
                        <div className="d-one">
                          <span>Order ID:</span>
                        </div>
                        <div className="d-two">
                          <span>{this.state.entry_order_id}</span>
                        </div>
                      </div>
                      <div className="detail-one">
                        <div className="d-one">
                          <span>Entry Date:</span>
                        </div>
                        <div className="d-two">
                          <span>{this.dateFormatter(this.state.trade_entry_date)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="log">
                      <div className="detail-one">
                        <div className="d-one">
                          <span>Broker:</span>
                        </div>
                        <div className="d-two">
                          <span className="text">{this.state.trade_broker}</span>
                        </div>
                      </div>
                      <div className="detail-one">
                        <div className="d-one">
                          <span>Entry Time:</span>
                        </div>
                        <div className="d-two">
                          <span>{this.timeFormatter(this.state.trade_entry_date)}</span>
                        </div>
                      </div>
                      {/* <div className="detail-one">
                      <div className="d-one">
                        <span>Quantity:</span>
                      </div>
                      <div className="d-two">
                        <span>100</span>
                      </div>
                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="trade-detail">
                <div className="row">
                  <div className="col-md-4">
                    <div className="log">
                      <div className="detail-one">
                        <div className="d-one">
                          <span>Exchange ID:</span>
                        </div>
                        <div className="d-two">
                          <span>{this.state.exit_exchange_order_id}</span>
                        </div>
                      </div>
                      <div className="detail-one">
                        <div className="d-one">
                          <span>Exit Date:</span>
                        </div>
                        <div className="d-two">
                          <span>{this.dateFormatter(this.state.trade_exit_date)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="log">
                      <div className="detail-one">
                        <div className="d-one">
                          <span>Order ID:</span>
                        </div>
                        <div className="d-two">
                          <span>{this.state.exit_order_id}</span>
                        </div>
                      </div>
                      <div className="detail-one">
                        <div className="d-one">
                          <span>Exit Time:</span>
                        </div>
                        <div className="d-two">
                          <span>{this.timeFormatter(this.state.trade_exit_date)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="log">
                      <div className="detail-one">
                        <div className="d-one">
                          <span>Exit Price:</span>
                        </div>
                        <div className="d-two">
                          <span className="text">
                            {this.state.trade_exit_price}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="foot-detail">
                <div className="row">
                  <div className="col-md-4">
                    <div className="f-detail">
                      <div className="detail-one">
                        <div className="d-ones">
                          <span>Points PnL:</span>
                        </div>
                        <div className="d-twos">
                          {/* <span className="text-success"></span> */}
                          {this.pnlFormatter(this.state.point_pnl)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="f-detail">
                      <div className="detail-one">
                        <div className="d-ones">
                          <span>Trade PnL:</span>
                        </div>
                        <div className="d-twos">
                          {this.pnlFormatter(this.state.trade_pnl)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="f-detail">
                      <div className="detail-one">
                        <div className="d-ones">
                          <span>Close Note:</span>
                        </div>
                        <div className="d-twos">
                          <span className="text">{typeof this.state.close_message === 'string' && this.state.close_message.replaceAll('_', ' ')}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>
          <Modal isOpen={this.state.modals} toggle={this.popmodal}>
            <ModalHeader toggle={this.popmodal}>Force Trade Close</ModalHeader>
            <ModalBody>
              <div className="lock-head">
                <h5>Are you sure to force close this trade ?</h5>
              </div>
              <div className="lock-btn">
                <div className="row">
                  <div className="col-md-6 no-btn">
                    <button className="btn btn-group" onClick={this.popmodal}>No</button>
                  </div>
                  <div className="col-md-6 yes-btn">
                    <button
                      className="btn btn-group"
                      onClick={this.handleClickOK}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>
          <NotificationSystem
            dismissible={false}
            ref={notificationSystem =>
              (this.notificationSystem = notificationSystem)
            }
            style={NOTIFICATION_SYSTEM_STYLE}
          />
        </div>
      </LoadingOverlay>
    );
  }
}

export default TradeLogs;
