import moment from 'moment';
import React from 'react';
import Moment from 'react-moment';
import { Col, Row, Table } from 'reactstrap';
import { slideDown, slideUp } from './anim';

function formatDate(date) {
  return moment(date).format('YYYY-MM-DD HH:mm:ss');
}

function capitalize(str) {
  return str
    .replace(/_/g, ' ')
    .split(' ')
    .map(s => {
      return s.charAt(0).toUpperCase() + s.substr(1);
    })
    .join(' ');
}

function isValidDate(d) {
  var date = moment(d, 'YYYY-MM-DD');
  // return moment(moment(d).format('DD-MM-YYYY'),'DD-MM-YYYY',true).isValid()
  return date.isValid();
}

const GetTemplateData = data => {
  return (
    data &&
    Object.keys(data).map(
      function (detail, id) {
        return (
          <Col md={4} key={id}>
            <div className="m-data">
              <p>
                <strong>{capitalize(detail)}</strong> : {data[detail]}
                {/* {isValidDate(data[detail]) ? "Date" : "No"} */}
              </p>
            </div>
          </Col>
        );
      }.bind(this),
    )
  );
};

const DrwaTable = ({ data }) => {
  return (
    <div className="manage-s">
      <div className="SecondTab">
        <Table>
          <thead>
            <tr>
              <th>#</th>
              <th>Trade Size</th>
              <th>Entry Date</th>
              <th>Entry Price</th>
              <th>Exit Date</th>
              <th>Exit Price</th>
              <th>Exit Note</th>
              <th>Points</th>
              <th>Trade Type</th>
              <th>Point PCT</th>
              <th>Trade PNL</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((log, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{log.trade_size}</td>
                    <td>
                      <Moment parse="YYYY-MM-DD HH:mm:ss">
                        {log.entry_date}
                      </Moment>
                    </td>
                    <td>{log.entry_price}</td>
                    <td>
                      <Moment parse="YYYY-MM-DD HH:mm:ss">
                        {log.exit_date}
                      </Moment>
                    </td>
                    <td>{log.exit_price}</td>
                    <td>{log.exit_note}</td>
                     <td style={{ color: log.points < 0 ? 'red': 'green' }} >{log.points}</td>
                    <td>{log.trade_type}</td>
                    <td>{log.point_pct}</td>
                    <td style={{ color: log.trade_pnl < 0 ? 'red': 'green' }} >{log.trade_pnl}</td>
                  </tr>
                );
              })
            ) : (
              <td colSpan="11">No Logs were found!</td>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default class ExpandableTableRow extends React.Component {
  state = { expanded: false };

  toggleExpander = e => {
    if (e.target.type === 'checkbox') return;

    if (!this.state.expanded) {
      this.setState({ expanded: true }, () => {
        if (this.refs.expanderBody) {
          slideDown(this.refs.expanderBody);
        }
      });
    } else {
      slideUp(this.refs.expanderBody, {
        onComplete: () => {
          this.setState({ expanded: false });
        },
      });
    }
  };

  render() {
    const { data, index } = this.props;
    return [
      <tr key="main" onClick={this.toggleExpander}>
        <td>{data ? data.symbol : ''}</td>
        <td className="uk-text-nowrap">{data.start}</td>
        <td>{data.end}</td>
        <td colSpan={3} className="text-right">
          {this.state.expanded ? (
            <i className="fa fa-angle-up" aria-hidden="true"></i>
          ) : (
            <i className="fa fa-angle-down" aria-hidden="true"></i>
          )}
        </td>
      </tr>,
      this.state.expanded && (
        <tr className="expandable" key="tr-expander">
          <td className="uk-background-muted" colSpan={6}>
            <div ref="expanderBody" className="inner uk-grid">
              <Row>{GetTemplateData(data)}</Row>
            </div>
          </td>
        </tr>
      ),
    ];
  }
}

export class ExpandableTableRowLogs extends React.Component {
  state = { expanded: false };

  toggleExpander = e => {
    if (e.target.type === 'checkbox') return;

    if (!this.state.expanded) {
      this.setState({ expanded: true }, () => {
        if (this.refs.expanderBody) {
          slideDown(this.refs.expanderBody);
        }
      });
    } else {
      slideUp(this.refs.expanderBody, {
        onComplete: () => {
          this.setState({ expanded: false });
        },
      });
    }
  };

  render() {
    const { data, index } = this.props;
    return [
      <tr key="main" onClick={this.toggleExpander}>
        <td>{data[0] ? data[0].symbol : ''}</td>
        <td colSpan={6} className="text-right">
          {this.state.expanded ? (
            <i className="fa fa-angle-up" aria-hidden="true"></i>
          ) : (
            <i className="fa fa-angle-down" aria-hidden="true"></i>
          )}
        </td>
      </tr>,
      this.state.expanded && (
        <tr className="expandable" key="tr-expander">
          <td className="uk-background-muted" colSpan={6}>
            <div ref="expanderBody" className="inner uk-grid">
              <DrwaTable data={data.reverse()} />
            </div>
          </td>
        </tr>
      ),
    ];
  }
}
