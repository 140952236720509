import React , { Component } from 'react'
import store from 'store'

// @import-components---------------------------------------------------------------------------------------

    import LoadingOverlay from 'react-loading-overlay';
    import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';
    import NotificationSystem from 'react-notification-system';

    import Icon from 'assets/img/icon/table-icon.svg';
    import Popover from 'components/Popover';

    import PriceCard from 'components/MemberShipPlans/PriceCard';

    import { TabContent, TabPane, Nav, NavItem, NavLink,Row } from 'reactstrap';
    import classnames from 'classnames';

    import * as plans from 'data/plan.js'

    import { tradeService } from 'services/trade_service';

// ---------------------------------------------------------------------------------------------------------

export default class MemberShipPlans extends Component {
    constructor(props){ super(props) 
       this.state = {}

       this.state.loading = false
       this.state.activeTab = '1'
       this.state.plans = plans.default

       this.toggle = this.toggle.bind(this);
       this.subscribe = this.subscribe.bind(this);

    }

    notification(message){
        this.notificationSystem.addNotification({
            title: <></>,
            message: message,
            level: 'info',
        })
    }

    async subscribe(userSelectedPlan) { 

        store.set('plan_type',userSelectedPlan,new Date().getTime() + (10*60*1000)) //store user selected plan 
        store.set('is_membership_renewal',true,new Date().getTime() + (10*60*1000)) //store user selected plan 

        try {

            this.setState({ loading : true })

            const serverResponse = await tradeService.renewMembership({ plan_type : userSelectedPlan })

            if(serverResponse.statusCode === "200") window.location = serverResponse.message

            else throw new Error('');
            
        } catch (error) { this.notification('Something went wrong please try again later') }

        this.setState({ loading : false })
    }

    toggle(tab) /* tab handler */ { if (this.state.activeTab !== tab)  this.setState({ activeTab: tab }); }

    render(){ return (
        <LoadingOverlay spinner active={this.state.loading} text="Loading content...">
            <div className="container-fluid"><div className="data-table">
                <div className="table-head"><div className="row">
                    <div className="col-md-6"><div className="head">
                        <h4>
                            <img className="table-icon" src={Icon} alt="..." />
                            Membership Plans <Popover message="Select membership plan to access qodrr universe" />
                        </h4>
                    </div></div>
                </div></div>

                <div className="w-100 membership-plans-container">
                    <Nav className="w-50 row membership-plans-switch-container">
                        <NavItem className="tab-switch col-md-3">
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggle('1'); }}>Monthly
                            </NavLink>
                        </NavItem>   
                        <NavItem className="tab-switch col-md-3">
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '2' })}
                                onClick={() => { this.toggle('2'); }}>Quaterly
                            </NavLink>
                        </NavItem>
                        <NavItem className="tab-switch col-md-3">
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '3' })}
                                onClick={() => { this.toggle('3'); }}>Halfyearly
                            </NavLink>
                        </NavItem>
                        <NavItem className="tab-switch col-md-3">
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '4' })}
                                onClick={() => { this.toggle('4'); }}>Annually
                            </NavLink>
                        </NavItem>
                    </Nav>
                </div>

                <div className='p-5'>
                    <TabContent activeTab={this.state.activeTab} className="">
                        <TabPane tabId="1">
                            <Row>{ this.state.plans.map((plan,index) => <PriceCard key={index} data={plan} idx={0} method={this.subscribe} ></PriceCard> ) }</Row>
                        </TabPane>
                        <TabPane tabId="2">
                            <Row>{ this.state.plans.map((plan,index) => <PriceCard key={index} data={plan} idx={1} method={this.subscribe} ></PriceCard> ) }</Row>
                        </TabPane>
                        <TabPane tabId="3">
                            <Row>{ this.state.plans.map((plan,index) => <PriceCard key={index} data={plan} idx={2} method={this.subscribe} ></PriceCard> ) }</Row>
                        </TabPane>
                        <TabPane tabId="4">
                            <Row>{ this.state.plans.map((plan,index) => <PriceCard key={index} data={plan} idx={3} method={this.subscribe} ></PriceCard> ) }</Row>
                        </TabPane>
                    </TabContent>
                </div>

                <NotificationSystem
                    dismissible={false}
                    ref={notificationSystem => (this.notificationSystem = notificationSystem)}
                    style={NOTIFICATION_SYSTEM_STYLE}
                ></NotificationSystem>
            </div></div>
      </LoadingOverlay>
    )}
}