import React from 'react'

const PrivateStrategy = () => {
    return(
        <React.Fragment>
            <div className="step-head">
            </div>
        </React.Fragment>
    )
}

export default PrivateStrategy
