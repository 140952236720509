import React, { Component } from 'react';
import { BsLaptop } from 'react-icons/bs';
import { FaCogs, FaRegMoon, FaRegSun } from 'react-icons/fa';

import NotificationSystem from 'react-notification-system';
import LoadingOverlay from 'react-loading-overlay';

import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';


// @import-dependencies ---------------------------------------------------------------

    import { tradeService } from 'services/trade_service';

// ------------------------------------------------------------------------------------

export default class ContactUs extends Component {
    
  constructor(props) {
      super(props);
      this.state = { loader : false };

      this.notification = { message : '' }

    }

  async manageServer(action){

    const userCheck = await tradeService.isUserSessionActive()

    if(userCheck === false){ return this.props.history.push('/login') }

    this.setState({ loader : true });

    try {

        const serverResponse = await tradeService.manageServer(action);

        if (serverResponse.statusCode === 200) {

            if(action ==="START") this.notification.message = "Server Started successfully."

            else this.notification.message = "Server Stopped successfully."
        }  
      
    } catch (error) { console.log('error occured while calling API') }

      this.setState({ loader : false });
      this.notificationSystem.addNotification({
          title: <i className="fa fa-shield"></i>,
          message: this.notification.message,
          level: 'info',
          position: 'br',
      });
  }


  async  startDataFeed(){

    const userCheck = await tradeService.isUserSessionActive()

    if(userCheck === false){ return this.props.history.push('/login') }

    this.setState({ loader : true });

    try {

        const serverResponse = await tradeService.startDataFeed()

          if(serverResponse.statusCode===200) this.notification.message = "Datafeed updation started successfully."

          else this.notification.message = "Datafeed updation not started."
      
    } catch (error) { console.log('error occured while calling API') }

      this.setState({ loader : false });
      this.notificationSystem.addNotification({
          title: <i className="fa fa-shield"></i>,
          message: this.notification.message,
          level: 'info',
          position: 'br',
      });
  }

  async  stopJobs(){

    const userCheck = await tradeService.isUserSessionActive()

    if(userCheck === false){ return this.props.history.push('/login') }

    this.setState({ loader : true });

    try {

        const serverResponse = await tradeService.stopJobs()

          if(serverResponse.statusCode===200) this.notification.message = "All Jobs have been cleared successfully."

          else this.notification.message = "Jobs not cleared."
      
    } catch (error) { console.log('error occured while calling API') }

      this.setState({ loader : false });
      this.notificationSystem.addNotification({
          title: <i className="fa fa-shield"></i>,
          message: this.notification.message,
          level: 'info',
          position: 'br',
      });
  }

  async  updateSymbols(){

    const userCheck = await tradeService.isUserSessionActive()

    if(userCheck === false){ return this.props.history.push('/login') }

    this.setState({ loader : true });

    try {

        const serverResponse = await tradeService.updateSymbols()

          if(serverResponse.statusCode==="200") this.notification.message = "Symbols updated successFully."

          else this.notification.message = "Symbols not updated."
      
    } catch (error) { console.log('error occured while calling API') }

      this.setState({ loader : false });
      this.notificationSystem.addNotification({
          title: <i className="fa fa-shield"></i>,
          message: this.notification.message,
          level: 'info',
          position: 'br',
      });
  }

  async  updateConvergenceData(){

    const userCheck = await tradeService.isUserSessionActive()

    if(userCheck === false){ return this.props.history.push('/login') }

    this.setState({ loader : true });

    try {

        const serverResponse = await tradeService.updateConvergenceData()

          if(serverResponse.statusCode==="200") this.notification.message = "Convergence updated successFully."

          else this.notification.message = "Convergence not updated."
      
    } catch (error) { console.log('error occured while calling API') }

      this.setState({ loader : false });
      this.notificationSystem.addNotification({
          title: <i className="fa fa-shield"></i>,
          message: this.notification.message,
          level: 'info',
          position: 'br',
      });
  }

    async checkIntradayData(){
    
        const userCheck = await tradeService.isUserSessionActive()

        if(userCheck === false){ return this.props.history.push('/login') }

        this.setState({ loader : true });

        try {
 
           const serverResponse = await tradeService.checkIntradayData()

            if(serverResponse.statusCode=== "200"){
                let message = (serverResponse.message == "DELAYED_DATAFEED") ? "Seems issue with datafeed.Kindly check the server" : "Datafeed is upto date";
                this.notification.message = message;
            }else {
                this.notification.message = "something went wrong";
            }             
      
        } catch (error) { console.log('error occured while calling API') }

            this.setState({ loader : false });
            this.notificationSystem.addNotification({
                title: <i className="fa fa-shield"></i>,
                message: this.notification.message,
                level: 'info',
                position: 'br',
        });
  }  

    async cleanOpenTrade(){
    
        if(tradeService.isUserSessionActive() === false){ return this.props.history.push('/login') }

        this.setState({ loader : true });

        try {
 
           const serverResponse = await tradeService.cleanOpenTrade()

            if(serverResponse.statusCode === 200){ this.notification.message = 'Open trade cleaned successfully'; }
            
            else { this.notification.message = "Something went wrong"; }             
      
        } catch (error) { console.log('error occured while calling API') }

            this.setState({ loader : false });
            this.notificationSystem.addNotification({
                title: <i className="fa fa-shield"></i>,
                message: this.notification.message,
                level: 'info',
                position: 'br',
        });
    }

    async cleanRedis(){
    
        if(tradeService.isUserSessionActive() === false){ return this.props.history.push('/login') }

        this.setState({ loader : true });

        try {
 
           const serverResponse = await tradeService.cleanRedis()

            if(serverResponse.statusCode === "200"){ this.notification.message = 'Redis Database Clear successfully'; }
            
            else { this.notification.message = "Something went wrong"; }             
      
        } catch (error) { console.log('error occured while calling API') }

            this.setState({ loader : false });
            this.notificationSystem.addNotification({
                title: <i className="fa fa-shield"></i>,
                message: this.notification.message,
                level: 'info',
                position: 'br',
        });
    }

  render() { return (

    <React.Fragment>
        <LoadingOverlay active={this.state.loader} spinner text="Loading content...">

        <div className="container-fluid"><div className="admin-dash"><div className="row">
          
            <div className="col-md-4"><div className="add-dash" onClick={() => { this.manageServer("START");}}>
                  <div className="card btn-style901 bg-one"><div className="row card-detail">
                      <div className="col-md-3 align">
                          <div className="dash-icon"><FaRegSun /></div>
                      </div>
                      <div className="col-md-9"><div className="d-detail">
                          <h3>Activate Server</h3>
                          <span>Start</span>
                      </div></div>
                  </div></div>
            </div></div>

            <div className="col-md-4"><div className="add-dash" onClick={() => { this.manageServer("STOP");}}>
                  <div className="card btn-style901 bg-two"><div className="row card-detail">
                      <div className="col-md-3 align">
                          <div className="dash-icon"><FaRegMoon /></div>
                      </div>
                      <div className="col-md-9"><div className="d-detail">
                            <h3>Sleep Server</h3>
                            <span>ShutDown</span>
                      </div></div>
                  </div></div>
            </div></div>

            <div className="col-md-4 "><div className="add-dash" onClick={() => { this.startDataFeed();}}> 
                  <div className="card btn-style901 bg-three"><div className="row card-detail">
                      <div className="col-md-3 align">
                          <div className="dash-icon"><FaCogs /></div>
                      </div>
                      <div className="col-md-9"><div className="d-detail">
                            <h3>Datafeed</h3>
                            <span>Update Datafeed</span>
                      </div></div>
                  </div></div>
              </div>
            </div>

            <div className="col-md-4"><div className="add-dash" onClick={() => { this.stopJobs();}}>
                  <div className="card btn-style901 bg-four"><div className="row card-detail">
                      <div className="col-md-3 align"><div className="dash-icon">
                          <BsLaptop />
                        </div></div>
                      <div className="col-md-9"><div className="d-detail">
                          <h3>Jobs</h3>
                          <span>Stop Jobs</span>
                      </div></div>
                  </div></div>
            </div></div>

            <div className="col-md-4 "><div className="add-dash" onClick={() => { this.updateSymbols();}}>
                  <div className="card btn-style901 bg-five"><div className="row card-detail">
                      <div className="col-md-3 align"><div className="dash-icon">
                          <BsLaptop />
                      </div></div>
                      <div className="col-md-9"><div className="d-detail">
                          <h3>Update Symbols</h3>
                          <span>Update Symbols</span>
                      </div></div>
                  </div></div>
            </div></div>

            <div className="col-md-4"> <div className="add-dash" onClick={() => { this.updateConvergenceData();}}>
                  <div className="card btn-style901 bg-six"><div className="row card-detail">
                      <div className="col-md-3 align"><div className="dash-icon">
                          <BsLaptop />
                      </div></div>

                      <div className="col-md-9"><div className="d-detail">
                          <h3>Update Convergence Data</h3>
                          <span>Update Convergence Data</span>
                      </div></div>
                  </div></div>
            </div></div>

            <div className="col-md-4"> <div className="add-dash" onClick={() => { this.checkIntradayData(); }}>
                  <div className="card btn-style901 bg-six"><div className="row card-detail">
                      <div className="col-md-3 align"><div className="dash-icon">
                          <BsLaptop />
                      </div></div>

                      <div className="col-md-9"><div className="d-detail">
                          <h3>Check Intraday Data</h3>
                          <span>Check Intraday Data</span>
                      </div></div>
                  </div></div>
            </div></div>

            <div className="col-md-4"> 
            <div className="add-dash" onClick={() => { this.cleanOpenTrade()  }}>
                  <div className="card btn-style901 bg-four"><div className="row card-detail">
                      <div className="col-md-3 align"><div className="dash-icon">
                          <BsLaptop />
                      </div></div>

                      <div className="col-md-9"><div className="d-detail">
                          <h3>Clean Open Trade</h3>
                          <span>Clean Open Trade</span>
                      </div></div>
                  </div></div>
            </div>
            </div>

            <div className="col-md-4"> 
            <div className="add-dash" onClick={() => { this.cleanRedis()  }}>
                  <div className="card btn-style901 bg-two"><div className="row card-detail">
                      <div className="col-md-3 align"><div className="dash-icon">
                          <BsLaptop />
                      </div></div>

                      <div className="col-md-9"><div className="d-detail">
                          <h3>Clean Redis Data</h3>
                          <span>Clean Redis Data</span>
                      </div></div>
                  </div></div>
            </div>
            </div>
        
        </div></div></div>

          <NotificationSystem
              dismissible={true}
              ref={notificationSystem => (this.notificationSystem = notificationSystem)}
              style={NOTIFICATION_SYSTEM_STYLE}
          ></NotificationSystem>

        </LoadingOverlay>
    </React.Fragment>
    );
  }
}
