import React from 'react'

// @import-components ------------------------------------------------

import StepHeading      from '../StepHeading'
import FormDetails      from '../FormDetails';
import TextField        from '../TextField';
import RadioButtonField from '../RadioButtonField';
import DropDownField    from '../DropDownField';

// -------------------------------------------------------------------

const OrderExecution = props => {

    const PositionSizeContent   = `Position Size:  100 for shares and 2 for Future lots. Eg: if you want to run strategy on Nifty Future with 2 lots, then define 2 as position size. Our system internally pick the current month's contract lot size.`
    const ExecutionTypeContent  = `Execution Type : Use Paper Trade for dummy execution of your strategy in realtime. Use Auto Trade for execution your strategy for realtime trading.it will send you a notification for every order execution to your broker. Use Notify Trade to skip the realtime trade and just get a notification, when you don't want to have a real trade executed in your broker account. Note: Paper trade will not send any notification.`
    const ContinuePreviousTradeContent = `Continue with Previous Trade : Pick an Option, if you really want to ride a long running trade, if you have been out of the trade because of SL hit or Target achieve. Note: Continue with Previous trade : ALWAYS will keep you in the trade always and execute the order. Use it only in case, when you are sure about your strategy performance. `
    const MaxIntradayTradesContent     = `Max Intraday Trade : Cap your daily trade execution for the Strategy. Eg: 3 for having a three trades a day or 0 for No Max Intraday Trade.`

    const content = `Provide order details`

    return (
        <React.Fragment>
            <StepHeading text="Order Execution" id="step-four-order-execution" content={content}></StepHeading>
            <FormDetails>
                <TextField md={6} label="Position Size" name="position_size" method={props.changeHandler}
                error={props.state.position_size.error} value={props.state.position_size.value}
                errorMessage={props.state.position_size.errorMessage} 
                showTooltip={true} toolTipContent={PositionSizeContent}
                ></TextField>

                <DropDownField md={6} label="Execution Type" name="exec_type" data={props.executionType}
                        error={props.state.exec_type.error} method={props.executionTypeHandler}
                        value={props.state.exec_type.value}
                        errorMessage={props.state.exec_type.errorMessage}
                        showTooltip={true} toolTipContent={ExecutionTypeContent}
                ></DropDownField>

                <RadioButtonField md={4}
                    label="Duration" name="duration_type" method={props.changeHandler}
                    error={props.state.duration_type.error} value={props.state.duration_type.value}
                    errorMessage={props.state.duration_type.errorMessage}
                    textOne="DAY" textTwo="IOC"
                    valueOne="DAY" valueTwo="IOC"
                ></RadioButtonField>

                <RadioButtonField md={4}
                    label="Order Type" name="order_type" method={props.changeHandler}
                    error={props.state.order_type.error} value={props.state.order_type.value}
                    errorMessage={props.state.order_type.errorMessage}
                    textOne="LIMIT" textTwo="MARKET"
                    valueOne="LIMIT" valueTwo="MARKET"
                ></RadioButtonField>

                <RadioButtonField md={4}
                    label="Product Type" name="product_type" method={props.changeHandler}
                    error={props.state.product_type.error} value={props.state.product_type.value}
                    errorMessage={props.state.product_type.errorMessage}
                    textOne="INTRADAY" textTwo="DELIVERY"
                    valueOne="INTRADAY" valueTwo="DELIVERY"
                ></RadioButtonField>

                <DropDownField md={6} label="Continue Previous Trade" name="cnt_prev_trade" data={props.continuePrevTrade}
                    error={props.state.cnt_prev_trade.error} value={props.state.cnt_prev_trade.value}
                    errorMessage={props.state.cnt_prev_trade.errorMessage}
                    method={props.changeHandler} showTooltip={true} toolTipContent={ContinuePreviousTradeContent}
                ></DropDownField>

                <TextField md={6} label="Max Intraday Trades" name="intraday_count" method={props.changeHandler}
                    error={props.state.intraday_count.error} value={props.state.intraday_count.value}
                    errorMessage={props.state.intraday_count.errorMessage} showTooltip={true} toolTipContent={MaxIntradayTradesContent}
                ></TextField>
            </FormDetails>
        </React.Fragment>
    )
}

export default OrderExecution