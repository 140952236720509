import React from 'react'
import DropDownField from '../DropDownField'
import StepHeading from '../StepHeading'

const OptionStrategy = (props) => {

    return (
        <React.Fragment>
            <StepHeading text="Option Strategy" id="step-four-data-selection" content="." ></StepHeading>
            <div className="row form-detail">
                <DropDownField
                    label="Strategy Selection"
                    type="select"
                    name="strategySelection"
                    id="strategySelection"
                    md="3"
                    error={props.state.strategySelection.error}
                    value={props.state.strategySelection.value}
                    errorMessage={props.state.strategySelection.errorMessage}
                    method={props.changeHandler}
                    data={props.strategySelection}
                ></DropDownField>
                <DropDownField
                    label="Strike Selection"
                    type="select"
                    name="strikeSelection"
                    id="strikeSelection"
                    md="3"
                    error={props.state.strikeSelection.error}
                    value={props.state.strikeSelection.value}
                    errorMessage={props.state.strikeSelection.errorMessage}
                    method={props.changeHandler}
                    data={props.strikeSelection}
                ></DropDownField>
            </div>
        </React.Fragment>
    )
}

export default OptionStrategy