import React from 'react';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { tradeService } from 'services/trade_service';
import store from 'store';
import Ticon from '../assets/img/icon/table-icon.svg';
import Popover from '../components/Popover';
import LoadingOverlay from 'react-loading-overlay';
class ManageSubscription extends React.Component {
  constructor() {
    super();
    this.state = {
      auth: undefined,
      loading:false,
      data: [],
      export:false,
      columns: [
        {
          name: 'SR.',
          selector: 'record_id',
          sortable: true,
        },
        {
          name: 'Username',
          selector: 'username',
          sortable: true,
        },
        {
          name: 'Email',
          selector: 'email',
          sortable: true,
        },
        {
          name: 'Plan',
          selector: 'plan_name',
          sortable: true,
        },
        {
          name: 'Payment Req ID',
          selector: 'payment_req_id',
          sortable: true,
        },
        {
          name: 'Start Date',
          selector: 'start_date',
          sortable: true,
        },
        {
          name: 'End Date',
          selector: 'end_date',
          sortable: true,
        },
        {
          name: 'Action',
          selector: '',
          sortable: true,
        },
      ],
    };
    this.userData = store.get('user_data');
  }

  loadMembership(uname) {
    this.setState({ loading: true });
    tradeService
      .getUserMembership({ username: uname })
      .then(result => {
        this.setState({ loading: false });
        if (result.statusCode === '200') {
          //console.log(result.data)
          //console.log("Success response collected");
          if (result.statusCode === '200') {
            this.setState({ data: result.data });
          }
        }
      })
      .catch(function (error) {
        console.log('error occured while calling API');
        //console.log(error);
      });
  }

  componentDidMount() {
    var username = '';
    if (username === '') {
      username = store.get('user_data').username;
    }
    console.log('username to fetch :' + username);
    this.setState({ username: username });
    this.loadMembership(username);
  }

  dataTableCell(data) {
    return this.setState({ data });
  }
  render() {
    return (
      <LoadingOverlay
      active={this.state.loading}
      spinner
      text="Loading content..."
    >
      <div className="container-fluid">
        <div className="data-table">
          <div className="table-head">
            <div className="row">
              <div className="col-md-6">
                <div className="head">
                  <h4>
                    <img className="table-icon" src={Ticon} alt="..." />
                    Manage Subscription
                    <Popover message="hey " />
                  </h4>
                </div>
              </div>
              <div className="col-md-6">
                {/* <div className="deploye-btn">
                                          <button className="btn btn-group">
                                            Deploy <img src={Dicon} />
                                          </button>
                                        </div> */}
              </div>
            </div>
          </div>
          <div className="main">
            <DataTableExtensions {...this.state}>
              <DataTable
                columns={this.state.columns}
                data={this.state.data}
                noHeader
                defaultSortField="id"
                defaultSortAsc={false}
                paginationRowsPerPageOptions={[10, 20, 50, 100]}
                pagination
                highlightOnHover
              />
            </DataTableExtensions>
          </div>
        </div>
      </div>
      </LoadingOverlay>
    );
  }
}

export default ManageSubscription;
