import React from 'react';
import store from 'store';
import { Redirect } from 'react-router-dom';


// @import-pages -----------------------------------------------------------------

    import AdminOps            from 'pages/AdminOps';
    import DeployedStrategy    from 'pages/DeployedStrategy'
    import BrokerConfiguration from 'pages/BrokerConfiguration';
    import BuildStrategy       from 'pages/BuildStrategy';
    import COIScreener         from 'pages/COIScreener';
    import ContactUs           from 'pages/ContactUs';
    import ConvergenceStatus   from 'pages/ConvergenceStatus';
    import Login               from 'pages/Login';
    import ManageStrategy      from 'pages/ManageStrategy';
    import ManageSubscription  from 'pages/ManageSubscription';
    import Marketplace         from 'pages/Marketplace';
    import PaymentHistory      from 'pages/PaymentHistory';
    import Profile             from 'pages/Profile';
    import RSIScreener         from 'pages/RSIScreener';
    import ForgotPassword      from 'pages/ForgotPassword';
    import SecurePage          from 'pages/SecurePage';
    import TradeLogs           from 'pages/TradeLogs';
    import HelpCenter          from 'pages/HelpCenter';
    import MemberShipPlans     from 'pages/membershipPlans';
    import Error500            from 'pages/Error500';
    import Routes              from './routes';

    import ActiveSubscribers     from 'pages/admin/ActiveSubscribers';
    import PublicStrategyPayment from 'pages/admin/PublicStrategyPayment';
    import ManageUsers           from 'pages/admin/ManageUsers'

// -------------------------------------------------------------------------------

// @import dashboard pages -------------------------------------------------------------
 
    const Dashboard = React.lazy(() => import('pages/DashboardPage'));

// ---------------------------------------------------------------------------

const PublicRoute = props => {

    if(props.location.pathname === '/dashboard') {

        const authentication = store.get('user_data') === undefined ? undefined : store.get('user_data').auth;

        if(authentication === undefined || authentication.includes('TY4N') || authentication.includes('S6E1')) return <Dashboard {...props}></Dashboard>

      return <AdminOps {...props}></AdminOps>
    }
    
    const search = Routes.filter(route => { return route.path === props.location.pathname; })[0];

    const component = search === undefined ? undefined : search.component;  

    return ( <Template routeProps={props} permissions={{}} component={component} ></Template>);
};


const SecureRoute = props => {

    const authentication = store.get('user_data') === undefined ? undefined : store.get('user_data').auth;

    const response = {
        component: undefined,
        permission: false,
        authorized: 'TY4N',
        path: props.location.pathname,
    };

    if (authentication !== undefined) {
        if (authentication.includes('S6E1')) response.authorized = 'S6E1';
        else if (authentication.includes('BSN6')) response.authorized = 'BSN6';
    }

  // @access-logic ------------------------------------------------------------------------------------------

        const search = Routes.filter(route => { return route.path === props.location.pathname; })[0];

        if (search === undefined) return (<Template routeProps={props} permissions={{}} component={undefined}></Template> );

        if (search.authorized.includes(response.authorized)) {
              response.permission = true;
              response.component = search.component;
        }

  // --------------------------------------------------------------------------------------------------------

      if (authentication === undefined) return ( <Template routeProps={props} permissions={{}} component={undefined} ></Template>);
 
      if (response.permission === false) return (<Template routeProps={props} permissions={{}} component={undefined} ></Template>);

      return ( <Template routeProps={props} permissions={{}} component={response.component} ></Template>
  );
};

const Template = props => {
    switch (props.component) {
        case 'login-page'      :      return <Login {...props.routeProps}></Login>;
        case 'quick-resources' :      return <HelpCenter {...props.routeProps}></HelpCenter>;
        case 'secure-page'     :      return <SecurePage {...props.routeProps}></SecurePage>;
        case 'error-500'       :      return <Error500   {...props.routeProps}></Error500>
        case 'manage-strategy' :      return <ManageStrategy {...props.routeProps}></ManageStrategy>;
        case 'trade-logs'      :      return <TradeLogs {...props.routeProps}></TradeLogs>;
        case 'convergence-status' :   return <ConvergenceStatus {...props.routeProps}></ConvergenceStatus>;
        case 'rsi-screener'       :   return <RSIScreener {...props.routeProps}></RSIScreener>;
        case 'build-strategy'     :   return <BuildStrategy {...props.routeProps}></BuildStrategy>;
        case 'broker-configuration' : return <BrokerConfiguration {...props.routeProps}></BrokerConfiguration>;
        case 'coi-screener'         : return <COIScreener {...props.routeProps}></COIScreener>;
        case 'manage-subscription'  : return <ManageSubscription {...props.routeProps}></ManageSubscription>;
        case 'marketplace'          : return <Marketplace {...props.routeProps}></Marketplace>;
        case 'payment-history'      : return <PaymentHistory {...props.routeProps}></PaymentHistory>;
        case 'profile'              : return <Profile {...props.routeProps}></Profile>;
        case 'contact-us'           : return <ContactUs {...props.routeProps}></ContactUs>;
        case 'admin-ops'            : return <AdminOps {...props.routeProps}></AdminOps>;
        case 'forgot-password'      : return <ForgotPassword {...props.routeProps}></ForgotPassword>;
        case 'membership-plans'     : return <MemberShipPlans {...props.routeProps}></MemberShipPlans>
        case 'deployed-strategy'    : return <DeployedStrategy {...props.routeProps}></DeployedStrategy>
        case 'public-strategy-payment' : return <PublicStrategyPayment {...props.routeProps}></PublicStrategyPayment>
        case 'active-subscribers'      : return <ActiveSubscribers {...props.routeProps}></ActiveSubscribers>
        case 'manage-users'            : return <ManageUsers {...props.routeProps}></ManageUsers>

        
        default: return <Redirect to="/dashboard"></Redirect>;
  }
};

export { SecureRoute, PublicRoute };
