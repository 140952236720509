const routes = [
  {
    path: '/login',
    name: 'Login Page',
    component: 'login-page',
    authorized: 'TY4N|S6E1|BSN6',
  },
  {
    path: '/secure-page',
    name: 'Secure Page',
    component: 'secure-page',
    authorized: 'S6E1|BSN6',
  },
  {
    path: '/manage-users',
    name: 'Manage Users',
    component: 'manage-users',
    authorized: 'S6E1|BSN6',
  },
  {
    path: '/deployed-strategy',
    name: 'Deployed Strategy',
    component: 'deployed-strategy',
    authorized: 'S6E1|BSN6',
  },
  {
    path: '/public-strategy-payment',
    name: 'Public Strategy Payment',
    component: 'public-strategy-payment',
    authorized: 'S6E1|BSN6',
  },
  {
    path: '/active-subscribers',
    name: 'Active Subscribers',
    component: 'active-subscribers',
    authorized: 'S6E1|BSN6',
  },
  {
    path: '/error-500',
    name: 'Error 500',
    component: 'error-500',
    authorized: 'TY4N|S6E1|BSN6',
  },
  {
    path: '/manage-strategy',
    name: 'Manage Strategy',
    component: 'manage-strategy',
    authorized: 'TY4N|S6E1',
  },
  {
    path: '/quick-resources',
    name: 'Quick Resources',
    component: 'quick-resources',
    authorized: 'TY4N|S6E1',
  },
  {
    path: '/trade-logs',
    name: 'Trade Logs',
    component: 'trade-logs',
    authorized: 'TY4N|S6E1',
  },
  {
    path: '/convergence-status',
    name: 'Convergence Status',
    component: 'convergence-status',
    authorized: 'TY4N|S6E1',
  },
  {
    path: '/rsi-screener',
    name: 'RSI Screener',
    component: 'rsi-screener',
    authorized: 'TY4N|S6E1',
  },
  {
    path: '/build-strategy',
    name: 'Build Strategy',
    component: 'build-strategy',
    authorized: 'TY4N|S6E1',
  },
  {
    path: '/broker-configuration',
    name: 'Broker Configuration',
    component: 'broker-configuration',
    authorized: 'TY4N|S6E1',
  },
  {
    path: '/coi-screener',
    name: 'COI Screener',
    component: 'coi-screener',
    authorized: 'TY4N|S6E1',
  },
  {
    path: '/manage-subscription',
    name: 'Manage Subscription',
    component: 'manage-subscription',
    authorized: 'TY4N|S6E1',
  },
  {
    path: '/marketplace',
    name: 'Marketplace',
    component: 'marketplace',
    authorized: 'TY4N|S6E1',
  },
  {
    path: '/payment-history',
    name: 'Payment History',
    component: 'payment-history',
    authorized: 'TY4N|S6E1',
  },
  {
    path: '/profile',
    name: 'Profile',
    component: 'profile',
    authorized: 'TY4N|S6E1',
  },
  {
    path: '/contact-us',
    name: 'contact',
    component: 'contact-us',
    authorized: 'TY4N|S6E1',
  },
  {
    path: '/membership-plans',
    name: 'membership-plans',
    component: 'membership-plans',
    authorized: 'TY4N|S6E1',
  },
  {
    path: '/admin-ops',
    name: 'Admin Ops',
    component: 'admin-ops',
    authorized: 'S6E1|BSN6',
  },
  { 
    path: '/forgot-password',            
    name: 'ForgotPassword',            
    component: 'forgot-password',                
    authorized: 'TY4N|S6E1|BSN6',
  },
];

export default routes;
