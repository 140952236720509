import React from 'react'

// @import-components ------------------------------------------------

    import StepHeading from '../StepHeading'
    import FormDetails from '../FormDetails';
    import RadioButtonField from '../RadioButtonField';

// -------------------------------------------------------------------

const OrderExecutionTwo = props => {
  return (
    <React.Fragment>
        <StepHeading text="Strategy Configuration" id="step-four-order-execution-two" content="Provide strategy configuration"></StepHeading>
        <FormDetails>
            <RadioButtonField md={4}
                      label="Include Gap" name="includeGap" method={props.changeHandler}
                      error={props.state.includeGap.error} value={props.state.includeGap.value}
                      errorMessage={props.state.includeGap.errorMessage}
                      textOne="Yes" textTwo="No"
                      valueOne="YES" valueTwo="NO"
              ></RadioButtonField>
            <RadioButtonField md={4}
                    label="Startegy Status" name="status" method={props.changeHandler}
                    error={props.state.status.error} value={props.state.status.value}
                    errorMessage={props.state.status.errorMessage}
                    textOne="Active" textTwo="In-active"
                    valueOne="ACTIVE" valueTwo="INACTIVE"
            ></RadioButtonField>
            <RadioButtonField md={4}
                    label="Directional trade (Optional)" name="directionalTrade" method={props.changeHandler}
                    error={props.state.directionalTrade.error} value={props.state.directionalTrade.value}
                    errorMessage={props.state.directionalTrade.errorMessage}
                    textOne="Long Only" textTwo="Short Only"
                    valueOne="LongOnly" valueTwo="ShortOnly"
            ></RadioButtonField>
        </FormDetails>
    </React.Fragment>
  )
}

export default OrderExecutionTwo