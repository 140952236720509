import React from 'react';

// @import-components ----------------------------------------------------------------------------------

    import icon from 'assets/img/icon/detail.svg';
     
    import { FormGroup, Label, Input , Col ,FormFeedback ,PopoverBody, Popover  } from 'reactstrap';

// -----------------------------------------------------------------------------------------------------

const DropDownField = (props) => { const [toolTip,toggleToolTip] = React.useState(false); return(

      <Col md={props.md}>
          <FormGroup>
              
                { props.showTooltip === true  && 
                  
                    <Label htmlFor={props.id}>{props.label} &nbsp;
                        <img onMouseOver={() => toggleToolTip(true)} onMouseOut={() => toggleToolTip(false)} src={icon} alt="..." id={props.name === undefined ? 'no-data' : props.name } />
                    </Label> 
                } 

                { props.showTooltip === true && 
                
                    <Popover placement="right" className="img-text" isOpen={toolTip} autohide={false} target={props.name === undefined ? 'no-data' : props.name } toggle={() => toggleToolTip(!toolTip)}>
                        <PopoverBody className="content_img img-text">{ props.toolTipContent }</PopoverBody>
                    </Popover>
                }

                { !props.showTooltip && <Label htmlFor={props.id}>{props.label} </Label> } 

              <Input onChange={ (event) => props.method(event.target)} invalid={props.error ? true : false} value={props.value} type='select' className="first-show" name={props.name} id={props.id}>
                  <option value="">-- Select --</option>
                  { props.data !== undefined && props.data.map((data,idx) => <option key={idx} value={data.value}>{ data.label }</option>) }
              </Input>
              <FormFeedback>{props.errorMessage}</FormFeedback>
          </FormGroup>
        </Col>
    )}

export default DropDownField