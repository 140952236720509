import Ticon from 'assets/img/icon/table-icon.svg';
import * as Brokers from 'data/brokers.json';
import LoadingOverlay from 'react-loading-overlay';
import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
// ---------------------------------------------------------------------------

// @import-dependencies ------------------------------------------------------
      
    import { tradeService } from 'services/trade_service';
    import store from 'store';

// @import-components && icons -----------------------------------------------

    import Popover from '../components/Popover';

    import NotificationSystem from 'react-notification-system';
    import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';

// ---------------------------------------------------------------------------

// static components --------------------------------------------------------------------------------------------------------------------------------------------------------------

const SelectBroker = ({ brokers, selectBroker }) => { return (

    <div className="col-md-4">
      <FormGroup>
          <Label for="exampleSelect">Brokers</Label>
          <Input type="select" className="first-shows" onChange={(event) => selectBroker(event.target)} name="select-broker" id="select-broker">
              <option value="">Select broker</option>
              {   Object.keys(brokers).map((broker, idx) => ( <option key={idx} value={brokers[broker].key}> {brokers[broker].label} </option> )) }
          </Input>
      </FormGroup>
    </div>
)};

const BrokerField = ({ data, handleChange }) => {

  return (
    <div className="col-md-4">
        <FormGroup>
            <Label for="exampleEmail">{data.label}</Label>
            <Input type={data.type} required={data.required} value={data.value} name={data.key} onChange={event => handleChange({ name: data.key, value: event.target.value }) }></Input>
      </FormGroup>
    </div>
  );
};

const BrokerFieldsContainer = ({ brokerFields , handleChange , handleSubmit }) => { return (

    <div className="col-md-12">
        <form className="row" onSubmit={event => handleSubmit(event)}>
            { brokerFields.map((data,idx) => <BrokerField data={data} handleChange={handleChange} key={idx}></BrokerField> ) }
          
            <div className="col-md-12"><div className="submit-btn">
              { brokerFields.length !== 0 && <button className="btn btn-group">Submit</button> }
            </div></div>
      </form>
    </div>
)}

// -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

class BrokerConfiguration extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: store.get('user_data'),
            brokerConfig : {},
            configData   : undefined,
            loader       : false,

            userselecteBroker : '',
            userselectBrokerFields : [],
            userConnectedBrokers   : []
        };

      this.selectBroker = this.selectBroker.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {

      this.setBrokersList() // update brokers state from store js
      this.getBrokersList() // call brokers api if data is unavailable
      this.getUserBrokersList() // fetch user connected brokers list
  }

  setBrokersList(){
    
    try {

      if(store.get('brokers_list') === undefined) throw Error('');

      this.setState({ brokerConfig : store.get('brokers_list') })
      
    } catch (error) { console.log('An error occurred while connecting to API'); }
  }

  async getBrokersList(){

    try {

      if(store.get('brokers_list') === undefined){

        const serverResponse = await tradeService.getBrokersList()
        
        if(serverResponse.statusCode !== "200" || serverResponse.data === undefined) throw Error('');

        if(typeof serverResponse.data !== 'object') throw Error('');

          this.setState({ brokerConfig : serverResponse.data })

          store.set('brokers_list',serverResponse.data, new Date().getTime() + 8 * 60 * 60 * 1000);
      }
      
    } catch (error) { console.log('An error occurred while connecting to API'); }
  }

  async getUserBrokersList() {

    try {

      const serverResponse = await tradeService.getBrokerConfig()

      if(serverResponse.statusCode !== "200" || serverResponse.data === undefined) throw Error('');

          this.setState({ userConnectedBrokers : serverResponse.data.brokers, })

          store.set('user_brokers',{ brokers : serverResponse.data.brokers }, new Date().getTime() + 1 * 60 * 60 * 1000);

      } catch (error) { console.log('An error occurred while connecting to API'); }
  }


  selectBroker({ name , value }) {

    this.setState({ 
        userselecteBroker : value , 
        userselectBrokerFields : value === '' ? [] : ((fields,brokers,selectedBroker) => { 

          if(brokers[selectedBroker] === undefined) return fields; /* if broker is not in user brokers list then it will return fileds with default null value */

          for (const [key , value] of Object.entries(brokers[selectedBroker])) {

                const row = fields.findIndex(row => row.key === key);

                fields[row].value = value
          }

          return fields; /* if broker is already their in list then fileds will return with the stored values */
            
          })(this.state.brokerConfig[value].fields,this.state.userConnectedBrokers,value)
      })
  }

  handleChange({ name, value }) {

    const userselectBrokerFields = [...this.state.userselectBrokerFields]

      for (const broker of userselectBrokerFields) {

          if(broker.key === name) broker.value = value 
      }

    this.setState({  userselectBrokerFields: userselectBrokerFields }); // update payload array
  }


  generatePayload() {

    if(this.state.userselectBrokerFields.length === 0 || this.state.userselecteBroker === '') return { status : false , payload : {} , error : 'Please select broker' }

    const brokerData = {}; const payload = {}; const userConnectedBrokers = { ...this.state.userConnectedBrokers }
    
    for (const textField of this.state.userselectBrokerFields) { brokerData[textField.key] = textField.value }

      userConnectedBrokers[this.state.userselecteBroker] = brokerData;

      payload.username = this.state.userData.username
      payload.brokers  = userConnectedBrokers

    return { status : true , payload , error : '' }
  }

  async handleSubmit(event) {

    event.preventDefault(); this.setState({ loader : true }); this.generatePayload();

    const userCheck    = tradeService.isUserSessionActive();
    const validation   = this.generatePayload();
    const notification = { message:'Configuration saved successfully!', lavel : 'info' };

      try {

        if (userCheck === false) return this.props.history.push('/login');

        if(validation.status === false) throw Error(validation.error);

            const serverResponse = await tradeService.saveBrokerConfig(validation.payload);

            if(serverResponse.statusCode !== '200') throw Error('An error occurred while connecting to API');

            this.setState({ userConnectedBrokers : validation.payload.brokers, }); // update user connected brokers

            store.set('user_brokers',{ brokers : validation.payload.brokers }, new Date().getTime() + 1 * 60 * 60 * 1000);
      
    } catch (error) { notification.message = error.message }

    this.setState({ loader : false });
    this.notificationSystem.addNotification({
          title: <i className="fa fa-shield"></i>,
          message: notification.message,
          level: notification.lavel,
          position: 'br',
    });
 
  }

  render() {
    return (
      <React.Fragment>
        <LoadingOverlay active={this.state.loader} spinner text="Loading content...">
        <div className="container-fluid"><div className="configuration">
            <div className="row">
              <div className="col-md-6"><div className="configuration-head">
                  <h4>
                    <img className="table-icon" src={Ticon} alt="..." />
                    Broker Configuration <Popover message="Manage broker specific credentials to connect with their API" />
                  </h4>
                </div></div>
            </div>


            <div className="configuration-detail">
                <SelectBroker
                    brokers={this.state.brokerConfig}
                    selectBroker={this.selectBroker}
                ></SelectBroker>

                <BrokerFieldsContainer
                    brokers={this.state.brokerConfig}
                    selectedBroker={this.state.userselecteBroker}
                    brokerFields={this.state.userselectBrokerFields}

                    handleSubmit={this.handleSubmit}
                    handleChange={this.handleChange}
                ></BrokerFieldsContainer>
            </div>

            <NotificationSystem
                dismissible={true}
                ref={notificationSystem => (this.notificationSystem = notificationSystem)}
                style={NOTIFICATION_SYSTEM_STYLE}
            ></NotificationSystem>

        </div></div>
        </LoadingOverlay>
      </React.Fragment>
    );
  }
}

export default BrokerConfiguration;
