import React from 'react';


// @import-nivo-components -----------------------------------------------

    import { ResponsiveLine } from '@nivo/line';
    import { BasicTooltip } from '@nivo/tooltip';


// -----------------------------------------------------------------------

// @chart-custom-components ----------------------------------------------

   const Tooltip = ({ point }) => { 

    const id = <><b>Date : </b>{point.data.x} <b>Amount</b> : {point.data.y} </>; 
    
    return(
        <BasicTooltip  
          id={id}
          color={point.serieColor}
          enableChip
        ></BasicTooltip> 
    )}

// -----------------------------------------------------------------------


const LineChart = ({ data , startDate,endDate /* see data tab */ }) => (
  <React.Fragment>

    <div className="Line-head"><div className="row">
        <div className="col-md-6"><div className="head">
            <h4>P&L Curve</h4>
        </div></div>

        <div className="col-md-6"><div className="date"><div className="line-date">
              <i className="fa fa-calendar-o" aria-hidden="true"></i>
              <p>{startDate} , { endDate } </p>
        </div></div></div>
    </div></div>

    <ResponsiveLine
      data={data} tooltip={Tooltip}
      margin={{ top: 10, bottom: 180, left: 80 ,right: 50 }}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: true,
        reverse: false,
      }}
      yFormat=" >-.2f"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: 'bottom',
        tickSize: 5,
        tickPadding: 0,
        tickRotation: 0,
        legend: 'Date',
        legendOffset: 40,
        legendPosition: 'middle',
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'PnL(₹)',
        legendOffset: -65,
        legendPosition: 'middle',
      }}
      colors= {['#28a745']}
      pointSize={10}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      colorBy={d => d.color}
      symbolBorderColor="rgba(0, 0, 0, .5)"
      pointLabelYOffset={-12}
      areaOpacity={0.1}
      useMesh={true}
      legends={[]}
      enableArea={true}
      
    />
  </React.Fragment>
);

export default LineChart;


