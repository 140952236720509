import Popover from 'components/Popover';
import React from 'react';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import NotificationSystem from 'react-notification-system';
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { tradeService } from 'services/trade_service';
import store from 'store';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';
import Ticon from '../assets/img/icon/table-icon.svg';
import LoadingOverlay from 'react-loading-overlay';

class Marketplace extends React.Component {
  constructor() {
    super();
    this.popmodal = this.popmodal.bind(this);
    this.state = {
      monthlyPrice : 0,
      quarterlyPrice : 0,
      halfyearlyPrice : 0,
      annualPrice: 0,
      code : '',
      payment_term: 'MONTHLY',
      auth: undefined,
      modal: false,
      data: [],
      columns: [
        {
          name: 'Strategy Name',
          sortable: true,
          width: '15%',
          selector: data => data.strategy_name,
        },
        {
          name: 'Strategy Subject',
          sortable: true,
          width: '15%',
          selector: data => data.strategy_subject,
        },
        {
          name: 'Strategy Description',
          sortable: true,
          width: '30%',
          selector: data => data.strategy_desc,
        },
        {
          name: 'Strategy Summary',
          sortable: true,
          width: '20%',
          selector: data => data.strategy_summary,
        },
        {
          name: 'Action',
          sortable: false,
          width: '10%',
          selector: data => (
            <button className="btn btn-group" onClick={() => this.popmodal(data)} >
              <i className="fa fa-credit-card"></i>
            </button>
          ),
        },
      ],
    };
    this.userData = store.get('user_data');
  }




  async subscribeStrategy() {
      
    const notification = { message : 'Redirecting to payment page' }; 

    try {

        const serverResponse = await tradeService.subscribeStrategy({
            username : store.get('user_data').username,
            code     : this.state.code,
            payment_term : this.state.payment_term
        })

        if (serverResponse.statusCode !== "200") throw new Error('')

            store.set("plan_data", this.state.code); 
            window.location = serverResponse.message
        
    } catch (error) { notification.message = 'Error Occurred while processing subscription'; }

      this.notificationSystem.addNotification({
          title: <i className="fa fa-shield"></i>,
          message: notification.message,
          level: 'info',
      });
  }


  loadStrategy() {
      tradeService.getMarketPlaceStrategy({username: store.get('user_data').username }).then(result => {
        
        this.setState({ loading: false });
        
        if (result.statusCode === '200') { this.setState({ data: result.data }); }
      
      }).catch(function (error) { this.setState({ loading: false }); console.log('error occurred while calling API'); });
  }

  componentWillMount() { this.setState({ loading: true });  this.loadStrategy(); }

  dataTableCell(data) {  return this.setState({ data }); }

  popmodal(data) {  
    this.setState({  
        modal: true, code : data.code, strategyName : data.strategy_name,
        monthlyPrice    : data.strategy_price[0] === undefined ? 0 : data.strategy_price[0].monthly, 
        quarterlyPrice  : data.strategy_price[1] === undefined ? 0 : data.strategy_price[1].quarterly,
        halfyearlyPrice : data.strategy_price[2] === undefined ? 0 : data.strategy_price[2].halfyearly,
        annualPrice     : data.strategy_price[3] === undefined ? 0 : data.strategy_price[3].annual,
    }) 
  }


  render() { return (
      <LoadingOverlay  active={this.state.loading} spinner text="Loading content...">
      <div className="container-fluid"><div className="data-table">
          <div className="market_place">
            <div className="table-head">
                <div className="row">
                  <div className="col-md-6 repo-head"><div className="head">
                    <h4><img className="table-icon" src={Ticon} alt="..." />Market Place
                        <Popover message="Check Our Strategy Marketplace for ready-made strategies." />
                    </h4>
                  </div></div>
                  <div className="col-md-6 repo-head1"></div>
              </div>
              <div className="log-content">
                <p>Marketplace for custom curated well researched strategies by experts. Simply Subscribe and access it.</p>
              </div>
              <div className="log-content">
                <span>
                <b>Note:</b> Margin allocation required per lot of Index/Stock futures on the Platform is purely suggestive in nature based on past performances of a particular system. Users are required to apply personal discretion and maintain adequate margins at all times per their brokers’ requirements as past performance does not in any way guarantee current or future adequacy of margins.

Users may note that the Index/Stock futures are leveraged products by their very nature with commensurate risk of draw-downs. Thus, Users must apply personal discretion while deciding on the quantity traded and margin allocation to their accounts.
                </span>
              </div>
            </div>
            <div className="main">
              <DataTableExtensions {...this.state}>
                <DataTable
                  columns={this.state.columns}
                  data={this.state.data}
                  noHeader
                  defaultSortField="id"
                  paginationRowsPerPageOptions={[10, 20, 50, 100]}
                  defaultSortAsc={false}
                  pagination
                  highlightOnHover
                />
              </DataTableExtensions>
              <NotificationSystem
                dismissible={false}
                ref={notificationSystem =>
                  (this.notificationSystem = notificationSystem)
                }
                style={NOTIFICATION_SYSTEM_STYLE}
              />
            </div>
          </div>

        <Modal isOpen={this.state.modal} toggle={() => { this.setState({ modal : false }) }} className="modal-lg">
            <ModalHeader toggle={() => { this.setState({ modal : false }) }}>Strategy Pricing ({ this.state.strategyName })</ModalHeader>
            <ModalBody className="modal-lg">
                <div className="marketPlace"><div className="row">
                    <div className='col-md-12 row m-1'>
                        <div className='col-md-3 text-center'><b>Monthly price </b><br></br><i className="fa fa-inr"></i> { this.state.monthlyPrice }</div>
                        <div className='col-md-3 text-center'><b>Quarterly price </b><br></br><i className="fa fa-inr"></i> { this.state.quarterlyPrice }</div>
                        <div className='col-md-3 text-center'><b>Half yearly price </b><br></br><i className="fa fa-inr"></i> { this.state.halfyearlyPrice }</div>
                        <div className='col-md-3 text-center'><b>Annual price </b><br></br> <i className="fa fa-inr"></i> { this.state.annualPrice }</div>
                    </div>
                    <div className='col-md-3'></div>
                    <div className="col-md-6">
                    <FormGroup>
                      <Label for="exampleEmail">&nbsp;&nbsp;</Label>
                      <Input type="select" name="Monthly" placeholder="Monthly" onChange={(e) => { this.setState({ payment_term : e.target.value }) } }>
                          <option value="MONTHLY">Monthly price</option>
                          <option value="QUARTERLY">Quarterly price</option>
                          <option value="HALFYEARLY">Half Yearly price</option>
                          <option value="ANNUAL">Annual price</option>
                      </Input>
                    </FormGroup>
                    </div>
                    <div className='col-md-3'></div>
                </div></div>
            </ModalBody>
            <ModalFooter>
                <div className='w-100' style={{ color:'#939393' }}>Note : Prices are exclusive of GST 18%</div>
                <Button className="btn btn-group" onClick={() => this.subscribeStrategy()}>Submit</Button>
            </ModalFooter>
        </Modal>
        
      </div></div>
      </LoadingOverlay>
    );
  }
}

export default Marketplace;
