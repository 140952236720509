import { Component } from 'react'
import { withRouter } from 'react-router-dom';
 
class GlobalExceptionHandler extends Component {

    constructor(props){ super(props) 

       this.state = { environment : "production" }

       this.errorHandler = this.errorHandler.bind(this)  
       this.consoleHandler()
    }

    componentDidMount(){ this.consoleHandler() }

    componentDidMount(){

        import('react-error-overlay').then(module => {
            module.stopReportingRuntimeErrors();  /** prevent react error screen frame */
        });

        window.addEventListener('error',this.errorHandler);
    }

    consoleHandler() {

        if(this.state.environment === 'development') return true;

        console.log    = () => { return null } /** update the console log object to null function */
        console.error  = () => { return null } /** update the console error object to null function */
        console.warn   = () => { return null } /** update the console warn object to null function */
        console.clear  = () => { return null } /** update the console clear object to null function */
    }

    errorHandler(errorObject) {

        const {error} = errorObject;
        const routerProps  = this.props;

        if (!error.captured) {
            
            error.captured = true;
            errorObject.stopImmediatePropagation();
            errorObject.preventDefault();

            setTimeout(()=> {
                if (!error.shouldIgnore) { routerProps.history.push('/error-500') }
            })
        }
    }

    render() { return ( this.props.children )}
}

export default withRouter(GlobalExceptionHandler);