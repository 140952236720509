import React from 'react'

const Footer = (props) => {

    const nextButtonClasses     =  props.nextStep === false ? false : props.index < 3   ? true : false

    const submitButtonClasses = false

    const previousButtonClasses = props.index === 4 && props.status === false ? true : false
    const redirectButtonClasses = props.index === 4 ? true : false
    const restartButtonClasses  = props.index === 4 && props.status === true ? true : false

    return (
        <React.Fragment>
            <div className="button-group">
                { previousButtonClasses && <button id="previous" className="button" type="button" onClick={(e) => props.previous(e)} >Back</button> }
                { nextButtonClasses     && <button id="next"     className="button" type="button" onClick={(e) => props.next(e)    } >Next</button> }
                { submitButtonClasses   && <button id="submit"   className="button" type="button" onClick={(e) => props.submit(e)  } >Submit</button> }
                { redirectButtonClasses && <button id="previous" className="button" type="button" onClick={(e) => props.redirect(e)} >Go back to dashboard</button> }
                { restartButtonClasses  && <button id="next"     className="button" type="button" onClick={(e) => props.reset(e)    } >Build another strategy</button>}
            </div>
        </React.Fragment>
    )
}

export default Footer

