import React from 'react';
import store from 'store';


// @import-icons && css ---------------------------------------------------------------------

    import Popover from '../components/Popover';
    import Dicon from 'assets/img/icon/dicon.svg';
    import Ticon from 'assets/img/icon/table-icon.svg';
    import 'react-data-table-component-extensions/dist/index.css';

// -------------------------------------------------------------------------------------------


import CustomPopover from 'components/ManageStrategy/CustomPopover';
import AccountInfo from 'components/ManageStrategy/AccountInfo';
import MountComponent from 'components/ManageStrategy/MountComponent';
import Note from 'components/ManageStrategy/Note';


import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import LoadingOverlay from 'react-loading-overlay';
import Moment from 'react-moment';
import NotificationSystem from 'react-notification-system';


import LineChart from 'components/Dashboard/LineChart';


import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader, } from 'reactstrap';
import { tradeService } from 'services/trade_service';

import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';

import BackTest from '../modules/BackTest';

const PopoverModel = props => { return (
      <React.Fragment>
          <button className="btn btn-grouop" id={`null-pointer-${props.data.record_id}`} onClick={() => props.toggle(`null-pointer-${props.data.record_id}`,props.data)}>
              <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
          </button>
      </React.Fragment>
  );
};

class ManageStrategy extends React.Component {
  constructor() {
    super();
    this.toggle = this.toggle.bind(this);
    this.state = {
      strategyId:null,
      segment:null,
      showAlert: true,
      strategyName:'',
      showLineChart:false,
      strategyStatsModal : false,
      strategyData: {},
      lineChartStartDate:'-',
      lineChartEndDate : '-',
      strategyLineChart:[],
      showPopover   : false, 
      popoverTarget : 'null-pointer-0',
      popoverData   : {},
      auth: undefined,
      popoverOpen: false,
      strategyCode    : null,
      strategyDetails : {},
      modal: false,
      modals: false,
      data: [],
      records: [],
      trade_signal: [],
      export: false,
      exportHeaders:true,
      fileName:"Manage Strategies.xls",
      columns: [
        {
          name: 'SR.',
          sortable: true,
          width: '80px',
          selector: 'record_id',
        },
        {
          name: 'Strategy',
          sortable: true,
          width: '110px',
          selector: 'strategy_name',
        },
        {
          name: 'Strategy type',
          sortable: true,
          width: '140px',
          selector: 'strategy_type',
        },
        {
          name: 'Description',
          sortable: true,
          width: '610px',
          selector: 'strategy_desc',
        },
        {
          name: 'Action(s)',
          sortable: true,
          export:false,
          cell: data => ( <PopoverModel data={data} toggle={this.toggle} ></PopoverModel>)
        },
      ],
    };
    this.tradeSignal = this.tradeSignal.bind(this);
    this.deployStrategy   = this.deployStrategy.bind(this);
    this.unDeployStrategy = this.unDeployStrategy.bind(this);
    this.repair = this.repair.bind(this);
    this.handleBTOpen  = this.handleBTOpen.bind(this);
    this.handleBTClose = this.handleBTClose.bind(this); 
    this.onCloseModal = this.onCloseModal.bind(this);
    this.handleClickOK = this.handleClickOK.bind(this);
    this.strategyStats = this.strategyStats.bind(this);

    this.conditionalRowStyles = [{
        when: row => row.deploy_status !== 'NA',
          style: {
              backgroundColor: '#00cbca',
              color: 'black',
            },
        },
      ];
  }


  toggle(target,data = undefined) { 
    
    this.setState({ showPopover: !this.state.showPopover , popoverTarget : target }); 

    if(data !== undefined) this.setState({ popoverData : data });
  }

  componentDidMount() { this.loadStrategy()  }

  onCloseModal() {
    this.setState({ strategyId: null,segment:null });
  }



  async tradeSignal(code = undefined) {

    if (tradeService.isUserSessionActive() === false) { return this.props.history.push('/login'); }

    try {

        this.setState({ loading: true }); /* show loading overlay */

        const serverResponse = await tradeService.execStrategy({
          username: store.get('user_data').username,
          code: code,
          exec_type: '0',
        })

        if (serverResponse.statusCode !== '200') throw new Error()  

        sessionStorage.setItem('exec_strategy', code);
        this.setState({
            loading: false, modal: true,
            records: serverResponse.data,
        });
      
    } catch (error) {
        this.setState({ loading : false })
        this.notificationSystem.addNotification({
            title: <></>,
            message: error.message || `No Recent Signal found at this moment. It could be because of strategy validation failed. Kindly check your strategy and retry.`,
            level: 'info',
        });
    } 
  }



  repair(data) {
      this.setState({
          modals     : !this.state.modals,
          segment    : data.segment,
          strategyId : data.code,
      });
  }
 

  loadStrategy() {
    this.setState({ loading: true });
    var username = store.get('user_data').username;
    tradeService
      .getStrategy({
        username: username,
      })
      .then(result => {
        this.setState({ loading: false });
        if (result.statusCode === '200') {
          // console.log(result.data)
          // console.log("Success response collected");
          this.setState({ data: result.data });
        }
      })
      .catch(function (error) {
        console.log('error occured while calling API');
        //console.log(error);
      });
  }

  signalFormatter(cell, row) {
    if (cell === 1) {
      return <span className="text-success">BUY</span>;
    } else {
      return <span className="text-danger">Sell</span>;
    }
  }

// ------------------------------------------------------------------------------------------------------------------------------
  
  authorized(userDetails, strategy) {
    
    const todayDate = ((date) => {

      const month = String(date.getUTCMonth() + 1);
      const day  = String(date.getUTCDate())

      return `${date.getUTCFullYear()}-${month.padStart(2, "0")}-${day.padStart(2,"0")}`

    })(new Date())

    if (userDetails.auth === 'S6E1') return true /* if user is admin return children */
 
    if (strategy.original_code === "NA" && userDetails.expiry_date >= todayDate) {

        return true  /* if expiry date is greater than current date and strategy type is private */ 
    }

    else { 

      if(userDetails.public_strategies === undefined) return false /* if public strategy is undefined */ 

      if(userDetails.public_strategies.length === 0) return false /* if public strategy array is empty */
      
      const publicStrategies = userDetails.public_strategies.filter((publicStrategy) => publicStrategy.code === strategy.original_code)[0]

          if(publicStrategies === undefined) return false

          if(publicStrategies.expiry_date >= todayDate) return true /* if expiry date is greater than current date  */ 
    }

    return false;
  }

  async strategyStats(strategy) { 

    if (tradeService.isUserSessionActive() === false) return this.props.history.push('/login')

    this.setState({ loading: true }); 

    try {

      const serverResponse = await tradeService.strategyStats({
          'username': store.get('user_data').username,
          'strategy_name': strategy.strategy_name
      })

      if (serverResponse.statusCode !== 200) { throw new Error('') }

      this.setState({ strategyStatsModal: true, strategyData: serverResponse.data , strategyName : strategy.strategy_name })
      this.setState({ 
            showLineChart : serverResponse.graph_data.length > 0 ? true : false,
            strategyLineChart: [{ id : "pnl_curve",color : "hsl(137, 70%, 50%)",data : ((data) => { if(data === null || data === undefined) {return []} return data })(serverResponse.graph_data)  }],
            lineChartStartDate : serverResponse.graph_data.length > 0 && serverResponse.graph_data[0].x,
            lineChartEndDate   : serverResponse.graph_data.length > 0 && serverResponse.graph_data[(serverResponse.graph_data.length - 1)].x,
      });
     
    } catch (error) { 
  
        this.setState({ loading: false }); /** hide loading overlay */
        this.notificationSystem.addNotification({
            title: <></>,
            message: "Something went wrong while fetching strategy performance",
            level: 'info',
        });
    }
    
    this.setState({ loading: false }); /** hide loading overlay */
  }

  handleBTClose()  /** * Close back testing page * @param {*} code */ {
    this.setState({ strategyCode: null })
  };
   
  handleBTOpen(strategy) /** * Open back testing page @param {*} code **/ {

    if (tradeService.isUserSessionActive() === false) return this.props.history.push('/login')
      
    if (this.authorized(store.get('user_data'), strategy) === false) {

        return this.notificationSystem.addNotification({
            title: <></>,
            message: 'Your Subscription for this strategy has expired.Renew now to continue services.',
            level: 'info',
        });
    }

    return this.setState({ strategyCode: strategy.code , strategyDetails : strategy })
  };

  async unDeployStrategy(code) /*** action undeploy strategy  @coder-kabir */ {

    if (tradeService.isUserSessionActive() === false) return this.props.history.push('/login')

    this.setState({ loading: true }); const notification = { message: 'Strategy undeployed successfully' }
    
    try {

      const serverResponse = await tradeService.undeployStrategy({
        'username': store.get('user_data').username,
        'code': code
      })

      if (serverResponse.statusCode === 200) this.loadStrategy();
        
      else notification.message = "Sorry strategy couldn't undeploy"
      
    } catch (error) { notification.message = "Sorry strategy couldn't undeploy" }
    
    this.setState({ loading: false });
    this.notificationSystem.addNotification({
        title: <></>,
        message: notification.message,
        level: 'info',
    });
  }
  
  async deployStrategy(strategy) /*** Action deploy strategy   @coder-kabir */ {
    
    if (tradeService.isUserSessionActive() === false) { return this.props.history.push('/login'); }

    this.setState({ loading: true }); const notification = { message : 'Strategy deployed successfully' }

    try {

        if (this.authorized(store.get('user_data'), strategy) === false) {
          
           throw new Error("Your subscription plan is expired")
        } /** check authentication  */

        const serverResponse = await tradeService.deployStrategy({
            username: store.get('user_data').username,
            code: strategy.code,
        })

          if (serverResponse.statusCode === 200) this.loadStrategy();

          else notification.message = serverResponse.status//"Sorry strategy couldn't deploy"
      
      } catch (error) { notification.message = error.message }

      this.setState({ loading: false });
      this.notificationSystem.addNotification({
          title: <></>,
          message: notification.message,
          level: 'info',
      });
  }

  
  
  async handleClickOK() /** * Action run strategy * @param {*} code * @returns */ {

    if (tradeService.isUserSessionActive() === false) { return this.props.history.push('/login'); }

    this.setState({ loading: true, strategyId: null ,segment:null, modals : false }); const notification = { message : '' } 
    
    try {

      const serverResponse = await tradeService.repairStrategy({ 
          username: store.get('user_data').username, 
          code: this.state.strategyId,
          segment:this.state.segment
      })

        if (serverResponse.statusCode === '200' && serverResponse.message === 'SUCCESS') {
          
              notification.message = 'Strategy repaired successfully!'
        }

        else { notification.message = serverResponse.message }

    } catch (error) { notification.message = error.message }

    this.setState({ loading: false });
    this.notificationSystem.addNotification({
        title: <></>,
        message: notification.message,
        level: 'info',
    });
  }

//------------------------------------------------------------------------------------------------------------------------


  /**
   * Time formatter
   * @param {*} cell
   * @param {*} row
   * @returns
   */
  timeFormatter(cell, row) {
    if (cell !== null || cell !== undefined) {
      return <Moment parse="YYYY-MM-DD HH:mm:ss">{cell}</Moment>;
    } else {
      return 'NA';
    }
  }

  render() {
    return (
      <LoadingOverlay
      active={this.state.loading}
      spinner
      text="Loading content..."
    >
      <div className="container-fluid">
        <div className="data-table">
          <div className="table-head">
            <div className="row">

              <div className='col-md-12'>
                <Alert className="reactstrap-custom-alert" isOpen={this.state.showAlert} toggle={() => { this.setState({ showAlert : false }) }}>
                    We have upgraded our platform , please update your old strategies in case if it's not working accuratly. Click on <b>Check Current Signal</b> From Action Dropdown to confirm if it's able to find last signal.
                </Alert>
              </div>
              <div className="col-md-6 repo-head"><div className="head">
                  <h4 id="null-pointer-0">
                    <img className="table-icon" src={Ticon} alt="..." />
                    Manage Strategy <Popover message="Manage your strategies, update , back-test and deploy." />
                  </h4>
              </div></div>
              

              

              {this.state.strategyCode ? (
                <></>
              ) : (
                <div className="col-md-6 repo-head1">
                  <div className="deploye-btn">
                    <button className="btn btn-group">
                      Deploy <img src={Dicon} alt="..." />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          {this.state.strategyCode ? (
            <BackTest
              code={this.state.strategyCode}
              onBTClose={this.handleBTClose}
              strategyDetails={this.state.strategyDetails}
            />
          ) : (
            <>
              <div className="manage-strategy">
                <div className="main">
                  <DataTableExtensions {...this.state}>
                    <DataTable
                      columns={this.state.columns}
                      data={this.state.data}
                      noHeader
                      // onSelectedRowsChange={this.updateState}
                      selectableRows
                      defaultSortField="id"
                      defaultSortAsc={false}
                      paginationRowsPerPageOptions={[10, 20, 50, 100]}
                      pagination
                      highlightOnHover
                      conditionalRowStyles={this.conditionalRowStyles}
                    />
                  </DataTableExtensions>
                  <NotificationSystem
                    dismissible={false}
                    ref={notificationSystem =>
                      (this.notificationSystem = notificationSystem)
                    }
                    style={NOTIFICATION_SYSTEM_STYLE}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <LoadingOverlay
          active={this.state.loading}
          spinner
          text="Loading content..."
        > 
          <Modal isOpen={this.state.modal} className="modal-lg" toggle={() => this.setState({ modal : false })}>
              <ModalHeader toggle={() => this.setState({ modal : false })}>Trade Signal</ModalHeader>
              <ModalBody>
                  { this.state.records.length === 0 && 
                    <div>
                        <h6 className='text-center'>We display in last generated signal in previous five days,No new signal found at this momment</h6>
                    </div> 
                  }

              {this.state.records.map(data => (
                <div className="check-signal">
                  <div className="row">
                    <div className="col-md-2">
                      <div className="check">
                        <h5>SYMBOL</h5>
                        <span>{data.symbol}</span>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="check">
                        <h5>SIDE</h5>
                        {this.signalFormatter(data.TS_Trades)}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="check">
                        <h5>ENTRY PRICE</h5>
                        <span>{data.close}</span>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="check">
                        <h5>IDENTIFIED TIME</h5>
                        <span>{this.timeFormatter(data.close_time)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </ModalBody>
          </Modal>
        </LoadingOverlay>


          <Modal isOpen={this.state.modals} toggle={this.repair} className={this.props.className} >
              <ModalHeader toggle={this.repair}>Repair Strategy</ModalHeader>
              <ModalBody>
                  <div className="check-signal">
                      <h5>Are you sure want to repair strategy ?</h5>
                  </div>
              </ModalBody>
              <ModalFooter>
                  <Button onClick={this.handleClickOK}>Save</Button>{' '}
                  <Button color="secondary" onClick={this.repair}>Cancel</Button>
              </ModalFooter>
          </Modal>

          <Modal isOpen={this.state.strategyStatsModal} className="modal-xl" toggle={() => this.setState({ strategyStatsModal : false })}>
              <ModalHeader toggle={() => this.setState({ strategyStatsModal : false })}>Strategy Performance ({ this.state.strategyName }) </ModalHeader>
              <ModalBody>
                  
                  <MountComponent condition={this.state.showLineChart === false && this.state.strategyData.no_trade === 0}>
                      <h4 className='text-center p-3'>Sorry no performance data found for this strategy { this.state.strategyName }</h4>
                  </MountComponent>         

                  <MountComponent condition={this.state.showLineChart && this.state.strategyData.no_trade !== 0 }>
                      <div className="line-chart"><div className="row">
                          <MountComponent data={this.state.strategyLineChart} condition={(this.state.showLineChart)}>
                              <div className="col-md-7 lines">
                                  <LineChart 
                                      data={this.state.strategyLineChart} 
                                      startDate={this.state.lineChartStartDate}
                                      endDate={this.state.lineChartEndDate}
                                  ></LineChart>
                              </div>
                          </MountComponent>
                      
                          <MountComponent condition={(this.state.strategyData.no_trade !== 0 && this.state.strategyData.no_trade !== undefined )}>
                              <AccountInfo info={this.state.strategyData}></AccountInfo>
                          </MountComponent>

                          <Note></Note>
                      </div></div>
                  </MountComponent>
              </ModalBody>
          </Modal>

          <CustomPopover 
                toggle={this.toggle}
                popoverTarget={this.state.popoverTarget}
                showPopover={this.state.showPopover}
                data={this.state.popoverData}

                tradeSignal={this.tradeSignal}
                deployStrategy={this.deployStrategy}
                unDeployStrategy={this.unDeployStrategy}
                repair={this.repair}
                handleBTOpen={this.handleBTOpen}
                strategyStats={this.strategyStats}
          ></CustomPopover>
      </div>
      </LoadingOverlay>
  )}
}

export default ManageStrategy;
