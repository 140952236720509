import React from 'react';
import store from 'store';
import * as validator from 'validatorjs';

// @import-components && icons -------------------------------------------------------------------------

import OrderExecution from './strategyComponents/stepFour/OrderExecution';
import OrderExecutionTwo from './strategyComponents/stepFour/OrderExecutionTwo';

import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';
import NotificationSystem from 'react-notification-system';

// -----------------------------------------------------------------------------------------------------

// static-data -----------------------------------------------------------------------------------------

const continuePrevTrade = [
  { label: 'NO', value: 'NO' },
  { label: 'ONLY MARKET OPEN', value: ' MRKT_OPEN' },
  { label: 'ALWAYS', value: 'ALWAYS' },
]

// ------------------------------------------------------------------------------------------------------


const StepFour = (props) => {

  const toggleClass = props.activeStep === 'stepFour' ? 'step step4' : 'step step4 hidden'

  const [validationButton, toggleValidationButton] = React.useState(true);
  const [executionType, updateExecutionTypes] = React.useState([
    { label: 'Paper Trade', value: '1' },
    { label: 'Auto Trade', value: '2' },
    { label: 'Notify Trade', value: '3' },
  ])

  const notificationSystem = React.useRef()
  const userDetails = store.get('user_data')

  React.useEffect(() => { toggleValidationButton(true); return () => { } }, [props.activeStep])
  React.useEffect(() => {

    if (props.updateStrategy.status) updatestrategy(props.updateStrategy.payload);

  }, [props.updateStrategy.status])

  // @execution-type-logic---------------------------------------------------------------------

  const stepTwoStates = props.payload.stepTwo

  React.useEffect(() => {

    if (stepTwoStates.segment === undefined) { return () => { } }

    if (stepTwoStates.segment === 'fut-opt' || stepTwoStates.segment === 'opt') {
      updateExecutionTypes([
        { label: 'Auto Trade', value: '2' },
      ])
    }

    else {
      updateExecutionTypes([
        { label: 'Paper Trade', value: '1' },
        { label: 'Auto Trade', value: '2' },
        { label: 'Notify Trade', value: '3' },
      ])
    }

    return () => { } /* final return empty object */

  }, [stepTwoStates.segment])

  // @states ----------------------------------------------------------------------------------

  const initialState = {

    position_size: { value: '', error: false, errorMessage: '' },
    exec_type: { value: '', error: false, errorMessage: '' },

    duration_type: { value: '', error: false, errorMessage: '' },
    order_type: { value: '', error: false, errorMessage: '' },
    product_type: { value: '', error: false, errorMessage: '' },
    cnt_prev_trade: { value: '', error: false, errorMessage: '' },
    intraday_count: { value: '0', error: false, errorMessage: '' },
    includeGap : { value: 'YES', error: false, errorMessage: '' },
    status: { value: 'ACTIVE', error: false, errorMessage: '' },
    directionalTrade: { value: '', error: false, errorMessage: '' },
  }

  const [localStates, updateStates] = React.useState(initialState)

  const updatestrategy = (data) => {

    const newState = { ...localStates }

    for (const name in newState) { newState[name].value = data[name] === undefined ? newState[name].value : data[name] }
  }

  // @custom-validation ---------------------------------------------------------------------------------------

  const validationData = {

    position_size: localStates.position_size.value,
    exec_type: localStates.exec_type.value,

    duration_type: localStates.duration_type.value,
    order_type: localStates.order_type.value,
    product_type: localStates.product_type.value,
    cnt_prev_trade: localStates.cnt_prev_trade.value,
    intraday_count: localStates.intraday_count.value,
    includeGap : localStates.includeGap.value,
    status: localStates.status.value,
    directionalTrade: localStates.directionalTrade.value
  };

  const validationRules = {

    position_size: 'required|numeric|min:1',
    exec_type: 'required',

    duration_type: 'required',
    order_type: 'required',
    product_type: 'required',
    cnt_prev_trade: 'required',
    intraday_count: 'required|numeric|min:0',
    includeGap: 'required',
    status: 'required',
  };

  const errorMessages = {

    "required.position_size": "Please describe position size",
    "numeric.position_size": "Please describe positive numeric number for position Size",
    "min.position_size": "Please describe position size eg: 1 for F&NO lot or 100 for shares",

    "required.exec_type": "Please describe execution type",
    "required.duration_type": "Please describe order duration type",
    "required.order_type": "Please describe order type",
    "required.product_type": "Please describe product type",
    "required.cnt_prev_trade": "Please describe continue with previous trade",

    "required.intraday_count": "Please describe intraday trade count",
    "numeric.intraday_count": "Please describe positive numeric number for intraday trade count",
    "min.intraday_count": "Please describe intraday trade count eg: 1",
    "required.includeGap": "Please describe to consider gaps or not.",
    "required.status": "Please describe strategy status",
  }

  const formValidation = () => {

    const validation = new validator(validationData, validationRules, errorMessages);
    const userState = { ...localStates }
    const validationResponse = { submitStatus: true, postData: validationData }

    if (validation.fails()) validationResponse.submitStatus = false;

    for (const name in userState) {

      userState[name].error = validation.errors.get(name).length > 0 ? true : false;
      userState[name].errorMessage = validation.errors.first(name) || ''
    }

    const segments = ['fut-opt','opt','future','mcx'] // max 20 position
    const otherSegments = ['nse'] // max 1000 position

    if(segments.includes(stepTwoStates.segment)){

      if(userState.position_size.value > 20) {
        validationResponse.submitStatus      = false;
        userState.position_size.error        = true;
        userState.position_size.errorMessage = 'max position size allowed is 20'
    } }

    if(otherSegments.includes(stepTwoStates.segment)){

      if(userState.position_size.value > 1000) {
        validationResponse.submitStatus      = false;
        userState.position_size.error        = true;
        userState.position_size.errorMessage = 'max position size allowed is 1000'
    } }

    updateStates({ ...userState }) // update errors and error messages //

    return validationResponse;
  }

  // @methods---------------------------------------------------------------------------------------------------

  const executionTypeHandler = ({ name, value }) => {

    if (userDetails === undefined) { userDetails = { auth: '', user_subscription: '' } }

    const todayDate = ((date) => {

      const month = String(date.getUTCMonth() + 1);
      const day = String(date.getUTCDate())

      return `${date.getUTCFullYear()}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`

    })(new Date())

    if (userDetails.auth === 'S6E1' || value === '1' || value === '') {

      return updateStates({ ...localStates, [name]: { value: value, error: false, errorMessage: '' } })
    }

    if (userDetails.user_subscription.includes('WIZARD_') ||
      userDetails.user_subscription.includes('MASTER_') ||
      userDetails.user_subscription.includes('SMART_')) {

      if (userDetails.expiry_date >= todayDate) return updateStates({ ...localStates, [name]: { value: value, error: false, errorMessage: '' } })
    }

    const type = executionType.filter((data) => data.value === value)[0]

    return notificationSystem.current.addNotification({
      title: <></>,
      message: <h6> {type !== undefined && type.label} is not allowed</h6>,
      level: 'info',
      position: 'br',
    });
  }

  const changeHandler = ({ name, value }) => {

    updateStates({ ...localStates, [name]: { value: value, error: false, errorMessage: '' } })
  }

  const submitHandler = () => {

    const { submitStatus, postData } = formValidation();

    if (submitStatus === false) return false

    props.stepValidator('stepFour', 'stepFourValidationComplete', postData); // send data to main function

    toggleValidationButton(false) // hide validation button of step one

  }

  // ------------------------------------------------------------------------------------------------------------


  return (
    <div className={toggleClass}>

      <OrderExecution
        changeHandler={changeHandler}
        executionTypeHandler={executionTypeHandler}
        state={localStates}
        executionType={executionType}
        continuePrevTrade={continuePrevTrade}
      ></OrderExecution>

      <OrderExecutionTwo
        changeHandler={changeHandler}
        state={localStates}
      ></OrderExecutionTwo>

      <NotificationSystem
        dismissible={false}
        ref={notificationSystem}
        style={NOTIFICATION_SYSTEM_STYLE}
      ></NotificationSystem>

      <div className="button-group">
        <button id="previous" className="button" type="button" onClick={(e) => props.previous(e)} >Back</button>
        {validationButton && <button id="next" className="button" type="button" onClick={submitHandler}>Submit</button>}
      </div><br></br>
    </div>
  );
};

export default StepFour;
