import React from 'react'
import DropDownField from '../DropDownField'
import StepHeading from '../StepHeading'
import SearchSelectFields from '../SearchSelectFields'

const DataSelection = (props) => {

    return (
        <React.Fragment>
            <StepHeading text="Data Selection" id="step-two-data-selection" content="Select Segment, TimeFrame, Instrument and Chart Type for your Strategy." ></StepHeading>
            <div className="row form-detail">
                <DropDownField
                    label="Segment"
                    type="select"
                    name="segment"
                    id="segment"
                    md="3"
                    error={props.state.segment.error}
                    value={props.state.segment.value}
                    errorMessage={props.state.segment.errorMessage}
                    method={props.changeHandler}
                    data={props.segment}
                ></DropDownField>
                <DropDownField
                    label="Time frame (In minutes)"
                    type="select"
                    name="timeframe"
                    id="timeframe"
                    md="3"
                    error={props.state.timeframe.error}
                    value={props.state.timeframe.value}
                    errorMessage={props.state.timeframe.errorMessage}
                    method={props.changeHandler}
                    data={props.timeFrame}
                ></DropDownField>
                <SearchSelectFields
                    label="Stock bucket"
                    type="select"
                    name="bucket"
                    id="bucket"
                    multiple={true}
                    md="3"
                    value={props.state.bucket.value}
                    error={props.state.bucket.error}
                    errorMessage={props.state.bucket.errorMessage}
                    method={props.changeHandler}
                    data={props.stockBucket}
                ></SearchSelectFields>
                <DropDownField
                    label="Chart type"
                    type="select"
                    name="charttype"
                    id="charttype"
                    md="3"
                    value={props.state.charttype.value}
                    error={props.state.charttype.error}
                    errorMessage={props.state.charttype.errorMessage}
                    method={props.changeHandler}
                    data={props.chartType}
                ></DropDownField>
            </div>
        </React.Fragment>
    )
}

export default DataSelection