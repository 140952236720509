
// @import-dependencies -------------------------------------------------------

      import React from 'react';
      import store from 'store';
      import { tradeService } from 'services/trade_service';
      import * as validator   from 'validatorjs';

 
// @import-components ----------------------------------------------------------

    import Popover from 'components/Popover';
    import ContactUsForm from 'components/ContactUsForm';
    import Icon from 'assets/img/icon/table-icon.svg';
    import { Alert } from 'reactstrap';

    import NotificationSystem from 'react-notification-system';
    import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';
    import LoadingOverlay from 'react-loading-overlay';

// -----------------------------------------------------------------------------


export default class ContactUs extends React.Component {
    constructor(props) {

        super(props);

        this.state = { alert : false, loader: false, username : store.get('user_data').username };

        this.state.formStates = {
            query     : { value: '', error: false, errorMessage: '' },
            full_name : { value: '', error: false, errorMessage: '' },
            email     : { value: '', error: false, errorMessage: '' },
            subject   : { value: '', error: false, errorMessage: '' },
            message   : { value: '', error: false, errorMessage: '' },
        }

        this.validation = {         
            query     : 'required',
            full_name : 'required|min:3|max:80',
            email     : 'required|email',
            subject   : 'required|min:3|max:80',
            message   : 'required|min:3|max:80',
        };

        this.errorMessages = {
            'required.query' : 'Please select a query',

            'required.full_name' : 'Please enter valid name',
            'min.full_name'      : 'Please enter minimum 3 character',
            'max.full_name'      : 'please enter within 80 character',

            'required.email': 'Please enter valid email address',
            'email.email': 'Not a valid email address',

            'required.subject' : 'Please enter valid subject',
            'min.subject'     : 'Please enter minimum 3 character',
            'max.subject'     : 'please enter within 120 character',
           
            'required.message': 'Please enter valid message',
            'min.message': 'Please enter minimum 20 character',
            'max.message': 'please enter within 500 character',
        }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange({ name, value }) {
    
    this.setState({ 
        formStates: { 
          ...this.state.formStates, 
          [name]: {  value: value, error: false,alert: '', }} 
    });
  }

  async handleSubmit(event) { event.preventDefault(); /* prevent page reload */

    const validationData = { 
        query     : this.state.formStates.query.value,
        email     : this.state.formStates.email.value,
        full_name : this.state.formStates.full_name.value,
        message   : this.state.formStates.message.value,
        subject   : this.state.formStates.subject.value,
    };


    const validation = new validator(validationData, this.validation,this.errorMessages);

    const formStates  = { ...this.state.formStates }
    const validationResponse = { submitStatus : true, postData : validationData  }
    const notification = { message:'Thanks for contacting us!', level : 'info' };

        if(validation.fails()) validationResponse.submitStatus = false;

        for (const name in formStates) { 
 
            formStates[name].error = validation.errors.get(name).length > 0 ? true : false;
            formStates[name].errorMessage = validation.errors.first(name) || '' 
        }  

      this.setState({ formStates : { ...formStates} }) // update errors and error messages //

      if(validationResponse.submitStatus === false) return false; 

      this.setState({ loader : true }); // show loader spinner //

      try {

          const serverResponse = await tradeService.submitContactUs(validationResponse.postData)

          if(serverResponse.statusCode !== '200') throw Error('Sorry your request could not be completed');

            this.setState({ alert : true, formStates : {
                query     : { value: '', error: false, errorMessage: '' },
                full_name : { value: '', error: false, errorMessage: '' },
                email     : { value: '', error: false, errorMessage: '' },
                subject   : { value: '', error: false, errorMessage: '' },
                message   : { value: '', error: false, errorMessage: '' },
            }})

    } catch (error) { 
        
        this.notificationSystem.addNotification({
            title: <i className="fa fa-shield"></i>,
            message: error.message,
            level: notification.level,
        });
    }
      
    this.setState({ loader : false });
  }

  render() {return (
    <LoadingOverlay active={this.state.loader} spinner text="Loading content...">
        <section className="wrapper">
          <div className="container-fluid"><div className="resources">
                <div className="row"><div className="col-md-6"><div className="configuration-head">
                    <h4>
                        <img className="table-icon" src={Icon} alt="..." />Contact Us
                        <Popover message="Contact Us " />
                    </h4>
                </div></div></div>

                <div className="contact-us"><div className="row">

                    <div className='col-md-12 pl-4 pr-4'>
                        <Alert className="reactstrap-custom-alert" isOpen={this.state.alert} toggle={() => { this.setState({ alert : false }) }}>
                            Thanks for contacting us!
                        </Alert>
                    </div>

                    <ContactUsForm
                        handleSubmit={this.handleSubmit}
                        handleChange={this.handleChange}
                        formData={this.state.formStates}
                    ></ContactUsForm>

                    <NotificationSystem
                        dismissible={true}
                        ref={notificationSystem => (this.notificationSystem = notificationSystem)}
                        style={NOTIFICATION_SYSTEM_STYLE}
                    ></NotificationSystem>
                </div></div>
          </div></div>
        </section>
      </LoadingOverlay>
    )}
}
