
import React from "react";
import { FormGroup, Label, Input ,Col,FormFeedback } from 'reactstrap';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';

import * as validator   from 'validatorjs';

class FormulaBasedStrategy extends React.Component {
    constructor(props) {
        super(props);

        this.formulas = {}

        this.movingAverageIndicator = ((formulas) => {

            if(formulas["ma"] === undefined) { return ['convergence'] }

            const data =  formulas["ma"].fields[0].data.map((data) => data.value)

            data.push('convergence')

            return data

        })(this.props.formulas)
  
        this.state = { 
            indicators     : this.props.indicators,
            comparator     : this.props.comparator,
            formulas       : this.props.formulas,
            updateStrategy : this.props.updateStrategy
        };

        this.state.products = [{
            id            : (Math.floor(Math.random() * 999999)).toString(36),
            showLongShort : true,
            kind          : { value: '', error: false,  errorMessage: '', data: this.state.indicators },
            comparator    : { value: '', error: false,  errorMessage: '', data: [] },
            long_params   : { value: '', error: false,  errorMessage: '', data: [] },
            short_params  : { value: '', error: false,  errorMessage: '', data: [] },
            params        : [],
        }];

        this.createStrategyFormula = this.createStrategyFormula.bind(this)
        this.indicatorHandler      = this.indicatorHandler.bind(this);
        
        this.props.handle.current.onclick = () => this.submithandler()
    }

    componentDidMount(){

        if(this.props.updateStrategy.status === false) return false;

        const strategies = this.props.updateStrategy.payload.strategy;
        const updateStrategies = [];

        for (const strategy of strategies) { 

            const long_short = strategy.long_short.replace(/[\])}[{(]/g, '').split(',')

            const params = ((strategy, self) => { 

                const explodeParams = strategy.params.replace(/[\])}[{(]/g, '').split(',') 

                if (strategy.kind === 'convergence') return [explodeParams[1],explodeParams[0],explodeParams[3],explodeParams[2]]

                else if (self.movingAverageIndicator.includes(strategy.kind)) { return [strategy.kind,( explodeParams[0] )] }

                else return explodeParams 
            
            })(strategy,this)

            const long_params  = long_short[0];
            const short_params = long_short.length === 2 ? long_short[1] : '0';

            const kind = this.movingAverageIndicator.includes(strategy.kind) ? 'ma' : strategy.kind
            const comparatorArray = this.state.formulas[kind] === undefined ? [] : this.state.formulas[kind].comparator;

            const product = {
                id            : (Math.floor(Math.random() * 999999)).toString(36),
                showLongShort : this.state.formulas[kind] === undefined ? false : this.state.formulas[kind].isLongShortEditEnable,
                kind          : { value: kind,               error: false,  errorMessage: '', data: this.state.indicators },
                comparator    : { value: strategy.comparator,error: false,  errorMessage: '', data: comparatorArray       },
                long_params   : { value: long_params,        error: false,  errorMessage: '', data: [] },
                short_params  : { value: short_params,       error: false,  errorMessage: '', data: [] },
                params        : [],
            }

            var response = this.createStrategyFormula(product,kind);

                response = this.comparatorLogic(response);

            for (const [index,formula] of response.params.entries()) { formula.value = params[index] }

            updateStrategies.push(response);
        }

        this.setState({ products : updateStrategies});
    }

    handleRowDel(product) {

        if(this.state.products.length === 1) return true;

        const index = this.state.products.indexOf(product);
        this.state.products.splice(index, 1);
        this.setState(this.state.products);
    };
  
    handleAddEvent() {

        if(this.state.products.length === 3) return true;
      
        const newStrategyRow = {
            id: (Math.floor(Math.random() * 999999)).toString(36),
            showLongShort : true,
            kind          : { value: '', error: false, errorMessage: '', data: this.state.indicators },
            comparator    : { value: '', error: false, errorMessage: '', data: this.state.comparator },
            long_params   : { value: '', error: false, errorMessage: '' },
            short_params  : { value: '', error: false, errorMessage: '' },
            params        : [],
        }

        this.state.products.push(newStrategyRow);
        this.setState(this.state.products);
    }

    updateStrategyFormula(event) { 

        if(event.target.value === '-- Select --') return void 0;
        
        const strategyRows = this.state.products.slice().map(function(strategy) {

            for (const key in strategy) {

                if(strategy.id === event.target.id){

                    const getFormula = strategy.params.filter((formula) => event.target.name === formula.name)[0]

                    getFormula.value = event.target.value
                    getFormula.error = false
                    getFormula.errorMessage = ''; break;
                }
            }; return strategy;
        });

        this.setState({ products : strategyRows});

    }
    
    createStrategyFormula(currentStrategyRow,indicator){

        currentStrategyRow.params = [] 

        if(this.state.formulas[indicator] === undefined) return currentStrategyRow

        if(this.state.formulas[indicator].fields.length === 0 ) return currentStrategyRow

        for (const formula of this.state.formulas[indicator].fields) {

            currentStrategyRow.params.push({ 
                value: '', error: false,  errorMessage: '', id: formula.key, key: formula.key, name: formula.key,label: formula.label, type: formula.type,data:formula.data
            })
        }

        return currentStrategyRow;
    }

    comparatorLogic(product){  

        if(product.comparator.value === "crossover" && product.kind.value === 'ma') { 
            
            for (const formula of this.state.formulas[product.kind.value].fields) {

                product.params.push({ 
                    value: '', error: false,  errorMessage: '', id: `${formula.key}_two`, key: `${formula.key}_two`, name: `${formula.key}_two`,label: formula.label, type: formula.type,data:formula.data
                })
            }
        }

        else if (product.kind.value === 'ma') {

            if(product.params.length > 2) {
                product.params.pop();
                product.params.pop();
            }
        }
        
        return  product 
    }


    indicatorHandler(event){

        const data = { id : event.target.id, name : event.target.name, value : event.target.value };
        const strategies = this.state.products.slice();

        // @duplicate-indicator ------------------------------------------------------------------------------

            const isIndicatorSelected = strategies.filter((strategy) => data.value === strategy.kind.value)[0]

            if(isIndicatorSelected !== undefined){

                return this.notificationSystem.addNotification({
                    title: <i className="fa fa-shield"></i>,
                    message: `Indicator is already selected`,
                    level: 'info',
                    position: 'br',
                });
            }
        // ---------------------------------------------------------------------------------------------------

        try {

            const updateStrategy = strategies.filter((strategy) => data.id === strategy.id)[0] /* get target row */

            if(updateStrategy === undefined) return true;

                if(this.state.formulas[data.value] !== undefined) { updateStrategy.showLongShort = this.state.formulas[data.value].isLongShortEditEnable  }

                updateStrategy.kind.value = data.value;  
                updateStrategy.kind.error = false;  
                updateStrategy.kind.errorMessage = ''; 

                updateStrategy.params = this.createStrategyFormula(updateStrategy,data.value).params;

                updateStrategy.comparator.value = ''
                updateStrategy.comparator.data  = this.state.formulas[updateStrategy.kind.value].comparator

            this.setState({ products : ((strategies) => {

                return strategies.map((strategy) => {

                    if(strategy.id === updateStrategy.id) strategy = updateStrategy;

                    return strategy
                })
            })(this.state.products.slice())});    
            
        } catch (error) { console.log('something went wrong') }
      
    }
  
    handleProductTable(event) {

        if(event.target.value === '-- Select --') return void 0;
        
        const data = { id : event.target.id, name : event.target.name, value : event.target.value };
        const products = this.state.products.slice();
        const self     = this
        const formulas = this.state.formulas

        const newProducts = products.map(function (product) {
            
            for (var key in product) { 

                if (key === data.name && product.id === data.id) {

                    product[key].value = data.value;  
                    product[key].error = false;  
                    product[key].errorMessage = ''; 

                    const longShort = formulas[product.kind.value];

                    // console.clear(); console.table(product); console.log(product.comparator); console.log(isLongShortEditEnable); 

                    /** if comparator is crosses or crossover than long_params and short_params would be 0  */

                    if (data.name === 'comparator' && (data.value === 'crosses' || data.value === 'crossover')) {

                        if(longShort === undefined) {
                            product.long_params.value = 0;
                            product.short_params.value = 0;
                        }

                        else if(longShort.isLongShortEditEnable === false) {
                            product.long_params.value = 0;
                            product.short_params.value = 0;
                        }
                    }
                    
                    if (data.name === 'long_params' || data.name === 'short_params') {

                        if (product.comparator.value === 'crosses' || product.comparator.value === 'crossover'){
                            
                            if(longShort === undefined) { product[key].value = 0; }
                            
                            else if(longShort.isLongShortEditEnable === false) { product[key].value = 0; }
                        }
                    }
                    
                    product = data.name === 'kind' ? self.createStrategyFormula(product,data.value) : product;

                    product = data.name === 'comparator' ? self.comparatorLogic(product) : product; break;
                 }
            }; return product;
        });

        this.setState({products:newProducts});
    };

    submithandler() { 

        const userState = this.state.products; 

        const formData = {}; const formValidations = {}; const errorMessages = {};

        this.state.products.map((data,n) => {

            formData[`kind${n}`]         =  data.kind.value
            formData[`comparator${n}`]   =  data.comparator.value
            formData[`long_params${n}`]  =  data.long_params.value
            formData[`short_params${n}`] =  data.short_params.value;

            formValidations[`kind${n}`]          = 'required'
            formValidations[`comparator${n}`]    = 'required'
            formValidations[`long_params${n}`]   = 'required|numeric|maxLength'
            formValidations[`short_params${n}`]  = 'required|numeric|maxLength';

            errorMessages[`required.kind${n}`]         = 'Indicator  is required'
            errorMessages[`required.comparator${n}`]   = 'Comparator is required'

            errorMessages[`required.short_params${n}`] = 'Short param is required';
            errorMessages[`numeric.short_params${n}`]  = 'Short param must be numeric';
            errorMessages[`maxLength.short_params${n}`]      = 'Short param length should not greater than 3@ places';

            errorMessages[`required.long_params${n}`]  = 'Long param is required';
            errorMessages[`numeric.long_params${n}`]   = 'Long param must be numeric';
            errorMessages[`maxLength.long_params${n}`] = 'Long param length should not greater than 3 places';

            ((self) => { 

                if(data.params === undefined || data.params.length === 0) return {};

                for (const param of data.params) {

                    formData[`formula_${param.key}${n}`]               =  param.value
                    formValidations[`formula_${param.key}${n}`]        =  param.type === "select" ? 'required' : 'required|numeric|maxLength'
                
                    errorMessages[`required.formula_${param.key}${n}`] = `${param.label} is required`;
                    errorMessages[`numeric.formula_${param.key}${n}`]  = `${param.label} must be numeric`;
                    errorMessages[`maxLength.formula_${param.key}${n}`] = `${param.label} length should not greater than 3 places`;
                }
            })(this); 

            return { formData , formValidations , errorMessages };
        }); 

        validator.register('maxLength', function(value, requirement, attribute) { 

            const dotsCount = (String(value).match(/\./g) || []).length;

            if(dotsCount > 0) { 
                
                if(String(value).length > 6) return false;

                return true; 
            }
            
            else if(String(value).length > 3) { return false; }

            return true;
            
        },'');


        const validation = new validator(formData, formValidations, errorMessages);

        const validationResponse = { submitStatus : true, postData : userState  }

        if(validation.fails()) validationResponse.submitStatus = false;

        for (const name in formData) { 

                const position = name.replace(/([a-zA-Z _])/g, "");
                const field    = name.replace(new RegExp("[0-9]", "g"), "");

            if(validation.errors.get(name).length === 0) continue;

            if(!userState[position].hasOwnProperty(field)) {

                const getFormula = userState[position].params.filter((formula) => field === `formula_${formula.name}`)

                if(getFormula.length === 0) continue;

                getFormula[0].error = validation.errors.get(name).length > 0 ? true : false;
                getFormula[0].errorMessage = validation.errors.first(name) || '' 

                continue;
            }

            userState[position][field].error = validation.errors.get(name).length > 0 ? true : false;
            userState[position][field].errorMessage = validation.errors.first(name) || '' 
        }; 

        if(validationResponse.submitStatus === false) this.props.payload('formula',validationResponse.submitStatus,{})

        else {
            
            const payload = this.generatePayload();

            this.props.payload('formula',true,payload)
        }

        return this.setState({ products: userState });
    }

    generatePayload(){

        const payload = []

        for (const strategy of this.state.products) {

            const params = ((strategy) => {

                if(strategy.kind.value === 'ma' && strategy.comparator.value === 'crossover') {

                    return `${strategy.params[1].value},${strategy.params[0].value},${strategy.params[3].value},${strategy.params[2].value}`
                }
                
                if(strategy.kind.value === 'ma') { return `${ strategy.params[1].value},` }

                return strategy.params.map((formula) => formula.value).join(',')  

            })(strategy)

            const kind = ((strategy) => {

                if(strategy.kind.value === 'ma' && strategy.comparator.value === 'crossover') return 'convergence'

                else if (strategy.kind.value === 'ma') return strategy.params[0].value

                else return strategy.kind.value
                
            })(strategy)

            const long_short = String(strategy.long_params.value) === '0' || String(strategy.short_params.value) === '0' ? '(0)' : `(${strategy.long_params.value},${strategy.short_params.value})`

            payload.push( {
                "kind"        : kind,
                "comparator"  : strategy.comparator.value,
                "params"      : `(${params})`,
                "long_short"  : long_short
            })
        }; 
        
        return payload;
    }

    render() { return (
        <React.Fragment>
            <StrategyTable  
                updateStrategyFormula={this.updateStrategyFormula.bind(this)}
                onProductTableUpdate={this.handleProductTable.bind(this)} 
                indicatorHandler={this.indicatorHandler}
                onRowAdd={this.handleAddEvent.bind(this)} 
                onRowDel={this.handleRowDel.bind(this)} 
                submithandler={this.submithandler.bind(this)}
                products={this.state.products}>
            </StrategyTable>
            <NotificationSystem 
                dismissible={true} ref={notificationSystem => (this.notificationSystem = notificationSystem)}
                style={NOTIFICATION_SYSTEM_STYLE}
            ></NotificationSystem>
        </React.Fragment>
    )}
}
  
class StrategyTable extends React.Component {
  
    render() {
        
        const onProductTableUpdate  = this.props.onProductTableUpdate;
        const updateStrategyFormula = this.props.updateStrategyFormula 
        const indicatorHandler      = this.props.indicatorHandler
        const rowDel = this.props.onRowDel;
     
        return (
            <React.Fragment>
                <div className='con-form'>
                    {
                        this.props.products.map(function(product) {
                            return (<StrategyRow indicatorHandler={indicatorHandler} onProductTableUpdate={onProductTableUpdate} updateStrategyFormula={updateStrategyFormula} product={product} onDelEvent={rowDel.bind(this)} key={product.id}/>)
                        })
                    }
                    <div className="row form-detail"><div className="col-12"><div className="add-btn">
                        <button className="btn btn-outline" type='button' onClick={this.props.onRowAdd}>
                            Add <i className="fa fa-plus"></i>
                        </button>
                    </div></div></div>
                </div>
            </React.Fragment>
        )}
  
  }

// @empty-wrappers ------------------------------------------------------------------------------------------

    const StrategyRowWrapper  = ({ children }) => <div className="row form-detail">{children}</div>
    const StrategyCellWrapper = ({ children }) => <div className='col-md-11'><div className="row">{children}</div></div>

// ----------------------------------------------------------------------------------------------------------
  
class StrategyRow extends React.Component {

    onDelEvent() { this.props.onDelEvent(this.props.product); }
    
    render() { return (

        <React.Fragment>
            <StrategyRowWrapper>
                <StrategyCellWrapper>
                    <EditableCell onProductTableUpdate={this.props.indicatorHandler} cellData={{
                        "type": "select",
                        value: this.props.product.kind.value,
                        error: this.props.product.kind.error,
                        errorMessage: this.props.product.kind.errorMessage,
                        data: this.props.product.kind.data,
                        id: this.props.product.id,
                        label:'Indicator',
                        name: 'kind'
                    }}/>
            
                    <EditableCell onProductTableUpdate={this.props.onProductTableUpdate} cellData={{
                        type: "select",
                        value: this.props.product.comparator.value,
                        error: this.props.product.comparator.error,
                        errorMessage: this.props.product.comparator.errorMessage,
                        data: this.props.product.comparator.data,
                        id: this.props.product.id,
                        label:'Comparator',
                        name: 'comparator'
                    }}/>


          
                    { this.props.product.showLongShort === true && <EditableCell onProductTableUpdate={this.props.onProductTableUpdate} cellData={{
                        type: "text",
                        value: this.props.product.long_params.value,
                        error: this.props.product.long_params.error,
                        errorMessage: this.props.product.long_params.errorMessage,
                        data: this.props.product.long_params.data,
                        id: this.props.product.id,
                        label:'Long Param',
                        name:'long_params'
                    }}/> }
          
                    { this.props.product.showLongShort === true && <EditableCell onProductTableUpdate={this.props.onProductTableUpdate} cellData={{
                        type: "text",
                        value: this.props.product.short_params.value,
                        error: this.props.product.short_params.error,
                        errorMessage: this.props.product.short_params.errorMessage,
                        data: this.props.product.short_params.data,
                        id: this.props.product.id,
                        label:'Short Param',
                        name:'short_params'
                    }}/> }
                </StrategyCellWrapper> 

                <div className='col-md-1' style={{ display:'flex',alignItems:'center',justifyContent:'space-evenly' }}>
                    <button className="btn btn-group" type='button'  onClick={this.onDelEvent.bind(this)} style={{ float: 'right',fontSize:'30px' }}>
                        <i className="fa fa-trash text-danger"></i>
                    </button>
                </div>

                <StrategyFormula formulas={this.props.product.params}  id={this.props.product.id} updateStrategyFormula={this.props.updateStrategyFormula} ></StrategyFormula>
                
            </StrategyRowWrapper>
        </React.Fragment>
    )}
}

class StrategyFormula extends React.Component {
    
    render(){
        
        return(
            <div className="col-md-11"><div className="row">
                { this.props.formulas.map((data,idx) => { return (
                    <EditableCell key={idx} onProductTableUpdate={this.props.updateStrategyFormula} cellData={{
                        type  : data.type,   
                        data  : data.data,
                        id    : this.props.id,
                        value : data.value,
                        error : data.error,
                        label : data.label,
                        name  : data.name,
                        errorMessage: data.errorMessage,
                    }}/>)})
                }
            </div></div>
    )}
}

class EditableCell extends React.Component {
  
    render() { return (
        <Col md={3}>
            <FormGroup>
                <Label htmlFor={this.props.cellData.id}>{this.props.cellData.label}</Label>          
                <Input 
                    type={this.props.cellData.type} 
                    name={this.props.cellData.name} 
                    id={this.props.cellData.id} value={this.props.cellData.value} 
                    onChange={this.props.onProductTableUpdate}
                    invalid={this.props.cellData.error ? true : false}
                >
                    <option>-- Select --</option>
                  { this.props.cellData.data !== undefined && this.props.cellData.data.map((data,idx) => <option key={idx} value={data.value}>{ data.label }</option>) }
                </Input>
                <FormFeedback>{this.props.cellData.errorMessage}</FormFeedback>
            </FormGroup>
        </Col>
    )}
}

export default FormulaBasedStrategy 

 