import React from 'react';
import store from 'store';


// @import-dependencies-------------------------------------------------------------------------------------

    import { tradeService } from 'services/trade_service';

    import Qodrr from 'hooks/QodrrContext';

 
// ---------------------------------------------------------------------------------------------------------

// @import-components---------------------------------------------------------------------------------------

    import DataTable from 'react-data-table-component';
    import DataTableExtensions from 'react-data-table-component-extensions'; 
    import LoadingOverlay from 'react-loading-overlay';
    import 'react-data-table-component-extensions/dist/index.css';

    import Icon from 'assets/img/icon/table-icon.svg';
    import Popover from 'components/Popover';

    import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';
    import NotificationSystem from 'react-notification-system';

    import parseQueryString from 'query-string';
    import moment from 'moment';

//----------------------------------------------------------------------------------------------------------





class PaymentHistory extends React.Component {

  constructor() {
      super();

      this.state   = { auth: undefined, loading: true, data: [] }
      this.state.export  =  false;
      this.state.filterPlaceholder = "Filter Data"
      this.state.columns = [
        {
          name: 'Plan',
          sortable: true,
          selector : data => data.payment_request.purpose
        },
        {
          name: 'Request ID',
          sortable: true,
          selector : data => data.payment_request.id
        },
        {
          name: 'Requested At',
          sortable: true,
          selector : data => this.timeFormatterMoment(data.payment_request.created_at)
        },        
        {
          name: 'Request Status',
          sortable: true,
          selector : data => data.payment_request.status
        },
        {
          name: 'Payment ID',
          sortable: true,
          selector : data => data.payment_info === undefined ? '' : data.payment_info.payment_id

        },
        {
          name: 'Payment Status',
          sortable: true,
          selector : data => data.payment_info === undefined ? '' : data.payment_info.status
        }]
    
      this.userData = store.get('user_data');
      this.timeFormatterMoment = this.timeFormatterMoment.bind(this);
  }


  componentDidMount() { 

    this.loadPayments(); // call payment data api 
  
    const response = parseQueryString.parse(this.props.location.search)

    if(Object.keys(response).length > 0) this.confirmSubscription(response);
  }

  timeFormatterMoment(date) {

    if (date === null || date === undefined) { return 'NA' }

    // return  <Moment parse="YYYY-MM-DD HH:mm:ss">{date}</Moment>

    return moment(date).format('dddd MMM-DD-yyyy h:mA [(GMT] Z[)]',);
  }

  notification(error){
      this.notificationSystem.addNotification({
          title: <></>,
          message: error,
          level: 'info',
      })
  }

  async loadPayments(){

    this.setState({ loading : true })
  
      try {

          const serverResponse = await tradeService.getUserPaymentHistory({"username" : this.userData.username })

          if(serverResponse.statusCode === "200") this.setState({ data : serverResponse.data });

      } catch (error) { this.notification('Payment history is not available') }

    this.setState({ loading : false })
  }

  async confirmSubscription(response){

      this.setState({ loading : true })

      try {

          const payload = {
              username       : this.userData.username,
              payment_req_id : response.payment_request_id,
              payment_id     : response.payment_id,
              payment_status : response.payment_status,
              code           : store.get("plan_data"),
              plan_type      : store.get("plan_type")
          }

          const serverResponse = await (() => {

              if(store.get('is_membership_renewal') === undefined || store.get('is_membership_renewal') === false){

                  return tradeService.confirmSubscription(payload)
              }
              
              return tradeService.verifyMembershipRenewal(payload)
          })()

          if (serverResponse.statusCode !== '200') throw new Error('An Error occurred while processing payment');

            this.notification('Payment processing completed successfully'); 
          
            this.loadPayments() // call api on subscription confirm 

            store.remove("is_membership_renewal"); 
            store.remove("plan_type");
            store.remove("plan_data");

            const axiosResponse = await tradeService.getUserConfig(); // calling config api to update user details

            if (axiosResponse.statusCode === "200"){

                const user_data = {
                  ...this.userData,
                  ...axiosResponse.data
                };

                this.context.updateConfig(axiosResponse.data);

                this.setState({ userData: user_data }); this.forceUpdate();

                store.set('user_data', user_data, new Date().getTime() + 1 * 60 * 60 * 1000); //hour * minute * seconds * 1000
            } 
        
      } catch (error) { this.notification('An Error occurred while processing payment')  }
   }

  dataTableCell(data) { return this.setState({ data }); }

  render() {return (
    <LoadingOverlay spinner active={this.state.loading} text="Loading content...">
      <div className="container-fluid"><div className="data-table">
          <div className="table-head"><div className="row">
              <div className="col-md-6"><div className="head">
                  <h4>
                    <img className="table-icon" src={Icon} alt="..." />
                    Payment History <Popover message="Get your payment history details" />
                  </h4>
              </div></div>
          </div></div>

          <NotificationSystem
              dismissible={false}
              ref={notificationSystem => (this.notificationSystem = notificationSystem)}
              style={NOTIFICATION_SYSTEM_STYLE}
          ></NotificationSystem>

          <DataTableExtensions className="main" {...this.state}>
              <DataTable
                columns={this.state.columns}
                data={this.state.data}
                noHeader
                paginationRowsPerPageOptions={[10, 20, 50, 100]}
                defaultSortField="id"
                defaultSortAsc={false}
                pagination
                highlightOnHover
              ></DataTable>
          </DataTableExtensions>
      </div></div>
      </LoadingOverlay>
    )}
}

PaymentHistory.contextType = Qodrr;

export default PaymentHistory;

