import React from "react";

const Note = () => {
    return (
        <div className='strategy-response-modal-note'>
            <span className='pl-4'>Note : Above performance details include trade executed in auto trade,paper trade,notify trade mode</span>
        </div>  
    )
}

export default Note