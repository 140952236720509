import Cycle from 'assets/img/icon/cycle.svg';
import React from 'react';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import LoadingOverlay from 'react-loading-overlay';
import { tradeService } from 'services/trade_service';
import store from 'store';
import Ticon from '../assets/img/icon/table-icon.svg';
import Popover from '../components/Popover';

class RSIScreener extends React.Component {
  constructor() {
    super();
    this.state = {
      loader : false,
      refreshTime : new Date(),
      auth: undefined,
      data: [],
      export:false,
      columns: [
        {
          name: 'SR.',
          selector: 'serial',
          sortable: true,
        },
        {
          name: 'Symbol',
          selector: 'ticker',
          sortable: true,
        },
        {
          name: 'LTP',
          selector: 'close',
          sortable: true,
        },
        {
          name: 'Volume',
          selector: 'volume',
          sortable: true,
        },
        {
          name: 'RSI(14)',
          selector: 'rsi_14',
          sortable: true,
        },
        {
          name: 'RSI Zone',
          selector: 'rsi_zone',
          cell: d => this.rsiZoneformatter(d.rsi_zone),
          sortable: true,
        },
      ],
    };
    this.userData = store.get('user_data');
    this.getRSIData = this.getRSIData.bind(this)
  }
  signalFormatter(cell, row) {
    if (cell === '3') {
      return <div className="badge badge-success">C3</div>;
    }
    if (cell === '5') {
      return <div className="badge badge-danger">C5</div>;
    }
  }
  rsiZoneformatter(cell, row) {
    if (cell === 'Neutral') {
      return <p style={{ color: '#006699' }}>Neutral</p>;
    }
    if (cell === 'Overbought') {
      return <p style={{ color: 'green' }}>Overbought</p>;
    }
    if (cell === 'OverSold') {
      return <p style={{ color: 'red' }}>OverSold</p>;
    }
  }




  getRSIData(){

    this.setState({ loader : true }) /* show loader */
      
    tradeService.getRSI().then(result => {
     
      if (result.statusCode === '200') { 
          this.setState({ 
              loader : false, refreshTime : result.date, 
              data: result.message.map((data,index) => { data.serial = index + 1; return data }) 
          });   
      }

    }).catch(function (error) { this.setState({ loader : false }); console.log('error occured while calling API'); });

  }


  componentDidMount() { this.getRSIData() }

  dataTableCell(data) { return this.setState({ data }); }

  render() { return (

    <LoadingOverlay active={this.state.loader} spinner text="Loading content...">
        <div className="container-fluid"><div className="data-table">
            <div className="table-head">
                <div className="row">
                    <div className="col-md-6"><div className="head">
                        <h4>
                            <img className="table-icon" src={Ticon} alt="..." />RSI Screener{' '}
                            <Popover message="RSI Screener Over Bought / Over Sold status of Nifty-500 Stocks." />
                        </h4>
                    </div></div>
                    <div className="col-md-6"><div className="cycle-btn">
                        <button className="btn btn-group" onClick={this.getRSIData}>
                            <img src={Cycle} alt="..." />
                            <span> Last refereshed at { String(this.state.refreshTime) }</span>
                        </button>
                    </div></div>
                </div>

                <div className="log-content">
                    <p>
                        RSI Screener Over Bought / Over Sold status of Nifty-500 Stocks.
                        Overbought above RSI value 70 and Oversold below RSI value 30.
                    </p>
                </div>
            </div>

            <div className="main">
                <DataTableExtensions {...this.state}>
                    <DataTable
                        columns={this.state.columns}
                        data={this.state.data}
                        noHeader
                        defaultSortField="id"
                        defaultSortAsc={false}
                        paginationRowsPerPageOptions={[10, 20, 50, 100]}
                        pagination
                        highlightOnHover
                    ></DataTable>
                </DataTableExtensions>
            </div>
        </div></div>
    </LoadingOverlay>
  )}
}

export default RSIScreener;
