import React from 'react';
import Success from 'assets/img/icon/success.svg';
import Error from 'assets/img/icon/error.svg';


const StepFive = props => {
  const toggleClass = props.activeStep === 'stepFive' ? 'step step5' : 'step step5 hidden';

    const SuccessComponent = (props) => {

       return (
         <React.Fragment>
              <img src={Success} alt="..." />
              <div className="success-message">
                   
                    { props.update === false &&  <h3>Successfully Built</h3>  }
                    { props.update === true  &&  <h3>Successfully Update </h3>  }

                    { props.update === false && <p>Your strategy is been successfully created!</p> }
                    { props.update === true  && <p>Your strategy is been update successfully</p>   }
              </div>
         </React.Fragment>
    )}

    const ErrorComponent = () => {
      return (
        <React.Fragment>
             <img src={Error} alt="..." />
             <div className="success-message">
                   <h3>Strategy validation failed</h3>
                   <p>Your strategy validation has been failed kindly recheck the parameters</p>
             </div>
        </React.Fragment>
   )}

  return (
    <div className={toggleClass}>
        <div className="success"><div className="success-head">
             { props.status === true   && <SuccessComponent { ...props} ></SuccessComponent> }
             { props.status === false  && <ErrorComponent { ...props} ></ErrorComponent> }
        </div></div>
    </div>
  );
};

export default StepFive;
