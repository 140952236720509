import React from 'react';
import store from 'store';
import { FormGroup, Label, Input } from 'reactstrap';
import classnames from 'classnames';
import Ticon from '../assets/img/icon/table-icon.svg';
import ProImage from '../assets/img/users/profiles.svg';
import { tradeService } from 'services/trade_service';
import Upload from '../assets/img/icon/upload.svg';
import UpImage from '../assets/img/icon/upimage.svg';
import Select from 'react-select'

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Form,
} from 'reactstrap';
import countries from 'data/CountryCodes';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';

class Profile extends React.Component {
  constructor() {
    super();
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '4',
      auth: undefined,
      formValues: {
        username: '',
      },
      formValuesBank: {
        bank_name: '',
      },
      formValuesPassword: {
        password: '',
      },
    };

    this.handleCountryChange = this.handleCountryChange.bind(this)

  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  componentDidMount() {
    console.clear();
    tradeService
      .getProfile({
        username: store.get('user_data').username,
      })
      .then(result => {
        this.setState({ loading: false });
        if (result.statusCode === '200') {
          if (result.statusCode === '200') {
            this.setState({ formValues: result.data });
          }
        }
      })
      .catch(function (error) {
        console.log('error occured while calling API');
        //console.log(error);
      });
  }
  //-----------Profile Details Code-----------
  handleChange(event) {
    event.preventDefault();
    let formValues = this.state.formValues;
    let name = event.target.name;
    let value = event.target.value;
    formValues[name] = value;
    this.setState({ formValues });
  }

  handleCountryChange(allCountries,info){

    let formValues = this.state.formValues;
    let name = info.name;
    let value = allCountries;
    formValues[name] = value;
    this.setState({ formValues });

  }

  handleSubmitProfile(event) {
    event.preventDefault();
    this.setState({ loading: true, loginError: '' });
    //API Related Work Pending
    
    return void(0);
    tradeService
      .updatedUserDetails(this.state.formValues)
      .then(result => {
        this.setState({ loading: false });
        if (result.statusCode === '200') {
          this.notificationSystem.addNotification({
            title: <></>,
            message: 'Profile details Updated Sucessfully!',
            level: 'info',
          });
          // this.props.history.replace('/profile');
        } else {
          this.setState({
            loginError: 'An error occured during saving information.',
          });
        }
      })
      .catch(function (error) {
        this.setState({
          loginError: 'An error occured while conntecting to API',
        });
        console.log('error occured while calling API');
      });
  }
  //-----------Bank Details Code-----------
  handleChangeBank(event) {
    event.preventDefault();
    let formValuesBank = this.state.formValuesBank;
    let name = event.target.name;
    let value = event.target.value;
    formValuesBank[name] = value;
    this.setState({ formValuesBank });
  }
  handleSubmitBank(event) {
    event.preventDefault();
    this.setState({ loading: true, loginError: '' });
    return void(0);
    if (
      this.state.formValuesBank['account_no'] !==
      this.state.formValuesBank['account_no_confirm']
    ) {
      this.notificationSystem.addNotification({
        title: <></>,
        message: 'Account No Does not Matched!',
        level: 'info',
      });
      this.setState({ confirmPassword: event.target.value });
    } else {
      tradeService
        .updatedUserDetails(this.state.formValues)
        .then(result => {
          this.setState({ loading: false });
          if (result.statusCode === '200') {
            this.notificationSystem.addNotification({
              title: <></>,
              message: 'Bank details Updated Sucessfully!',
              level: 'info',
            });
            // this.props.history.replace('/profile');
          } else {
            this.setState({
              loginError: 'An error occured during saving information.',
            });
          }
        })
        .catch(function (error) {
          this.setState({
            loginError: 'An error occured while conntecting to API',
          });
          console.log('error occured while calling API');
        });
    }
  }

  //-----------Password Change Code-----------
  handleChangePassword(event) {
    event.preventDefault();
    let formValuesPassword = this.state.formValuesPassword;
    let name = event.target.name;
    let value = event.target.value;
    formValuesPassword[name] = value;
    this.setState({ formValuesPassword });
  }
  handleSubmitPassword(event) {
    event.preventDefault();
    this.setState({ loading: true, loginError: '' }); 
    if (
      this.state.formValuesPassword['password'] !==
      this.state.formValuesPassword['confirmPassword']
    ) {
      this.notificationSystem.addNotification({
        title: <></>,
        message: 'Password not Matched!',
        level: 'info',
      });
      this.setState({ confirmPassword: event.target.value });
    } else {
      this.state.formValues['password'] = this.state.formValuesPassword['password']
      tradeService
        .updatedUserDetails(this.state.formValues)
        .then(result => {
          this.setState({ loading: false });
          if (result.statusCode === '200') {
            this.notificationSystem.addNotification({
              title: <></>,
              message: 'Password Updated Sucessfully!',
              level: 'info',
            });
            // this.props.history.replace('/profile');
          } else {
            this.setState({
              loginError: 'An error occured during saving information.',
            });
          }
        })
        .catch(function (error) {
          this.setState({
            loginError: 'An error occured while conntecting to API',
          });
          console.log('error occured while calling API');
        });
    }
  }

  render() {
    return (
      <>
        <div className="container-fluid">
          <div className="profiles">
            <div className="profile-head">
              <h4>
                <img className="table-icon" src={Ticon} alt="..." />
                Profile
              </h4>
            </div>
            <div className="profile-tab">
              <Nav tabs>
                {/* <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === '1',
                    })}
                    onClick={() => {
                      this.toggle('1');
                    }}
                  >
                    Profile details
                  </NavLink>
                </NavItem> */}
                {/* <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === '2',
                    })}
                    onClick={() => {
                      this.toggle('2');
                    }}
                  >
                    Bank details
                  </NavLink>
                </NavItem> */}
                {/* <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === '3',
                    })}
                    onClick={() => {
                      this.toggle('3');
                    }}
                  >
                    Document
                  </NavLink>
                </NavItem> */}
                {/* <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === '4',
                    })}
                    onClick={() => {
                      this.toggle('4');
                    }}
                  >
                    Change password
                  </NavLink>
                </NavItem> */}
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  <Row>
                    <Col sm="12">
                      <div className="section-one">
                        <div className="pro-detail">
                          <div className="pro-imgs">
                            <img src={ProImage} alt="..."></img>
                            <div className="pro-text">
                              <h4>Add Image</h4>
                            </div>
                          </div>
                        </div>
                        <Form
                          action=""
                          method="post"
                          onSubmit={this.handleSubmitProfile.bind(this)}
                          name="profile-form"
                          encType="multipart/form-data"
                          className="form-horizontal"
                        >
                          <div className="personal-detail">
                            <div className="per-detail">
                              <div className="personal-head">
                                <h4>Personal Details</h4>
                              </div>
                              <div className="row">
                                <div className="col-md-4">
                                  <FormGroup>
                                    <Label for="exampleEmail">User name</Label>
                                    <Input
                                      type="text"
                                      name="username"
                                      onChange={this.handleChange.bind(this)}
                                      value={this.state.formValues['username']}
                                      placeholder="Ex: Sonali124"
                                      disabled='true'
                                    />
                                  </FormGroup>
                                </div>
                                <div className="col-md-4">
                                  <FormGroup>
                                    <Label for="exampleEmail">Mobile</Label>
                                    <Input
                                      type="text"
                                      name="phone_number"
                                      onChange={this.handleChange.bind(this)}
                                      value={
                                        this.state.formValues['phone_number']
                                      }
                                      placeholder="Ex: Sonali124"
                                    />
                                  </FormGroup>
                                </div>
                                <div className="col-md-4">
                                  <FormGroup>
                                    <Label for="exampleEmail">Aadhar No.</Label>
                                    <Input
                                      type="text"
                                      name="aadhar_no"
                                      onChange={this.handleChange.bind(this)}
                                      value={this.state.formValues['aadhar_no']}
                                      placeholder="Aadhar No."
                                    />
                                  </FormGroup>
                                </div>
                                <div className="col-md-4">
                                  <FormGroup>
                                    <Label for="exampleEmail">Email Id</Label>
                                    <Input
                                      type="text"
                                      name="email"
                                      onChange={this.handleChange.bind(this)}
                                      value={this.state.formValues['email']}
                                      placeholder="Email"
                                      disabled='true'
                                    />
                                  </FormGroup>
                                </div>
                                <div className="col-md-4">
                                  <FormGroup>
                                    <Label for="exampleEmail">Pan No.</Label>
                                    <Input
                                      type="text"
                                      name="pan_no"
                                      onChange={this.handleChange.bind(this)}
                                      value={this.state.formValues['pan_no']}
                                      placeholder="Pan No."
                                    />
                                  </FormGroup>
                                </div>
                                <div className="col-md-4">
                                  <FormGroup>
                                    <Label for="exampleEmail">
                                      GST (Optional)
                                    </Label>
                                    <Input
                                      type="text"
                                      name="gst_no"
                                      onChange={this.handleChange.bind(this)}
                                      value={this.state.formValues['gst_no']}
                                      placeholder="GST No"
                                    />
                                  </FormGroup>
                                </div>
                              </div>
                            </div>
                            <div className="per-detail">
                              <div className="personal-head">
                                <h4>Address Details</h4>
                              </div>
                              <div className="row">
                                <div className="col-md-4">
                                  <FormGroup>
                                    <Label for="exampleEmail">
                                      Flat Number
                                    </Label>
                                    <Input
                                      type="text"
                                      name="flat_no"
                                      onChange={this.handleChange.bind(this)}
                                      value={this.state.formValues['flat_no']}
                                      placeholder="Flat Number"
                                    />
                                  </FormGroup>
                                </div>
                                <div className="col-md-4">
                                  <FormGroup>
                                    <Label for="exampleEmail">
                                      Address line
                                    </Label>
                                    <Input
                                      type="text"
                                      name="address_line"
                                      onChange={this.handleChange.bind(this)}
                                      value={
                                        this.state.formValues['address_line']
                                      }
                                      placeholder="Address line"
                                    />
                                  </FormGroup>
                                </div>
                                <div className="col-md-4">
                                  <FormGroup>
                                    <Label for="exampleEmail">Landmark</Label>
                                    <Input
                                      type="text"
                                      name="landmark"
                                      onChange={this.handleChange.bind(this)}
                                      value={this.state.formValues['landmark']}
                                      placeholder="Landmark"
                                    />
                                  </FormGroup>
                                </div>
                                <div className="col-md-4">
                                  <FormGroup>
                                    <Label for="exampleEmail">City</Label>
                                    <Input
                                      type="text"
                                      name="city"
                                      onChange={this.handleChange.bind(this)}
                                      value={this.state.formValues['city']}
                                      placeholder="City"
                                    />
                                  </FormGroup>
                                </div>
                                <div className="col-md-4">
                                  <FormGroup>
                                    <Label for="exampleSelect">State</Label>
                                    <Input
                                      type="text"
                                      className="first-show"
                                      name="state"
                                      value={this.state.formValues['state']}
                                      id="state"
                                      placeholder="State"
                                    />  
                                  </FormGroup>
                                </div>
                                <div className="col-md-4">


                                  <FormGroup>
                                    <Label for="exampleSelect">Country</Label>

                                    <Select name="country" onChange={(allCountries,info) => this.handleCountryChange(allCountries,info)} className="first-show" options={countries.map((data) => { return { label: data.name,value : data.code } })} />

                                    {/* <Input
                                      type="select"
                                      className="first-show"
                                      name="country"
                                      onChange={this.handleChange.bind(this)}
                                      id="exampleSelect"
                                    >
                                      <option>Select Country</option>
                                      {countries.map((data, idx) => (
                                        <option key={idx} value={data.code}>
                                          {data.name}
                                        </option>
                                      ))}
                                    </Input> */}
                                  </FormGroup>

                                </div>
                                <div className="col-md-12">
                                  <div className="save-btn">
                                    <button
                                      onClick={this.handleSubmitProfile.bind(
                                        this,
                                      )}
                                      className="btn btn-group"
                                    >
                                      Save
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col sm="12">
                      <div className="section-one med-screen">
                        <div className="main-tab">
                          <div className="per-detail ">
                            <Form
                              action=""
                              method="post"
                              onSubmit={this.handleSubmitBank.bind(this)}
                              name="Bank-form"
                              encType="multipart/form-data"
                              className="form-horizontal"
                            >
                              <div className="row">
                                <div className="col-md-6">
                                  <FormGroup>
                                    <Label for="exampleEmail">Bank Name</Label>
                                    <Input
                                      type="text"
                                      name="bank_name"
                                      value={
                                        this.state.formValuesBank['bank_name']
                                      }
                                      onChange={this.handleChangeBank.bind(
                                        this,
                                      )}
                                      placeholder="Bank Name"
                                      required="True"
                                    />
                                  </FormGroup>
                                </div>
                                <div className="col-md-6">
                                  <FormGroup>
                                    <Label for="exampleEmail">
                                      Branch Name
                                    </Label>
                                    <Input
                                      type="text"
                                      name="branch_name"
                                      value={
                                        this.state.formValuesBank['branch_name']
                                      }
                                      onChange={this.handleChangeBank.bind(
                                        this,
                                      )}
                                      placeholder="Branch Name"
                                      required="True"
                                    />
                                  </FormGroup>
                                </div>
                                <div className="col-md-6">
                                  <FormGroup>
                                    <Label for="exampleEmail">
                                      Account No.
                                    </Label>
                                    <Input
                                      type="text"
                                      name="account_no"
                                      value={
                                        this.state.formValuesBank['account_no']
                                      }
                                      onChange={this.handleChangeBank.bind(
                                        this,
                                      )}
                                      placeholder="Account No."
                                      required="True"
                                    />
                                  </FormGroup>
                                </div>
                                <div className="col-md-6">
                                  <FormGroup>
                                    <Label for="exampleEmail">
                                      Re-enter Account no.
                                    </Label>
                                    <Input
                                      type="text"
                                      name="account_no_confirm"
                                      value={
                                        this.state.formValuesBank[
                                          'account_no_confirm'
                                        ]
                                      }
                                      onChange={this.handleChangeBank.bind(
                                        this,
                                      )}
                                      placeholder="Re-enter Account no."
                                      required="True"
                                    />
                                  </FormGroup>
                                </div>
                                <div className="col-md-6">
                                  <FormGroup>
                                    <Label for="exampleEmail">
                                      Bank IFSC code
                                    </Label>
                                    <Input
                                      type="text"
                                      name="ifsc_code"
                                      value={
                                        this.state.formValuesBank['ifsc_code']
                                      }
                                      onChange={this.handleChangeBank.bind(
                                        this,
                                      )}
                                      placeholder="Bank IFSC code"
                                      required="True"
                                    />
                                  </FormGroup>
                                </div>
                                <div className="col-md-12">
                                  <div className="save-btn">
                                    <button
                                      type="submit"
                                      className="btn btn-group"
                                    >
                                      Save
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </Form>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <Row>
                    <Col sm="12">
                      <div className="section-one small-screen">
                        <div className="documnent">
                          <div className="row">
                            <div className="col-md-10">
                              <FormGroup>
                                <Label for="exampleSelect">Document type</Label>
                                <Input
                                  type="select"
                                  className="first-show"
                                  name="select"
                                  id="exampleSelect"
                                >
                                  <option>Select Country</option>
                                  <option>2</option>
                                  <option>3</option>
                                  <option>4</option>
                                  <option>5</option>
                                </Input>
                              </FormGroup>
                            </div>
                            <div className="col-md-2 mob-respo">
                              <div className="upload-btn">
                                <button className="btn btn-group">
                                  <img src={Upload} alt="..." />
                                </button>
                              </div>
                            </div>
                            <div className="col-md-12 mob-respo">
                              <div className="upload area">
                                <div className="upload-detail">
                                  <img src={UpImage} alt="..." />
                                  <div className="up-btn">
                                    <button className="btn btn-group">
                                      <i
                                        className="fa fa-eye"
                                        style={{ color: '#00AAFF' }}
                                      ></i>
                                    </button>
                                    <button className="btn btn-group">
                                      <i
                                        className="fa fa-trash"
                                        style={{ color: '#FF6868' }}
                                      ></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </TabPane>


                <TabPane tabId="4">
                  <Row>
                    <Col sm="12">
                      <div className="section-one extra-small-screen">
                        <div className="documnent">
                          <Form
                              action="" method="post" name="Password-form" encType="multipart/form-data"
                              onSubmit={this.handleSubmitPassword.bind(this)}
                              className="form-horizontal"
                          >
                            <div className="row">
                              <div className="col-md-12">
                                <FormGroup>
                                  <Label for="exampleEmail">Old password</Label>
                                  <div className="input-group in-bord">
                                    <Input type="old_password" placeholder="Password" />
                                    <div className="input-group-append app-view">
                                      <button className="btn btn-group" type='button'>
                                        <i className="fa fa-eye" style={{ color: '#999999' }}></i>
                                      </button>
                                    </div>
                                  </div>
                                </FormGroup>
                              </div>
                              
                              <div className="col-md-12">
                                <FormGroup>
                                  <Label for="exampleEmail">New password</Label>
                                  <div className="input-group in-bord">
                                    <Input
                                      type="password"
                                      name="password"
                                      onChange={this.handleChangePassword.bind(
                                        this,
                                      )}
                                      placeholder="New password"
                                      required="True"
                                    />
                                    <div className="input-group-append app-view">
                                      <button className="btn btn-group">
                                        <i
                                          className="fa fa-eye"
                                          style={{ color: '#999999' }}
                                        ></i>
                                      </button>
                                    </div>
                                  </div>
                                </FormGroup>
                              </div>
                              <div className="col-md-12">
                                <FormGroup>
                                  <Label for="exampleEmail">
                                    Confirm password
                                  </Label>
                                  <div className="input-group in-bord">
                                    <Input
                                      type="password"
                                      name="confirmPassword"
                                      onChange={this.handleChangePassword.bind(
                                        this,
                                      )}
                                      placeholder="Confirm password"
                                      required="True"
                                    />
                                    <div className="input-group-append app-view">
                                      <button className="btn btn-group">
                                        <i
                                          className="fa fa-eye"
                                          style={{ color: '#999999' }}
                                        ></i>
                                      </button>
                                    </div>
                                  </div>
                                </FormGroup>
                              </div>
                              <div className="col-md-12">
                                <div className="save-btn">
                                  <button
                                    type="submit"
                                    className="btn btn-group"
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
        <NotificationSystem
          dismissible={false}
          ref={notificationSystem =>
            (this.notificationSystem = notificationSystem)
          }
          style={NOTIFICATION_SYSTEM_STYLE}
        />
      </>
    );
  }
}

export default Profile;
