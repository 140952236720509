import React from 'react'
import DropDownField from '../DropDownField'
import StepHeading from '../StepHeading'
import TextField from '../TextField'

const RenkoChartDetails = (props) => {
    return (
        <React.Fragment>
            <StepHeading text="Renko Chart Details" id="step-two-renko-chart-details" content="Define Renko Chart Brick Size. It can be either in precentage like 0.2 or absolute value like 20."></StepHeading>
            <div className="row form-detail">
                <DropDownField
                    label="Renko Box Size Type"
                    type="select"
                    name="r_box_type"
                    id="r_box_type"
                    md="4"
                    data={props.renkoBoxSizeType}
                    value={props.state.r_box_type.value}
                    error={props.state.r_box_type.error}
                    errorMessage={props.state.r_box_type.errorMessage}
                    method={props.changeHandler}
                ></DropDownField>
                <TextField
                    label="Renko Box Size Value"
                    type="text"
                    name="r_box_val"
                    id="r_box_val"
                    md="4"                    
                    value={props.state.r_box_val.value}
                    error={props.state.r_box_val.error}
                    errorMessage={props.state.r_box_val.errorMessage}
                    method={props.changeHandler}
                ></TextField>
                <TextField
                    label="Confirmation Box Count"
                    type="text"
                    name="r_box_cnf"
                    id="r_box_cnf"
                    md="4"
                    value={props.state.r_box_cnf.value}
                    error={props.state.r_box_cnf.error}
                    errorMessage={props.state.r_box_cnf.errorMessage}
                    method={props.changeHandler}
                ></TextField>
            </div>
        </React.Fragment>
    )
}

export default RenkoChartDetails