import icon from 'assets/img/icon/detail.svg';
import React from 'react';
import { Popover, PopoverBody } from 'reactstrap';

const CustomPopover = props => {
  const [toolTip, toggleToolTip] = React.useState(false);

  return (
    <React.Fragment>
      {' '}
      {props.text}{' '}
      <img
        onMouseOver={() => toggleToolTip(true)}
        onMouseOut={() => toggleToolTip(false)}
        src={icon}
        alt="..."
        id={props.id === undefined ? 'no-data' : props.id}
      />
      <Popover
        placement="right"
        className="img-text"
        isOpen={toolTip}
        autohide={false}
        target={props.id === undefined ? 'no-data' : props.id}
        toggle={() => toggleToolTip(!toolTip)}
      >
        <PopoverBody className="content_img img-text">
          {props.message}
        </PopoverBody>
      </Popover>
    </React.Fragment>
  );
};

export default CustomPopover;
