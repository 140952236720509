
import React from "react"

const MountComponent = ({ condition, children , data }) => {

    if(condition === true) { return children }

    return <React.Fragment></React.Fragment>
} 

export default MountComponent