import moment from 'moment';
import React, { Component, Suspense } from 'react';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import LoadingOverlay from 'react-loading-overlay';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
} from 'reactstrap';
//import { UserContext } from 'hooks/UserContext';
import { tradeService } from 'services/trade_service';
import store from 'store';
import ExpandableTableRow, {
  ExpandableTableRowLogs,
} from './ExpandableTableRow';

var DatePicker = require('reactstrap-date-picker');

const LineChart = React.lazy(() => import('./LineChart')); // Lazy-loaded

// const LOG_DATA = [
//   {
//     id: 'Banknifty',
//     data: [
//       { x: "2021-10-20T10:54:00'", y: 25000 },
//       { x: '2021-10-02', y: 25675 },
//       { x: '2021-10-03', y: 23450 },
//       { x: '2021-10-04', y: 23650 },
//       { x: '2021-10-05', y: 22450 },
//       { x: '2021-10-06', y: 21250 },
//       { x: '2021-10-07', y: 24450 },
//       { x: '2021-10-08', y: 27450 },
//     ],
//   },
//   {
//     id: 'Nifty',
//     data: [
//       { x: '2021-10-01', y: 25000 },
//       { x: '2021-10-02', y: 26675 },
//       { x: '2021-10-03', y: 27450 },
//       { x: '2021-10-04', y: 25650 },
//       { x: '2021-10-05', y: 23450 },
//       { x: '2021-10-06', y: 24250 },
//       { x: '2021-10-07', y: 26450 },
//       { x: '2021-10-08', y: 28450 },
//     ],
//   },
// ];

// var d = new Date();
// //console.log(d.toLocaleDateString());
// d.setMonth(d.getMonth() - 1);
// //console.log(d.toLocaleDateString());
// //console.log(d.toISOString().slice(0, 10));
// var mindDate = d.toISOString().slice(0, 10);

export default class BackTest extends Component {
  //static contextType = UserContext;
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      collapse: true,
      activeTab: 'statistics',
      data: [],
      equityCurve: [],
      formData: {
        capital: '',
        quantity: '',
        executionType: 'Delivery',
        startDate: new Date().toISOString(),
        endDate: new Date().toISOString(),
      },
    };
    this.toggleClose = this.toggleClose.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getBackTestData = payload => {
    tradeService
      .getdownloadData(payload)
      .then(result => {
        //let data = [];
        let data = result.data
          ? Array.isArray(result.data)
            ? result.data
            : [result.data]
          : [];
        data = data.filter(x => x);
        // let graphData = data.filter(x => x.graph);
        // graphData = graphData.map(a => a.graph);
        this.setState({
          loading: false,
          data: data,
          equityCurve: data.map(a => a.graph),
        });
      })
      .catch(function (error) {
        console.log('error occured while calling API', error);
        this.setState({ loading: false });
      });
  };
  /**
   * Toggle fade
   */
  toggleClose() {
    this.props.onBTClose();
  }

  toggle = tab => {
    if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
  };

  handleChange(value, key) {
    this.setState({ formData: { ...this.state.formData, [key]: value } });
  }

  handleFormChange(e) {
    const { value, name } = e.target;
    this.setState({ formData: { ...this.state.formData, [name]: value } });
  }

  handleSubmit(evt) {
    evt.preventDefault();
    this.setState({ loading: true });
    var username = store.get('user_data').username;
    const payload = {
      // ...this.state.formData,
      start_date: moment(this.state.formData.startDate).format('YYYY-MM-DD'),
      end_date: moment(this.state.formData.endDate).format('YYYY-MM-DD'),
      // amount: this.state.formData.quantity,
      quantity: this.state.formData.quantity,
      capital: this.state.formData.capital,
      executionType: this.state.formData.executionType,
      username: username,
      code: this.props.code,
    };
    this.getBackTestData(payload);
  }


  minDate() {

    const date = new Date(); const userDetails = store.get('user_data')

    if(userDetails === undefined || this.props.strategyDetails.original_code !== "NA") {
      date.setMonth(date.getMonth() - 1);
    }

    else if (userDetails.user_subscription.includes("STARTER_"))   date.setMonth(date.getMonth() - 1);
    else if (userDetails.user_subscription.includes("SMART_"))  date.setMonth(date.getMonth() - 3);
    else if (userDetails.user_subscription.includes("MASTER_")) date.setMonth(date.getMonth() - 12);
    else if (userDetails.user_subscription.includes("WIZARD_")) date.setMonth(date.getMonth() - 36);
    else date.setMonth(date.getMonth() - 1)

    return date.toISOString().slice(0, 10);
  }

  render() {
    return (
      <LoadingOverlay
        active={this.state.loading}
        spinner
        text="Loading content..."
      >
        <div className="back-test">
          <div className="backtest-card">
            <Card>
              <CardBody>
                <Form onSubmit={this.handleSubmit}>
                  <Row className="align-items-end">
                    <Col sm="2">
                      <FormGroup>
                        <Label for="capital	">Capital</Label>
                        <Input
                          type="number"
                          min={0}
                          name="capital"
                          id="capital"
                          placeholder="Capital"
                          onChange={this.handleFormChange}
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="2">
                      <FormGroup>
                        <Label for="quantity">Quantity</Label>
                        <Input
                          type="number"
                          min={0}
                          name="quantity"
                          id="quantity"
                          placeholder="Quantity"
                          onChange={this.handleFormChange}
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="2">
                      <FormGroup>
                        <Label for="startDate">Start Date</Label>
                        <DatePicker
                          id="startDate"
                          minDate={this.minDate()}
                          value={this.state.formData.startDate}
                          onChange={(v, f) => this.handleChange(v, 'startDate')}
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="2">
                      <FormGroup>
                        <Label for="endDate">End Date</Label>
                        <DatePicker
                          id="endDate"
                          value={this.state.formData.endDate}
                          onChange={(v, f) => this.handleChange(v, 'endDate')}
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="2">
                      <FormGroup>
                        <Label for="executionType">Select</Label>
                        <Input
                          type="select"
                          name="executionType"
                          id="executionType"
                          onChange={this.handleFormChange}
                        >
                          <option value="DELIVERY">Delivery</option>
                          <option value="INTRADAY">Intraday</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col sm="2">
                      <FormGroup className="align-items-end">
                        <button className="btn btn-group">Submit</button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </div>

          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <div className="back-head">
                    <div className="b-head">
                      <i className="fa fa-align-justify"></i> Back Testing
                    </div>
                    <div className="card-header-actions">
                      <button
                        className="card-header-action btn btn-group"
                        onClick={this.toggleClose}
                      >
                        <AiOutlineArrowLeft
                          style={{ marginTop: '4px', marginRight: '5px' }}
                        />
                        Go Back
                      </button>
                    </div>
                  </div>
                </CardHeader>
                <Collapse isOpen={this.state.collapse} id="broker-config-panel">
                  <CardBody>
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={`${
                            this.state.activeTab === 'statistics'
                              ? 'active'
                              : ''
                          }`}
                          onClick={() => {
                            this.toggle('statistics');
                          }}
                        >
                          Statistics
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={`${
                            this.state.activeTab === 'logs' ? 'active' : ''
                          }`}
                          onClick={() => {
                            this.toggle('logs');
                          }}
                        >
                          Log Info
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={`${
                            this.state.activeTab === 'chart' ? 'active' : ''
                          }`}
                          onClick={() => {
                            this.toggle('chart');
                          }}
                        >
                          Equity Curve
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                      <TabPane tabId="statistics">
                        <Row>
                          <Col sm="12">
                            <h4>Statistics</h4>
                            <div className="oneTab">
                              <Table>
                                <thead>
                                  <tr>
                                    <th>Symbol Name</th>
                                    <th>Start Date </th>
                                    <th colSpan={4}>End Date</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.loading ? (
                                    <tr>
                                      <td
                                        colSpan={6}
                                        className="uk-text-center"
                                      >
                                        <em className="uk-text-muted">
                                          Loading...
                                        </em>
                                      </td>
                                    </tr>
                                  ) : this.state.data.length > 0 ? (
                                    this.state.data.map(
                                      (symbol, index) =>
                                        symbol.stats && (
                                          <ExpandableTableRow
                                            key={index}
                                            index={index + 1}
                                            data={JSON.parse(symbol.stats)}
                                          />
                                        ),
                                    )
                                  ) : (
                                    <tr>
                                      <td
                                        colSpan={6}
                                        className="uk-text-center"
                                      >
                                        <em className="uk-text-muted">
                                          No records were found!
                                        </em>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="logs">
                        <Row>
                          <Col sm="12">
                            <Suspense fallback={'Loading...'}>
                              <h4>Trade Log</h4>
                              <div className="oneTab">
                                <Table>
                                  <thead>
                                    <tr>
                                      <th colSpan={6}>Symbol Name</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.loading ? (
                                      <tr>
                                        <td
                                          colSpan={6}
                                          className="uk-text-center"
                                        >
                                          <em className="uk-text-muted">
                                            Loading...
                                          </em>
                                        </td>
                                      </tr>
                                    ) : this.state.data.length > 0 ? (
                                      this.state.data.map(
                                        (symbol, index) =>
                                          symbol.logs && (
                                            <ExpandableTableRowLogs
                                              key={index}
                                              index={index + 1}
                                              data={symbol.logs}
                                            />
                                          ),
                                      )
                                    ) : (
                                      <tr>
                                        <td
                                          colSpan={6}
                                          className="uk-text-center"
                                        >
                                          <em className="uk-text-muted">
                                            No records were found!
                                          </em>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </Table>
                              </div>
                            </Suspense>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="chart">
                        <Row>
                          <Col sm="12">
                            <h4>PnL Curve</h4>
                            <div style={{ height: '400px' }}>
                              <Suspense fallback={'Loading...'}>
                                <LineChart data={this.state.equityCurve} />
                              </Suspense>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Collapse>
              </Card>
            </Col>
          </Row>
        </div>
      </LoadingOverlay>
    );
  }
}
