import React from 'react';
import store from 'store'
import * as validator   from 'validatorjs';
import { tradeService } from 'services/trade_service';

// @import-components && icons --------------------------------------------------------------------------------

    import DataSelection     from './strategyComponents/stepTwo/DataSelection';
    import RenkoChartDetails from './strategyComponents/stepTwo/RenkoChartDetails';
    import StrategyParameters from './strategyComponents/stepTwo/StrategyParameters';
    import OptionStrategy from './strategyComponents/stepTwo/OptionStrategy'
    // import LevelBasedStrategy from './strategyComponents/stepTwo/Products';
    import FormulaBasedStrategy from './strategyComponents/stepTwo/FormulaBasedStrategy';
    import LoadingOverlay from 'react-loading-overlay';

// -------------------------------------------------------------------------------------------------------------

    const strategySelection = [
        { value:'option_buy',     label:'Option Buy'  },
        { value:'option_sell',    label:'Option Sell' },
        { value:'debit_spread',    label:'Debit Spread' },
        { value:'credit_spread',    label:'Credit Spread' }
        //{ value:'straddle_buy',   label:'Straddle Buy'   },
        // { value:'straddle_sell',  label:'Straddle Sell'  },
        // { value:'strangle_buy',   label:'Strangle Buy' },
        // { value:'strangle_sell',  label:'Strangle Sell'  },
    ]
     
    

    const timeFrame   = [
        { value:'1m' , label:'1 Minute' },
        { value:'3m' , label:'3 Minute' },
        { value:'5m' , label:'5 Minute' },
        { value:'10m', label:'10 Minute' },
        { value:'15m', label:'15 Minute' },
        { value:'30m', label:'30 Minute' },
        { value:'60m', label:'1 Hour' }, 
    ]

    const chartType = [
        { label:'OHLC' ,value:'OHLC' },
        { label:'RENKO' ,value:'RENKO' },
        { label:'HAIKIN ASHI', value:'HAIKIN_ASHI'}
    ]

    const renkoBoxSizeType = [
      { label:'Absolute' ,value:'abs' },
      { label:'Percentage' ,value:'per' },
    ]

    const comparator = [
        { label:'Crosses' ,value:'crosses' },
        { label:'Crossover' ,value:'crossover' },
        { label:'Above/Below' ,value:'above_below' },
    ]

// -------------------------------------------------------------------------------------------------------------

const StepTwo = props => {

    const segment = ((userDetails) => {

        if(userDetails === undefined) return []

        if(userDetails.user_subscription.includes('WIZARD_') || 
           userDetails.user_subscription.includes('MASTER_') ||
           userDetails.user_subscription.includes('SMART_')){
           
            return [
                {label:'NSE',value: 'nse'},
                { label:'NFO',value:'future'},
                { label:'MCX', value:'mcx'},
                { label:'Indices Option',value:'fut-opt'},
              ]
        }

        return [
            { label:'NFO',value:'future'},
            { label:'MCX', value:'mcx'},
        ]

    })(store.get('user_data'))

  const toggleClass = props.activeStep === 'stepTwo' ? 'step step2' : 'step step2 hidden';

  const [strategyType, changeStrategyType] = React.useState('formula_based_strategy');
  const [validationButton,toggleValidationButton] = React.useState(true);

  const [loader,toggleLoader] = React.useState(false)
  const [mount,toogleMount]   = React.useState(false)

  const [getStrategyPayload,updateStrategyPayload] = React.useState({ status : false, payload: {} });
  const [symbols,updateSymbols] = React.useState([]);
  const [strikeSelection,updateStrikeSelection]  = React.useState([])

  const submitButton = React.useRef(null);

  const loadSymbols = (value) => { 

    if(value === "") { return true; }

    toggleLoader(true); 
      
    tradeService.symbolList({'exchange': value})
        .then((result) => { updateSymbols(result); toggleLoader(false) })
        .catch((error) => { console.log('error occured while calling API') });
  }

  React.useEffect(() => { setTimeout(() =>{ loadSymbols(localStates.segment.value) },300) },[])
  React.useEffect(() => { toggleValidationButton(true); return () => {} },[props.activeStep])
  React.useEffect(() => { 

    if(props.updateStrategy.status) updatestrategy(props.updateStrategy.payload);

  },[props.updateStrategy.status])

  React.useEffect(() => { if(submitButton.current !== undefined) toogleMount(true) },[submitButton])

  // @states ---------------------------------------------------------------------------------------------------

      const permanentState = {
          segment   : { value: '', error: false, errorMessage: '' },
          timeframe : { value: '', error: false, errorMessage: '' },
          charttype : { value: '', error: false, errorMessage: '' },
          bucket    : { value: [], error: false, errorMessage: '' },
      }

      const renkoChartDetailsState = {
          r_box_type : { value: '', error: false, errorMessage: '' },
          r_box_val  : { value: '', error: false, errorMessage: '' },
          r_box_cnf  : { value: '', error: false, errorMessage: '' },
      }

      const OptionStrategyState = {
          strategySelection : { value: '', error: false, errorMessage: '' },
          strikeSelection   : { value: '', error: false, errorMessage: '' },
      }

      const levelStrategyState = {
        strategy_name : { value: '', error: false, errorMessage: '' },
      }
      const formulaStrategyState = {
        strategy_name : { value: '', error: false, errorMessage: '' },
      }
  
      const initialState = { 
        ...permanentState, 
        ...levelStrategyState, 
        ...formulaStrategyState,
        ...renkoChartDetailsState,
        ...OptionStrategyState
      }

      const [localStates,updateStates] = React.useState(initialState)
      
      const updatestrategy = (data) => { 

            const strategyOptions = ['option_buy','option_sell'];
            const strategyOptionsTwo = ['debit_spread','credit_spread','straddle_buy','straddle_sell','strangle_buy','strangle_sell']
              console.log(data.option_strategy)
              if(data.option_strategy !== undefined && data.option_strategy !== null) { 

                  if(strategyOptions.includes(data.option_strategy.name)) updateStrikeSelection([{ value:'ATM', label:'ATM' }])

                  else if(strategyOptionsTwo.includes(data.option_strategy.name)) updateStrikeSelection([
                      { value:'ATM_1' , label:'ATM 1' },
                      { value:'ATM_2' , label:'ATM 2' },
                      { value:'ATM_3' , label:'ATM 3' },
                      { value:'ATM_4' , label:'ATM 4' },
                      { value:'ATM_5' , label:'ATM 5' }
                  ])
              }

          updateStates((state) => {

            if(data.option_strategy !== undefined && data.option_strategy !== null){ 
              
              state.strategySelection.value = data.option_strategy.name   === undefined ? state.strategySelection.value : data.option_strategy.name
              state.strikeSelection.value   = data.option_strategy.strike === undefined ? state.strikeSelection.value   : data.option_strategy.strike
            }

              state.segment.value   = data.segment   === undefined ? state.segment.value   : data.segment
              state.timeframe.value = data.timeframe === undefined ? state.timeframe.value : data.timeframe
              state.charttype.value = data.charttype === undefined ? state.charttype.value : data.charttype
              state.bucket.value    = data.bucket    === undefined ? state.bucket.value    : data.bucket

              if(data.charttype === 'OHLC' || data.charttype === undefined) return state;

              state.r_box_type.value = data.r_box_type === undefined ? state.r_box_type.value : data.r_box_type
              state.r_box_val.value  = data.r_box_val  === undefined ? state.r_box_val.value  : data.r_box_val
              state.r_box_cnf.value  = data.r_box_cnf  === undefined ? state.r_box_cnf.value  : data.r_box_cnf

            return state
          })
      }  

  // @validation ----------------------------------------------------------------------------------------------

    const formValidation = () => {

      // @custom-validation-rules -------------------------------------------------------------

      // --------------------------------------------------------------------------------------

        const renkoChartDetailsData = {
          r_box_type : localStates.r_box_type.value,
          r_box_val  : localStates.r_box_val.value,
          r_box_cnf  : localStates.r_box_cnf.value,
        }

        const renkoChartDetailsValidations = {
          r_box_type : 'required', 
          r_box_val  : 'required|numeric',
          r_box_cnf  : 'required|numeric',
        }

        const optionStrategyData = {
            strategySelection : localStates.strategySelection.value, 
            strikeSelection   : localStates.strikeSelection.value,
        }
        const optionStrategyValidations = {
            strategySelection : 'required',
            strikeSelection   : 'required'
        }

        const levelbasedStrategyData = {}
        const levelbasedStrategyValidations = {}

        const formulaBasedStrategyData = {}
        const formulaBasedStrategyValidations = {}

    // -------------------------------------------------------------------------------------------------------------
      
      const validationData = { 
          segment    : localStates.segment.value, 
          timeframe  : localStates.timeframe.value, 
          charttype  : localStates.charttype.value,
          bucket     : localStates.bucket.value,
      };

      const validationRules = { 
          segment    : 'required', 
          timeframe  : 'required',
          charttype  : 'required',
          bucket     : 'required',
      };

      const errorMessages = {

          "required.segment"    : "Please describe segment for strategy",
          "required.timeframe"  : "Please describe time frame for strategy",
          "required.charttype"  : "Please describe chart type for strategy",
          "required.bucket"     : "Please describe symbols for strategy",

          "required.r_box_type" : "Please describe renko box type",
          "required.r_box_val"  : "Please describe renko box size",
          "required.r_box_cnf"  : "Please describe renko box confirmation",

          "numeric.r_box_val"  : "Please describe positive numeric number for renko box size",
          "numeric.r_box_cnf"  : "Please describe positive numeric number for renko box confirmation",

          "required.strategy_price_quarterly"  : "Please enter quarterly price",
          "required.strategy_price_halfyearly" : "Please enter halfyearly price",
          "required.strategy_price_annual"     : "Please enter annual price",

          "required.strategySelection" : "Please select Strategy",
          "required.strikeSelection"   : "Please select Strike",

      }

      const formData = {
          ...validationData,
          ...((segment) =>      { return segment === 'fut-opt' || segment === 'opt' ? optionStrategyData : {} })(localStates.segment.value),
          ...((chartType) =>    { return chartType === 'RENKO' ? renkoChartDetailsData : {} })(localStates.charttype.value),
          ...((strategyType) => { return strategyType === 'level_based_strategy'   ? levelbasedStrategyData   : {} })(strategyType),
          ...((strategyType) => { return strategyType === 'formula_based_strategy' ? formulaBasedStrategyData : {} })(strategyType),
      }

      const formValidations = { 
          ...validationRules,
          ...((segment) =>      { return segment === 'fut-opt' || segment === 'opt' ? optionStrategyValidations      : {} })(localStates.segment.value),
          ...((chartType) =>    { return chartType === 'RENKO' ? renkoChartDetailsValidations : {} })(localStates.charttype.value),
          ...((strategyType) => { return strategyType === 'level_based_strategy'   ? levelbasedStrategyValidations   : {} })(strategyType),
          ...((strategyType) => { return strategyType === 'formula_based_strategy' ? formulaBasedStrategyValidations : {} })(strategyType),
      }

      const validation = new validator(formData, formValidations,errorMessages);
      const userState  = { ...localStates }
      const validationResponse = { submitStatus : true, postData : formData  }

      if(validation.fails()) validationResponse.submitStatus = false;

        for (const name in userState) { 

          userState[name].error = validation.errors.get(name).length > 0 ? true : false;
          userState[name].errorMessage = validation.errors.first(name) || '' 
        }  
        
        updateStates({ ...userState }) // update errors and error messages //

      return validationResponse;
  }
  

  // @methods---------------------------------------------------------------------------------------------------

      const optionStrategyChangeHandler = ({ name , value }) => {

        const strategyOptions = ['option_buy','option_sell'];

        const strategyOptionsTwo = ['debit_spread','credit_spread','straddle_buy','straddle_sell','strangle_buy','strangle_sell']

        if(name === 'strategySelection') { 

          if(strategyOptions.includes(value)) updateStrikeSelection([{ value:'ATM', label:'ATM' }])

          else if(strategyOptionsTwo.includes(value)) updateStrikeSelection([
                  { value:'ATM_1' , label:'ATM 1' },
                  { value:'ATM_2' , label:'ATM 2' },
                  { value:'ATM_3' , label:'ATM 3' },
                  { value:'ATM_4' , label:'ATM 4' },
                  { value:'ATM_5' , label:'ATM 5' },
            ])
        }

        return updateStates({ ...localStates,[name]:{  value : value , error: false, errorMessage: '' }}) 
      }

      const changeHandler = ({ name , value }) => { 

          if(name === 'bucket' && value.length > 2) return true;

          else if(name === 'segment') {
            
              loadSymbols(value);

              return updateStates({ ...localStates,
                  segment : {  value : value , error: false, errorMessage: '' },
                  bucket  : {  value : []    , error: false, errorMessage: '' },
                  strategySelection  : {  value : '', error: false, errorMessage: '' },
                  strikeSelection    : {  value : '', error: false, errorMessage: '' },
              })  
          }

          else return updateStates({ ...localStates,[name]:{  value : value , error: false, errorMessage: '' }})  
      }

      const submitHandler = () => { 
        
          const { submitStatus , postData  } = formValidation();

          if(submitStatus === false) return false;

          if(strategyType === 'formula_based_strategy' && getStrategyPayload.status === false) return false;

          /* store strategy data ------------------------------------------*/

              postData.strategy = getStrategyPayload.payload
              postData.option_strategy = {
                name   : postData.strategySelection,
                strike : postData.strikeSelection
              }

          // ----------------------------------------------------------------

          props.stepValidator('stepTwo','stepTwoValidationComplete',postData); // send data to main function

          toggleValidationButton(false) // hide validation button of step one
      }

      const strategyPayload = (strategy,status,payload) => { return updateStrategyPayload({ status, payload }) } // this method is use to take data 

  //-------------------------------------------------------------------------------------------------------------

  return (

    <LoadingOverlay active={loader} spinner text="Loading content...">
    <div className={toggleClass}><div className="step-two">
        <DataSelection
            segment={segment}
            chartType={chartType}
            timeFrame={timeFrame}
            stockBucket={symbols}
            state={localStates} 
            changeHandler={changeHandler}
        ></DataSelection>

        { (localStates.segment.value === 'fut-opt' || localStates.segment.value === 'opt') && 

            <OptionStrategy 
                strategySelection={strategySelection}
                strikeSelection={strikeSelection}
                state={localStates} 
                changeHandler={optionStrategyChangeHandler}
            ></OptionStrategy>
        }

        { localStates.charttype.value === 'RENKO' &&
              <RenkoChartDetails 
                  renkoBoxSizeType={renkoBoxSizeType}
                  state={localStates} 
                  changeHandler={changeHandler}
              ></RenkoChartDetails>
        }

        <StrategyParameters 
            strategyType={strategyType}
            changeStrategyType={changeStrategyType}
        ></StrategyParameters>

        {/* { mount === true & strategyType === 'level_based_strategy'   &&  <LevelBasedStrategy   payload={strategyPayload} handle={submitButton} updateStrategy={props.updateStrategy} indicators={props.indicators} comparator={comparator}></LevelBasedStrategy>   } */}
        {/* { mount === true & strategyType === 'formula_based_strategy' &&  <FormulaBasedStrategy payload={strategyPayload} handle={submitButton} updateStrategy={props.updateStrategy} indicators={props.indicators} comparator={comparator}></FormulaBasedStrategy> }  */}

        { mount === true &&  <FormulaBasedStrategy payload={strategyPayload} handle={submitButton} updateStrategy={props.updateStrategy} indicators={props.indicators} formulas={props.formulas} comparator={comparator}></FormulaBasedStrategy> } 


        <div className="button-group mb-3">
            <button id="previous" className="button" type="button" onClick={(e) => props.previous(e)} >Back</button>
            <button ref={submitButton} id="next" className="button" type="button" onClick={submitHandler}>Next</button>
        </div>

    </div></div>
    </LoadingOverlay>
  );
};

export default StepTwo;
