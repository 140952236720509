import React from "react"

// @import-components ----------------------------------------------------------------------------------

    import { FormGroup, Col , Label } from 'reactstrap';

// -----------------------------------------------------------------------------------------------------

const SpanTag = ({ md, label, text }) => { return (
      <Col md={md}>
        <FormGroup>
            <Label>{label}</Label>
            <div className="custome-input">
            <span>{text}</span>
            </div>
        </FormGroup>
      </Col>
  )}

  export default SpanTag