
import React from "react";
import 'assets/popover.css'
 
// @import-page-components-----------------------------------------------------------

    import { Button, Popover, PopoverBody, PopoverHeader } from "reactstrap";
    // import styled from "styled-components";


    // const Wrapper = styled.div``

     
    // position: fixed !important;
    // top: 0 !important;
    // right: 0 !important;
    // bottom: 0 !important;
    // left: 0 !important;
    // z-index: 1040 !important;
    // background-color: transparent;
    // opacity: 0.8!important;
   


// ----------------------------------------------------------------------------------

class SecurePage extends React.Component {
    constructor(){ 
        super(); 
        
        this.toggle = this.toggle.bind(this);
        this.state = { showPopover : false, popoverTarget: 'null-pointer' };

        this.state.list = [
            {
                color: "red",
                value: "red-pointer"
            },
            {
                color: "green",
                value: "green-pointer"
            },
            {
                color: "blue",
                value: "blue-pointer"
            },
            {
                color: "cyan",
                value: "cyan-pointer"
            },
            {
                color: "magenta",
                value: "magenta-pointer"
            },
            {
                color: "yellow",
                value: "yellow-pointer"
            },
            {
                color: "black",
                value: "black-pointer"
            }
        ]

        this.state.popoverWrapperStyle = {
            position : 'fixed !important',
            top      : '0 !important',
            right    : '0 !important',   
            bottom   : '0 !important',
            left     : '0 !important',
            zIndex   : '1040 !important',
            backgroundColor: 'transparent',
            opacity: '0.8!important'
        }
    }

    toggle(target) { this.setState({ showPopover: !this.state.showPopover , popoverTarget : target }); }


    render(){ return (
        <React.Fragment>
             <div className="m-5">

            {
                this.state.list.map((data) => { return ( 
                    <React.Fragment>
                        <h4 style={{ color : data.color }} >{data.color}</h4>
                        <p>{ data.value }</p>
                        <Button id={ data.value } onClick={() => this.toggle(data.value)}>Launch Popover</Button>
                    </React.Fragment>
                 )})
            }
            

            <div style={{ backgroundColor:'bisque' , width:'350px', marginLeft:'250px' }}>
                <Button id={this.state.popoverTarget}  onClick={() => this.toggle('null-pointer')}>default popover</Button>
            </div>

            <div onClick={() => this.toggle(this.state.popoverTarget)}  className={ this.state.showPopover ? 'active-popover-wrapper' : 'inactive-popover-wrapper' }>
                <Popover  placement="left" isOpen={this.state.showPopover} target={this.state.popoverTarget} >
                    <PopoverHeader>Popover Title</PopoverHeader>
                    <PopoverBody>Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.</PopoverBody>
                </Popover>
            </div>

        </div>
         </React.Fragment>
    )}
}

export default SecurePage




 