import React from 'react';

// @import-components ---------------------------------------------------------

    import TextField          from 'components/BuildStrategies/strategyComponents/TextField';
    import DropDownField      from 'components/BuildStrategies/strategyComponents/DropDownField';
    import FormDetails        from 'components/BuildStrategies/strategyComponents/FormDetails';

// ----------------------------------------------------------------------------

export default function ContactUsForm({ handleSubmit, formData,  handleChange, }) {

  const data = [
      { value: 'Subscription', label:'Subscription Related Query' },
      { value: 'Affiliate Programm',     label : 'Affiliate Programm Related Query' },
      { value: 'Need Technical Support', label : 'Need a Technical Support' },
      { value: 'Custom Algo',            label : 'Assistance Required for Custom Algorithms' },
      { value: 'General Query',          label : 'General Query' },
  ]

  return (
    <div className="col-md-12"><div className="contact-card"><div className="card">
          <form onSubmit={handleSubmit} autoComplete="off">
              <FormDetails>
                  <DropDownField method={handleChange} value={formData.query.value} error={formData.query.error}     errorMessage={formData.query.errorMessage}     name="query"     md={6} label="Query Type" data={data}></DropDownField>
                  <TextField method={handleChange} value={formData.full_name.value} error={formData.full_name.error} errorMessage={formData.full_name.errorMessage} name="full_name" md={6} label="Your Name"></TextField>
                  <TextField method={handleChange} value={formData.email.value}     error={formData.email.error}   errorMessage={formData.email.errorMessage}    name="email"    md={6} label="Your Email Address"></TextField>
                  <TextField method={handleChange} value={formData.subject.value}   error={formData.subject.error} errorMessage={formData.subject.errorMessage}  name="subject"  md={6} label="Subject"></TextField>
                  <TextField method={handleChange} value={formData.message.value}   error={formData.message.error} errorMessage={formData.message.errorMessage}  name="message"  md={6} label="Message" type="textarea"></TextField>
            </FormDetails>

            <div className="robo"><div className="submit-btn ">
                <button className="btn btn-group send" type="submit">
                  Send
                </button>
            </div></div>
          </form>
    </div></div></div>
  )}
