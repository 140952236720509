import React, { createContext , Component } from 'react';

const Qodrr = createContext();

export class QodrrProvider extends Component {
    state = {
        userName   : 'Soniya',
        userConfig : {},
        userLogin  : false
    }

    updateConfig = (configuration) => { this.setState({ userConfig : configuration }) }

    updateUser   = (data) => { this.setState({ userName : data.userName , userLogin : data.login }) }

    render() { 

        const { userConfig,userName } = this.state;
        const { updateConfig , updateUser } = this;
        
        return(
            <Qodrr.Provider value={{ userConfig, userName , updateConfig,updateUser }}>
                { this.props.children }
            </Qodrr.Provider>
        )
    }
}

export default Qodrr