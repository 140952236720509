import React from "react";

// @import-components -------------------------------------------------------

    import CustomPopover from "components/Popover";
    import Icon from 'assets/img/icon/table-icon.svg';
    import styled from 'styled-components'
    import ReactPaginate from 'react-paginate';

    import * as links from '../data/helpCenter.json'

// --------------------------------------------------------------------------

// @static-components -------------------------------------------------------

    const Wrapper = styled.div`
        width:96%;
        margin-top:20px;
        padding: 1em;
        border: none;
        margin-left:2%;
        border-radius: 10px;
        box-shadow: 0px 0px 40px rgb(0 102 153 / 19%);
    `

    const ListContainer = styled.div``
    const ListItem = styled.div``

    const ListLink = styled.a`
        color:black;font-weight:500;
        display: list-item;
        list-style-type: disc;
        list-style-position: inside;
        &:hover {
            color: #006699 !important;
         }
    `


    const ListGroup = ({ data }) => {
        return (
            <React.Fragment>
                <ListContainer className="row p-2">
                    { 
                        data.map((link,idx) => (
                            <ListItem key={idx} className="col-md-6 p-2">
                                <ListLink target="_blank" href={link.href}>{link.text}</ListLink>
                            </ListItem>
                        )) 
                    }
                </ListContainer>
            </React.Fragment>
        )} 

// --------------------------------------------------------------------------


export default class HelpCenter extends React.Component {

    constructor() { 
        super();

        this.state = {}

        this.state.data = links.default
        this.state.currentItems = []
        this.state.pageCount    = 0
        this.state.itemOffset   = 0
        this.state.linksPerPage = 14

        this.handlePageClick = this.handlePageClick.bind();
    }

    componentDidMount(){

        const endOffset = this.state.itemOffset + this.state.linksPerPage;

        this.setState({
            pageCount    : Math.ceil(this.state.data.length / this.state.linksPerPage),
            currentItems : this.state.data.slice(this.state.itemOffset, endOffset) 
        })
    }

    handlePageClick = (event) => {

        const newOffset = (event.selected * 4) % this.state.data.length;
        const endOffset = this.state.itemOffset + this.state.linksPerPage;

        this.setState({
            itemOffset   : newOffset,
            pageCount    : Math.ceil(this.state.data.length / this.state.linksPerPage),
            currentItems : this.state.data.slice(this.state.itemOffset, endOffset) 
        })
    };
  
    render(){ return(
        <React.Fragment>
            <div className="container-fluid"><div className="configuration">
                <div className="row">
                    <div className="col-md-6"><div className="configuration-head">
                        <h4>
                            <img className="table-icon" src={Icon} alt="..." />Quick Resources 
                            <CustomPopover message="Help Section for Frequently Asked Questions" />
                        </h4>
                    </div></div>
                </div>

                <Wrapper className="card">
                    <ListGroup data={this.state.currentItems}></ListGroup>

                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="next"
                        onPageChange={this.handlePageClick}
                        pageRangeDisplayed={4}
                        pageCount={this.state.pageCount}
                        previousLabel="previous"
                        renderOnZeroPageCount={null}
                        className="help-center-pagination"
                    ></ReactPaginate>
                </Wrapper>

            </div></div>
        </React.Fragment>
    )}
}