import React from 'react';

const Header = props => {
  return (
    <React.Fragment>
      <ul className="progress-bar">
        <div>
          <li
            className={
              props.stepOneValidationComplete
                ? 'progress-bar__dot full'
                : 'progress-bar__dot'
            }
          ></li>
          <p
            className={
              props.stepOneValidationComplete
                ? 'top-line top-line-active'
                : 'top-line'
            }
          >
            Basic Details
          </p>
        </div>
        <li className="progress-bar__connector"></li>
        <div>
          <li
            className={
              props.stepTwoValidationComplete
                ? 'progress-bar__dot full'
                : 'progress-bar__dot'
            }
          ></li>
          <p
            className={
              props.stepTwoValidationComplete
                ? 'top-line top-line-active'
                : 'top-line'
            }
          >
            Data Selection & <br /> Parameter
          </p>
        </div>
        <li className="progress-bar__connector">
          <br />
        </li>
        <div>
          <li
            className={
              props.stepThreeValidationComplete
                ? 'progress-bar__dot full'
                : 'progress-bar__dot'
            }
          ></li>
          <p
            className={
              props.stepThreeValidationComplete
                ? 'top-line top-line-active'
                : 'top-line'
            }
          >
            Pricing
          </p>
        </div>
        <li className="progress-bar__connector"></li>
        <div>
          <li
            className={
              props.stepFourValidationComplete
                ? 'progress-bar__dot full'
                : 'progress-bar__dot'
            }
          ></li>
          <p
            className={
              props.stepFourValidationComplete
                ? 'top-line top-line-active'
                : 'top-line'
            }
          >
            Execution & <br /> Configuration
          </p>
        </div>
      </ul>
    </React.Fragment>
  );
};

export default Header;
