import Cycle from 'assets/img/icon/cycle.svg';
import React from 'react';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import LoadingOverlay from 'react-loading-overlay';
import { tradeService } from 'services/trade_service';
import store from 'store';
import Ticon from '../assets/img/icon/table-icon.svg';
import Popover from '../components/Popover';

class COIScreener extends React.Component {
  constructor() {
    super();
    this.state = {
      loader : false,
      refreshTime : new Date(),
      auth: undefined,
      data: [],
      export:false,
      columns: [
        {
          name: 'SR.',
          selector: 'serial',
          sortable: true,
        },
        {
          name: 'Symbol',
          selector: 'symbol',
          sortable: true,
        },
        {
          name: 'LTP',
          selector: 'close',
          sortable: true,
        },
        {
          name: 'Change %',
          selector: 'change_pr',
          sortable: true,
        },
        {
          name: 'OI % Change',
          selector: 'oi_change_pr',
          sortable: true,
        },
        {
          name: 'Volume % Change',
          selector: 'vol_change_pr',
          sortable: true,
        },
        {
          name: 'Build Up',
          selector: 'build_up',
          cell: d => this.buildformatter(d.build_up),
          sortable: true,
        },
      ],
    };
    this.userData = store.get('user_data');
    this.getCOIData = this.getCOIData.bind(this)
  }

  buildformatter(cell, row) {
    if (cell === 'SHORT') {
      return <p style={{ color: 'red' }}>Short</p>;
    }
    if (cell === 'LONG') {
      return <p style={{ color: 'green' }}>Long</p>;
    }
    if (cell === 'SHORT_UNWIND') {
      return <p style={{ color: 'orange' }}>Short Unwind</p>;
    }
    if (cell === 'LONG_UNWIND') {
      return <p style={{ color: '#006699' }}>Long Unwind</p>;
    }
  }

  getCOIData(){

    this.setState({ loader : true }) /* show loader */

    tradeService.getCOI().then(result => {

      if (result.statusCode === '200') { 
          this.setState({ 
              loader : false, refreshTime : result.date, 
              data: result.message.map((data,index) => { data.serial = index + 1; return data }) 
          });   
      }

    }).catch(function (error) { this.setState({ loader:false }); console.log('error occured while calling API'); });

  }

  componentDidMount() { this.getCOIData(); }

  dataTableCell(data) { return this.setState({ data }); }

  render() { return (

    <LoadingOverlay active={this.state.loader} spinner text="Loading content...">
      <div className="container-fluid"><div className="data-table">
          <div className="table-head">
              <div className="row">
                <div className="col-md-6"><div className="head">
                    <h4>
                      <img className="table-icon" src={Ticon} alt="..." />COI Screener{' '}
                      <Popover message="Moving Average Convergence status on Nifty-500 stocks." />
                    </h4>
                </div></div>

                <div className="col-md-6"><div className="cycle-btn">
                    <button className="btn btn-group" onClick={this.getCOIData}>
                        <img src={Cycle} alt="..." />
                        <span> Last refereshed at { String(this.state.refreshTime) }</span>
                    </button>
                </div></div>
              </div>

              <div className="log-content">
                  <p> Cumulative open interest analysis of Future segment stocks to identiyfy expirywide position in stock.</p>
              </div>
          </div>
         
          <div className="main">
              <DataTableExtensions {...this.state}>
                  <DataTable
                      columns={this.state.columns}
                      data={this.state.data}
                      noHeader
                      defaultSortField="id"
                      paginationRowsPerPageOptions={[10, 20, 50, 100]}
                      defaultSortAsc={false}
                      pagination
                      highlightOnHover
                  ></DataTable>
              </DataTableExtensions>
          </div>
      </div></div>
    </LoadingOverlay>
    );
  }
}

export default COIScreener;
