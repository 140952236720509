 
import React from 'react'

// @import-components ------------------------------------------------
     
   import StepHeading from '../StepHeading'
   import FormDetails from '../FormDetails';
   import TextField        from '../TextField';
   import RadioButtonField from '../RadioButtonField';

// -------------------------------------------------------------------

const StopLossPrice = props => {
  return (
    <React.Fragment>
        <StepHeading text="Stop Loss Price" id="step-three-stop-loss-price" content="Define stop loss price or target value in point and percentage.Eg: for 30 points, mention 30 and for percentange mention 0.5 which is 0.5%"></StepHeading>

        <FormDetails>
            <RadioButtonField md={6} method={props.changeHandler}
                label="Type of Stop Loss value" name="sl_type"
                error={props.state.sl_type.error}  value={props.state.sl_type.value}
                errorMessage={props.state.sl_type.errorMessage}
                textOne="Point" textTwo="Percentage"
                valueOne="point" valueTwo="percentage"
            ></RadioButtonField>
            <TextField md={6} label="Stop Loss value" name="sl_value" method={props.changeHandler}
                error={props.state.sl_value.error}  value={props.state.sl_value.value}
                errorMessage={props.state.sl_value.errorMessage}
            ></TextField>
      
            <RadioButtonField md={6} label="Type of Target value" name="target_type" method={props.changeHandler}
                error={props.state.target_type.error} value={props.state.target_type.value}
                errorMessage={props.state.target_type.errorMessage}
                textOne="Point" textTwo="Percentage"
                valueOne="point" valueTwo="percentage"
            ></RadioButtonField>
            <TextField md={6} label="Target value" name="target_value" method={props.changeHandler}
                error={props.state.target_value.error} value={props.state.target_value.value}
                errorMessage={props.state.target_value.errorMessage}
            ></TextField>
        </FormDetails>
        
    </React.Fragment>
  )
}

export default StopLossPrice