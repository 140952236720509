import Cycle from 'assets/img/icon/cycle.svg';
import LoadingOverlay from 'react-loading-overlay';
import Ticon from 'assets/img/icon/table-icon.svg';
import React from 'react';
import Popover from 'components/Popover';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { tradeService } from 'services/trade_service';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';

class ManageUsers extends React.Component {
    constructor() {
        super();

        this.response = {}
        this.state    = {}
    
        this.confirmationModal = false
        this.confirmationData  = {}
        this.state.loading     = false
        this.state.data    = []
        this.state.columns = []
        this.state.filterPlaceholder = 'Filter Data';
        this.state.exportHeaders = true;
        this.state.export   =  false;
        this.state.fileName = "Users List";

        this.state.columns[0] = {
            name: 'SR.',
            sortable: true,
            selector: data => data.index,
        }

        this.state.columns[1] = {
            name: 'Username',
            sortable: true,
            selector: data => data.username,
        }

        this.state.columns[2] = {
            name: 'Email',
            sortable: true,
            selector: data => data.email,
        }

        this.state.columns[3] = {
            name: 'Role',
            sortable: true,
            selector : data => {

                if(data.auth_type === 'S6E1') return 'Super Admin';

                if(data.auth_type === 'BSN6') return 'Admin';

                return 'User';
            }
        }

        this.state.columns[4] = {
            name: 'Status',
            sortable: true,
            selector : data => data.status
        }

        this.state.columns[5] = {
            name: 'Action',
            sortable: true,
            selector : data => { return (
               <React.Fragment>
                    <button className="btn btn-group" onClick={() => this.toggleModal(true,data)}>
                    { data.status === 'ACTIVE'   && <i className="fa fa-toggle-on"  aria-hidden="true" style={{ color: '#85eb41' }} ></i> }   
                    { data.status === 'INACTIVE' && <i className="fa fa-toggle-off" aria-hidden="true" style={{ color: '#000' }} ></i> }    
                    </button>
               </React.Fragment>
            )}
        }

    this.getUsersList     = this.getUsersList.bind(this);
    this.toggleUserStatus = this.toggleUserStatus.bind(this);
  }

    componentDidMount() { this.getUsersList(); }

    async toggleUserStatus(){ 

        this.setState({ confirmationModal : false, loading: true });
        
        try {

            const payload = { 
                'username' : this.state.confirmationData.username, 
                'status'   : this.state.confirmationData.status 
            }

            payload.status = payload.status == 'ACTIVE' ? 'INACTIVE' : 'ACTIVE' 

            const serverResponse = await tradeService.updateUserStatus(payload)

            if (serverResponse.statusCode !== '200') { throw Error(serverResponse.message); }

            this.response.message = "Status changed successfully :)"

            this.getUsersList() // refresh user list
            
        } catch (error) { this.response.message = 'Something Went Wrong!' }

        this.setState({ loading: false });
        this.notificationSystem.addNotification({
            title: <></>,
            message: this.response.message,
            level: 'info',
        });
    }

    async getUsersList() {

        try {

            this.setState({ loading: true });

            const serverResponse = await tradeService.getUsers()

            if (serverResponse.statusCode === '200') { 

                serverResponse.data = serverResponse.data.map((data,index) => { data.index = index + 1; return data; })
                
                this.setState({ data : serverResponse.data }) 
            } 

        } catch (error) { console.log(error); console.log(error); }

        this.setState({ loading: false });
    }

    dataTableCell(data) { console.clear(); console.log(data); return this.setState({ data }); }
   
    toggleModal(status,data = {}) { this.setState({ confirmationModal : status, confirmationData : data }); }

    render() { return (
        <LoadingOverlay active={this.state.loading} spinner text="Loading content...">
        <div className="container-fluid"><div className="data-table">

            <div className="table-head"><div className="row">
                <div className="col-md-6 repo-head"><div className="head">
                    <h4><img className="table-icon" src={Ticon} alt="..." />Manage Users<Popover message="..." /></h4>
                </div></div>

                <div className="col-md-6 repo-head1"><div className="cycle-btn">
                    <button className="btn btn-group" onClick={this.getUsersList}>
                      <img src={Cycle} alt="..." />
                    </button>
                </div></div>
            </div></div>

            <NotificationSystem dismissible={false} style={NOTIFICATION_SYSTEM_STYLE}
                ref={notificationSystem =>  (this.notificationSystem = notificationSystem)}
            ></NotificationSystem>

            <Modal isOpen={this.state.confirmationModal} toggle={() => { this.toggleModal(false) }}>
                <ModalHeader toggle={() => { this.toggleModal(false) }}>Update User Status</ModalHeader>
                <ModalBody>
                    <div className="lock-head">
                        <h5>Are you sure you want to continue ?</h5>
                    </div>
                    <div className="lock-btn"><div className="row">
                            <div className="col-md-6 no-btn">
                                <button className="btn btn-group" onClick={() => { this.toggleModal(false) }}>No</button>
                            </div>
                            <div className="col-md-6 yes-btn">
                                <button className="btn btn-group" onClick={() => { this.toggleUserStatus() }}>Yes</button>
                            </div>
                    </div></div> 
                </ModalBody>
            </Modal>

            <div className=""><div className="main">
                <DataTableExtensions {...this.state}>
                  <DataTable
                    columns={this.state.columns}
                    data={this.state.data}
                    noHeader
                    defaultSortField="id"
                    paginationRowsPerPageOptions={[10, 20, 50, 100]}
                    defaultSortAsc={false}
                    pagination
                    highlightOnHover
                  />
                </DataTableExtensions>
            </div></div>

        </div></div>
        </LoadingOverlay>
    )}
}

export default ManageUsers;
