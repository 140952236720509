import React from "react";

import { Card, Button, CardTitle, Col, CardBody } from 'reactstrap';

const PriceCard = ({ data, idx, method }) => { return (
        <Col sm="3">
            <Card className="membership-plan-card">
                <CardTitle className="membership-plan-card-title">
                    <h4 className='text-center font-weight-bold' style={{ color :'#006699' }}><i className='fa fa-inr'></i> {data.durationPrices[idx]}/{data.planDurations[idx]}</h4>
                    <h6 className='text-center'>Additional 18% GST</h6>

                    <h4 className='mt-3 text-center font-weight-bold'>{data.name}</h4>
                    <h6 className='text-center '>{data.mainFeature}</h6>
                </CardTitle>
                <CardBody className="membership-plan-card-content">
                    <h5 className='text-center mb-4'>Features/Plans</h5>

                    {
                        data.features.map((feature, index) => (
                            <div className="row mt-3">
                                <div className="col-md-2"> <i className="fa fa-check membership-plan-card-text-icon" aria-hidden="true"></i> </div>
                                <div className="col-md-10">{ feature }</div>
                            </div>
                        ))
                    }
                </CardBody>
                <Button className="membership-plans-subscribe-button" onClick={() => method(data.userSelectePlans[idx])}>Subscribe</Button>
            </Card>
        </Col>
    )}

export default PriceCard