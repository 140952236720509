import React from 'react';
import store from 'store';
import Moment from 'react-moment';

// @import-content && hooks -----------------------------------------------------------

    import Qodrr from 'hooks/QodrrContext';

    import { MdClearAll } from 'react-icons/md';
    import { Button, Nav, Navbar } from 'reactstrap';
    import { Link } from "react-router-dom";
    
    import bn from 'utils/bemnames';


    const bem = bn.create('header');

// -------------------------------------------------------------------------------------



class Header extends React.Component {
  state = {
    isOpenNotificationPopover: false,
    isNotificationConfirmed: false,
    isOpenUserCardPopover: false,
    date: new Date().toLocaleString(),
    time: '',hours : 0, minutes : 0,
    userData: store.get('user_data'),
    todayDate : ((date) => {

      const month = String(date.getUTCMonth() + 1);
      const day  = String(date.getUTCDate())

      return `${date.getUTCFullYear()}-${month.padStart(2, "0")}-${day.padStart(2,"0")}`

    })(new Date()),
  };

  componentDidMount() { this.intervalID = setInterval(() => this.tick(), 1000); }

  componentWillUnmount() { clearInterval(this.intervalID); }

  tick() { 
  
    const date = new Date();
    
    this.setState({ time: `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}` }); 
    this.setState({ hours : date.getHours(),minutes: date.getMinutes() })
  }

  aboutToExpire(date) {

      const days = 10;
      const expireDate = new Date(date.getTime() - (days * 24 * 60 * 60 * 1000));
      const month = String(expireDate.getUTCMonth() + 1);
      const day   = String(expireDate.getUTCDate())

      return `${expireDate.getUTCFullYear()}-${month.padStart(2, "0")}-${day.padStart(2,"0")}`
  }

  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });

    if (!this.state.isNotificationConfirmed) {
      this.setState({ isNotificationConfirmed: false });
    }
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  render() {

    const MembershipExpire = ({ data }) => {

      if (Object.keys(data).length === 0) return <React.Fragment></React.Fragment>
      
      if (this.state.todayDate > data.expiry_date) return (
          <div className='w-100 text-white p-2' style={{ background :'#12788d',fontWeight: 'bold', textAlign:'center' }}>
            Your membership plan is expired. <Link className='text-white font-weight-bold' to='/membership-plans'>Click to renew your membership.</Link> feel free to contact us in case of any concern 
          </div>
      )

      if (this.state.todayDate >= this.aboutToExpire(new Date(data.expiry_date))) {

              const expiryDate = new Date(data.expiry_date); 
              const todayDate  = new Date();
              const difference = Math.abs(expiryDate - todayDate);
              const days = Math.round(difference / (1000 * 3600 * 24))
        
        return (
            <div className='w-100 text-white p-2' style={{ background :'#12788d',fontWeight: 'bold', textAlign:'center' }}>
                Your current membership plan is about to expire in days {days} days.
                <Link className='text-white font-weight-bold' to='/membership-plans'>Click to extend your membership.</Link>
                feel free to contact us in case of any concern 
            </div>
        )
      }

      return <React.Fragment></React.Fragment>
    }

    const MaintenanceNotification = ({ time , hours , minutes }) => {

      const getMinutes = (() => { return (hours * 60) + minutes; })()

      /** start time 11:30 pm and end time is 07:00 am

          1380 + 45 = 1425 = 23:45 || 11:45 pm 
          1440 + 59 = 1499 = 24:59 || 12:59 am

          60   + 0  = 60 = 01:00 || 01:00 am
          420  + 0  = 420  = 07:00 || 07:00 am
      */

      if((getMinutes >= 1425 && getMinutes <= 1499) || (getMinutes >= 1 && getMinutes <= 420))  {
        return (
          <div className='w-100 p-2' style={{ background :'#fff3cd',color:'#856404',fontWeight: 'bold', textAlign:'center' }}>
                Application is under scheduled maintenance from 11.45 PM to 7 AM IST and few of the features might not work.
          </div>
      )}

      return <React.Fragment></React.Fragment>
    }

    const UserProfile = ({ data }) => {
      return (
        <Nav navbar>
          <div className="top-head">
            <h2>Hello {data.username}</h2>
            <span>Welcome to Qodrr!</span>
          </div>
        </Nav>
    )};

    const LoginProfile = () => {
      return (
        <Nav navbar>
          <div className="top-head"><h2>Welcome to Qodrr!</h2></div>
        </Nav>
    )};

    return (
      <React.Fragment>
          <Navbar light expand className="main-nav">
              <Nav navbar className="mr-2">
                  <Button outline onClick={this.handleSidebarControlButton}>
                      <MdClearAll size={25} />
                  </Button>
              </Nav>

            { this.state.userData !== undefined && <UserProfile data={this.state.userData}></UserProfile>}
            { this.state.userData === undefined && <LoginProfile></LoginProfile> }

            <Nav navbar className={bem.e('nav-right')}>
                <div className="date-top">
                      <i className="fa fa-clock-o"></i>
                      <span> Current Time :{' '}<Moment format="MMMM Do YYYY, h:mm:ss a" interval={1000}></Moment></span>
                </div>
            </Nav>
        </Navbar>
        <MembershipExpire data={this.context.userConfig}></MembershipExpire>
        <MaintenanceNotification hours={this.state.hours} minutes={this.state.minutes} time={this.state.time}></MaintenanceNotification>
      </React.Fragment>
    );
  }
}

Header.contextType = Qodrr;

export default Header;
