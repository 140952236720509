import React from "react"
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import StepHeading from "./StepHeading";
import TextField from "../TextField";

const PublicStrategy = (props) => {
    return (
        <React.Fragment>
            <StepHeading text="Strategy details" content="Mentioned Strategy details like pricing and description. Note: It'll be used to display to end user." id="public-strategy-details"></StepHeading>

            <div className="row form-detail">
                <div className="col-md-6"><div className="row">
                    <TextField md={12} method={props.method} value={props.localStates.strategy_subject.value}           errorMessage={props.localStates.strategy_subject.errorMessage}          error={props.localStates.strategy_subject.error} name="strategy_subject" label="Subject"></TextField>
                    <TextField md={6}  method={props.method} value={props.localStates.strategy_price_monthly.value}     errorMessage={props.localStates.strategy_price_monthly.errorMessage}    error={props.localStates.strategy_price_monthly.error} name="strategy_price_monthly" label="Monthly price"></TextField>
                    <TextField md={6}  method={props.method} value={props.localStates.strategy_price_quarterly.value}   errorMessage={props.localStates.strategy_price_quarterly.errorMessage}  error={props.localStates.strategy_price_quarterly.error} name="strategy_price_quarterly" label="Quarterly price"></TextField>
                    <TextField md={6}  method={props.method} value={props.localStates.strategy_price_halfyearly.value}  errorMessage={props.localStates.strategy_price_halfyearly.errorMessage} error={props.localStates.strategy_price_halfyearly.error} name="strategy_price_halfyearly" label="Halfyearly price"></TextField>
                    <TextField md={6}  method={props.method} value={props.localStates.strategy_price_annual.value}      errorMessage={props.localStates.strategy_price_annual.errorMessage}     error={props.localStates.strategy_price_annual.error} name="strategy_price_annual" label="Annual price"></TextField>
                </div></div>

                <div className="col-md-6">
                    <FormGroup>
                        <Label>Description</Label>
                        <Input onChange={(e) => props.method(e.target)} type="textarea" value={props.localStates.strategy_desc.value}  invalid={props.localStates.strategy_desc.error ? true : false} name="strategy_desc" placeholder="example: Qodrr" rows="5" />
                        <FormFeedback>{props.localStates.strategy_desc.errorMessage}</FormFeedback>
                    </FormGroup>
                    <p>
                        <b style={{ color:'#73818f' }}>Note:</b>
                        <span style={{ color:'#73818f' }}>Platform fee of minimum ₹ 500 or 20% of actual price (Whichever is higher) will be charged from strategy price. </span>
                    </p>
                </div>
            </div>
        </React.Fragment>
    )
}

export default PublicStrategy