import Cycle from 'assets/img/icon/cycle.svg';
import LoadingOverlay from 'react-loading-overlay';
import Ticon from 'assets/img/icon/table-icon.svg';
import React from 'react';
import Popover from 'components/Popover';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { tradeService } from 'services/trade_service';
import Moment from 'react-moment';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';



class PublicStrategyPayment extends React.Component {
    constructor() {
        super();
    
        this.state = {}

        this.state.loading = false
        this.state.data    = []
        this.state.columns = []
        this.state.filterPlaceholder = 'Filter Data';
        this.state.exportHeaders = true;
        this.state.export   =  true;
        this.state.fileName = "public-strategy-payment";

        this.state.modal = false
        this.state.modalData = {}

        this.state.columns[0] = {
            name: 'SR.',
            selector: 'index',
            sortable: true,
        }

        this.state.columns[1] = {
            name: 'Strategy Name',
            selector: 'strategy_name',
            sortable: true,
        }

        this.state.columns[2] = {
            name: 'Username',
            selector: 'strategy_subscriber_username',
            sortable: true,
        }

        this.state.columns[3] = {
            name: 'Amount Paid',
            selector: 'amount_paid',
            sortable: true,
        }


        this.state.columns[4] = {
            name: 'Payment ID',
            selector: 'payment_id',
            sortable: true,
        }
        this.state.columns[5] = {
            name: 'Paymen Date',
            sortable: true,
            cell : data => { 

            if (data.payment_date === null || data.payment_date === undefined) {
                return <React.Fragment>{ 'NA' }</React.Fragment>
            }
                
            return(
                <React.Fragment>
                    <Moment parse="YYYY-MM-DD HH:mm:ss">{data.payment_date}</Moment>
                </React.Fragment>
            )}
        }

        this.state.columns[6] = {
            name: 'Action',
            sortable: true,
            cell : data => { 

            return(
                <React.Fragment>
                    <button className="btn btn-group" onClick={() => { this.modalMethod(data) }}>
                        <i className="fa fa-eye" aria-hidden="true" style={{ color: '#0EA5E9' }}></i>
                    </button>
                </React.Fragment>
            )}
        }

    this.getpublicStrategyPayment = this.getpublicStrategyPayment.bind(this);
    this.modalMethod = this.modalMethod.bind(this)
  }

    componentDidMount() { this.getpublicStrategyPayment(); }

    async getpublicStrategyPayment() {

        try {

            this.setState({ loading: true });

            const serverResponse = await tradeService.publicStrategyPayment()

            if (serverResponse.statusCode === "200") { 

                serverResponse.data = serverResponse.data.map((data,index) => { data.index = index + 1; return data; })
                
                this.setState({ data : serverResponse.data }) 
            } 

        } catch (error) { console.log(error); console.log(error); }

        this.setState({ loading: false });
    }

    modalMethod(data){

        try {

            if(data.gst !== null){ data.gst = data.gst * 100 }

            this.setState({ modal: true, modalData : { ...data } })

        } catch (error) { console.clear(); console.log('error',error) }
    }

    dataTableCell(data) { console.clear(); console.log(data); return this.setState({ data }); }

    render() { return (
        <LoadingOverlay active={this.state.loading} spinner text="Loading content...">
        <div className="container-fluid"><div className="data-table">

            <div className="table-head"><div className="row">
                <div className="col-md-6 repo-head"><div className="head">
                    <h4><img className="table-icon" src={Ticon} alt="..." />Public Strategy Payment <Popover message="..." /></h4>
                </div></div>

                <div className="col-md-6 repo-head1"><div className="cycle-btn">
                    <button className="btn btn-group" onClick={this.getpublicStrategyPayment}>
                      <img src={Cycle} alt="..." />
                    </button>
                </div></div>
            </div></div>

            <div className=""><div className="main">
                <DataTableExtensions {...this.state}>
                  <DataTable
                    columns={this.state.columns}
                    data={this.state.data}
                    noHeader
                    defaultSortField="id"
                    paginationRowsPerPageOptions={[10, 20, 50, 100]}
                    defaultSortAsc={false}
                    pagination
                    highlightOnHover
                  />
                </DataTableExtensions>
            </div></div>

            <Modal isOpen={this.state.modal} toggle={() => this.setState({ modal: false })} className="modal-xl">
                <ModalHeader toggle={() => this.setState({ modal: false })}>Payment Details</ModalHeader>
                <ModalBody className="modal-xl">
                    <div className="trade-detail"><div className="row">

                        <div className="col-md-3">
                            <p><b>Strategy Name :</b><br></br><span>{ this.state.modalData.strategy_name }</span></p>
                        </div>
                        <div className="col-md-3">
                            <p><b>Strategy Code :</b><br></br><span>{ this.state.modalData.strategy_code }</span></p>
                        </div>
                        <div className="col-md-3">
                            <p><b>Subscriber Name :</b><br></br><span>{ this.state.modalData.strategy_subscriber_username }</span></p>
                        </div>
                        <div className="col-md-3">
                            <p><b>Author Earning :</b><br></br> <span>₹ { this.state.modalData.strategy_author_earning }</span></p>
                        </div>


                        <div className="col-md-3">
                            <p><b>Platform Earning :</b><br></br> <span>₹ { this.state.modalData.platform_earning }</span></p>
                        </div>
                        <div className="col-md-3">
                            <p><b>GST Amount :</b><br></br> <span>₹ { this.state.modalData.gst_amount }</span></p>
                        </div>
                        <div className="col-md-3">
                            <p><b>GST :</b><br></br> <span>{ this.state.modalData.gst }%</span></p>
                        </div>
                        <div className="col-md-3">
                            <p><b>Amount Paid :</b><br></br> <span> ₹ { this.state.modalData.amount_paid }</span></p>
                        </div>

                        <div className="col-md-3">
                            <p><b>Payment ID:</b><br></br> <span>{ this.state.modalData.payment_id }</span></p>
                        </div>
                        <div className="col-md-3">
                            <p><b>Payment Date :</b><br></br> 
                                <span>
                                    { (this.state.modalData.payment_date === null || this.state.modalData.payment_date === undefined) && <React.Fragment>{ 'NA' }</React.Fragment> }
                                </span>
                                <span>
                                    { (this.state.modalData.payment_date !== null || this.state.modalData.payment_date !== undefined) && <Moment parse="YYYY-MM-DD HH:mm:ss">{this.state.modalData.payment_date}</Moment> }
                                </span>
                            </p>
                        </div>

                    </div></div>
                </ModalBody>
            </Modal>

        </div></div>
        </LoadingOverlay>
    )}
}

export default PublicStrategyPayment;
