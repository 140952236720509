import buidStrategy from '../assets/img/icon/buildStrategy.svg'
// import manageStrategy from '../assets/img/icon/manageStrategy.svg'
import marketPlace from '../assets/img/icon/marketplace.svg'
const icons = {
    operationBuildStrategy : buidStrategy,
    // operationManageStrategy : manageStrategy,
    operationTradeLog : buidStrategy,
    operationMarketPlace : marketPlace

}
export default icons