
import React from "react"
import icon from 'assets/img/icon/detail.svg';
import { Popover,PopoverBody } from 'reactstrap';

const StepHeading = (props) => { 

    const [toolTip,toggleToolTip] = React.useState(false)

    return(
       <React.Fragment>
            <div className="step-head">
               <h4> { props.text } <img onMouseOver={() => toggleToolTip(true)} onMouseOut={() => toggleToolTip(false)} src={icon} alt="..." id={props.id === undefined ? 'no-data' : props.id } /></h4>
                <Popover placement="right" className="img-text" isOpen={toolTip} autohide={false} target={props.id === undefined ? 'no-data' : props.id } toggle={() => toggleToolTip(!toolTip)}>
                    <PopoverBody className="content_img img-text">{ props.content }</PopoverBody>
                </Popover>
            </div>
       </React.Fragment> 

    )
 }

export default StepHeading