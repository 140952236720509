import Cycle from 'assets/img/icon/cycle.svg';
import LoadingOverlay from 'react-loading-overlay';
import Ticon from 'assets/img/icon/table-icon.svg';
import React from 'react';
import Popover from 'components/Popover';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { tradeService } from 'services/trade_service';
import Moment from 'react-moment';


class ActiveSubscribers extends React.Component {
    constructor() {
        super();
    
        this.state = {}

        this.state.loading = false
        this.state.data    = []
        this.state.columns = []
        this.state.filterPlaceholder = 'Filter Data';
        this.state.exportHeaders = true;
        this.state.export   =  true;
        this.state.fileName = "Active Subscribers";

        this.state.columns[0] = {
            name: 'SR.',
            selector: 'index',
            sortable: true,
        }

        this.state.columns[1] = {
            name: 'Username',
            selector: 'username',
            sortable: true,
        }

        this.state.columns[2] = {
            name: 'Strategy Name',
            selector: 'strategy_name',
            sortable: true,
        }

        this.state.columns[3] = {
            name: 'Start Date',
            sortable: true,
            cell : data => { 

            if (data.start_date === null || data.start_date === undefined) {
                return <React.Fragment>{ 'NA' }</React.Fragment>
            }
                
            return(
                <React.Fragment>
                    <Moment parse="YYYY-MM-DD HH:mm:ss">{data.start_date}</Moment>
                </React.Fragment>
            )}
        }

        this.state.columns[4] = {
            name: 'End Date',
            sortable: true,
            cell : data => { 

            if (data.end_date === null || data.end_date === undefined) {
                return <React.Fragment>{ 'NA' }</React.Fragment>
            }
                
            return(
                <React.Fragment>
                    <Moment parse="YYYY-MM-DD HH:mm:ss">{data.end_date}</Moment>
                </React.Fragment>
            )}
        }

    this.getActiveSubscribers = this.getActiveSubscribers.bind(this);
  }

    componentDidMount() { this.getActiveSubscribers(); }

    async getActiveSubscribers() {

        try {

            this.setState({ loading: true });

            const serverResponse = await tradeService.activeSubscribers()

            if (serverResponse.statusCode === 200) { 

                serverResponse.data = serverResponse.data.map((data,index) => { data.index = index + 1; return data; })
                
                this.setState({ data : serverResponse.data }) 
            } 

        } catch (error) { console.log(error); console.log(error); }

        this.setState({ loading: false });
    }

    dataTableCell(data) { console.clear(); console.log(data); return this.setState({ data }); }

    render() { return (
        <LoadingOverlay active={this.state.loading} spinner text="Loading content...">
        <div className="container-fluid"><div className="data-table">

            <div className="table-head"><div className="row">
                <div className="col-md-6 repo-head"><div className="head">
                    <h4><img className="table-icon" src={Ticon} alt="..." />Active Subscribers<Popover message="..." /></h4>
                </div></div>

                <div className="col-md-6 repo-head1"><div className="cycle-btn">
                    <button className="btn btn-group" onClick={this.getActiveSubscribers}>
                      <img src={Cycle} alt="..." />
                    </button>
                </div></div>
            </div></div>

            <div className=""><div className="main">
                <DataTableExtensions {...this.state}>
                  <DataTable
                    columns={this.state.columns}
                    data={this.state.data}
                    noHeader
                    defaultSortField="id"
                    paginationRowsPerPageOptions={[10, 20, 50, 100]}
                    defaultSortAsc={false}
                    pagination
                    highlightOnHover
                  />
                </DataTableExtensions>
            </div></div>

        </div></div>
        </LoadingOverlay>
    )}
}

export default ActiveSubscribers;
