import React from 'react'

// @import-components  ----------------------------------------------------------------

    import { FormGroup, Label, Input ,Col } from 'reactstrap';

// ------------------------------------------------------------------------------------


const RadioButtonField = (props) => {

    const style = {
        width: '100%',
        marginTop: '0.25rem',
        fontSize: '0.9em',
        color: '#AB0003',
        fontWeight: 'bold'
    }

    const containerClass = props.error === false ? "checker col-md-12" : "checker col-md-12 is-invalid form-control"

  return (
    <React.Fragment>
        <Col md={props.md}>
            <FormGroup>
                <Label>{ props.label }</Label>
                <div className={containerClass}><div className="row">
             
                    <div className="col-md-6 mob-respo">
                        <Input checked={props.valueOne === props.value ? true : false} type="radio" onChange={(e) => props.method({ name: props.name, value :props.valueOne }) } name={props.name} value={props.valueOne} />{' '}
                        <span><b>{props.textOne}</b></span>
                    </div>
              
                    <div className="col-md-6 mob-respo align-right">
                        <Input checked={props.valueTwo === props.value ? true : false } type="radio" onChange={(e) => props.method({ name: props.name, value :props.valueTwo }) } name={props.name} value={props.valueTwo} />{' '}
                        <span><b>{props.textTwo}</b></span>
                    </div>
                </div></div>
                <p style={style}>{props.errorMessage}</p>
            </FormGroup>
        </Col>
    </React.Fragment>
  )
}

export default RadioButtonField