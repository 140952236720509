import React from 'react';
import store from 'store';
import * as validator   from 'validatorjs';

// @import-components && icons --------------------------------------------------------------------------------

    import TimeField          from './strategyComponents/stepOne/TimeField';
    import FormDetails        from './strategyComponents/FormDetails';
    import SelectStrategyType from './strategyComponents/stepOne/SelectStrategyType';

    import TextField          from './strategyComponents/TextField';
    import DropDownField      from './strategyComponents/DropDownField';

    import StepHeading        from './strategyComponents/stepOne/StepHeading';

    import PublicStrategy     from './strategyComponents/stepOne/PublicStrategy';
    import PrivateStrategy    from './strategyComponents/stepOne/PrivateStrategy';

    import SpanTag from './strategyComponents/SpanTag'

    import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';
    import NotificationSystem from 'react-notification-system';

// ------------------------------------------------------------------------------------------------------------


const StepOne = (props) => {

  const toggleClass = props.activeStep === 'stepOne' ? 'step step1' : 'step step1 hidden'

  const [strategyType,switchStrategyType] = React.useState('private_strategy');
  const [validationButton,toggleValidationButton] = React.useState(true);

  const notificationSystem = React.useRef()
  const userDetails = store.get('user_data')

  const brokers = ((brokers) => { 
    
    if(Object.keys(props.brokers).length === 0) return [] 

    return Object.keys(props.brokers).map((data,idx) => { return { label:brokers[data].label,value:brokers[data].key } });
  
  })(props.brokers)

  React.useEffect(() => { toggleValidationButton(true); return () => {} },[props.activeStep])
  React.useEffect(() => { 

    if(props.updateStrategy.status) updatestrategy(props.updateStrategy.payload);

  },[props.updateStrategy.status])

  // @validation && states && handlers -------------------------------------------------------------------------------------------------------------

      const permanentState = {
          strategy_name : { value: '', error: false, errorMessage: '' },
          broker        : { value: '', error: false, errorMessage: '' },
          start_time    : { value: '', error: false, errorMessage: '' },
          end_time      : { value: '', error: false, errorMessage: '' },
          strategy_type : { value: '', error: false, errorMessage: '' },

          start_time_hr : { value: '',   error: false, errorMessage: '' },
          start_time_mt : { value: '',   error: false, errorMessage: '' },
          start_time_fm : { value: 'AM', error: false, errorMessage: '' },

          end_time_hr : { value: '',   error: false, errorMessage: '' },
          end_time_mt : { value: '',   error: false, errorMessage: '' },
          end_time_fm : { value: 'AM', error: false, errorMessage: '' },
      }

      const privateStrategyState = {}

    const publicStrategyState  = {
            strategy_subject          : { value: '', error: false, errorMessage: '' },
            strategy_desc             : { value: '', error: false, errorMessage: '' },
            strategy_price_monthly    : { value: '', error: false, errorMessage: '' },
            strategy_price_quarterly  : { value: '', error: false, errorMessage: '' },
            strategy_price_halfyearly : { value: '', error: false, errorMessage: '' },
            strategy_price_annual     : { value: '', error: false, errorMessage: '' },
    }

    const initialState = { ...permanentState,...publicStrategyState,...privateStrategyState }

    const [localStates,updateStates] = React.useState(initialState)

    const updatestrategy = (data) => {

        const timeConverter = (time) => {

            const totalMinutes = time / 60
            const timeObject = {
                getHours   : parseInt(totalMinutes / 60),
                getMinutes : 0,
                getFormat  : 'AM'
            }

            timeObject.getMinutes = totalMinutes - (timeObject.getHours * 60);

            if(timeObject.getHours > 12) {
                timeObject.getHours  -= 12
                timeObject.getFormat  = 'PM'
            }

            return timeObject
        }

        updateStates((state) => {

            state.start_time_hr.value = data.start_time === undefined ? state.start_time_hr.value : timeConverter(data.start_time).getHours
            state.start_time_mt.value = data.start_time === undefined ? state.start_time_mt.value : timeConverter(data.start_time).getMinutes
            state.start_time_fm.value = data.start_time === undefined ? state.start_time_fm.value : timeConverter(data.start_time).getFormat

            state.end_time_hr.value = data.end_time === undefined ? state.end_time_hr.value : timeConverter(data.end_time).getHours
            state.end_time_mt.value = data.end_time === undefined ? state.end_time_mt.value : timeConverter(data.end_time).getMinutes
            state.end_time_fm.value = data.end_time === undefined ? state.end_time_fm.value : timeConverter(data.end_time).getFormat

            state.strategy_name.value = data.end_time === undefined ? state.strategy_name.value : data.strategy_name
            state.broker.value        = data.end_time === undefined ? state.broker.value        : data.broker

            switchStrategyType(data.strategy_type === undefined ? 'private_strategy' : data.strategy_type === 'PRIVATE' ? 'private_strategy' : 'public_strategy'); // switch strategy type 

            if(data.strategy_type === undefined || data.strategy_type === 'PRIVATE') return state;

            state.strategy_subject.value = data.strategy_subject === undefined ? state.strategy_subject.value : data.strategy_subject
            state.strategy_desc.value    = data.strategy_desc    === undefined ? state.strategy_desc.value    : data.strategy_desc

            if(data.strategy_price.length === 0) return void 0;

                state.strategy_price_monthly.value    = data.strategy_price === undefined ? state.strategy_price_monthly.value    : data.strategy_price[0].monthly
                state.strategy_price_quarterly.value  = data.strategy_price === undefined ? state.strategy_price_quarterly.value  : data.strategy_price[1].quarterly
                state.strategy_price_halfyearly.value = data.strategy_price === undefined ? state.strategy_price_halfyearly.value : data.strategy_price[2].halfyearly
                state.strategy_price_annual.value     = data.strategy_price === undefined ? state.strategy_price_annual.value     : data.strategy_price[3].annual

            return state;
        })

    }  /* The data will be updated if the strategy is on update mode */

    const formValidation = () => {

        // @custom-validation-rules -------------------------------------------------------------

            validator.register('stringValidation', function(value, requirement, attribute) { 

                const regex = value.toString().replace(/\W/g, '')

                return value === regex
                
            }, '');

            validator.register('price', function(value, requirement, attribute) { 
                const expression = '^[0-9]+$';
                const regex = new RegExp(expression);

                return value.toString().match(regex)
            }, 'Please describe valid price');


            validator.register('hoursValidation', function(value, requirement, attribute) { 

                return value <= 12 ? true : false 

            }, 'Hours should less or equal to 12');

            validator.register('minutesValidation', function(value, requirement, attribute) { 

                return value <= 60 ? true : false 

            }, 'Minutes should less or equal to 60');

        // -------------------------------------------------------------------------------------------------------------

            const publicStrategyData = {

                strategy_subject          : localStates.strategy_subject.value,
                strategy_desc             : localStates.strategy_desc.value,
                strategy_price_monthly    : localStates.strategy_price_monthly.value,
                strategy_price_quarterly  : localStates.strategy_price_quarterly.value,
                strategy_price_halfyearly : localStates.strategy_price_halfyearly.value,
                strategy_price_annual     : localStates.strategy_price_annual.value,
            }

            const publicStrategyvalidations = {
                strategy_subject          : 'required|max:100',
                strategy_desc             : 'required',
                strategy_price_monthly    : 'required|numeric|min:500',
                strategy_price_quarterly  : 'required|numeric|min:500',
                strategy_price_halfyearly : 'required|numeric|min:500',
                strategy_price_annual     : 'required|numeric|min:500',
            }

            const privateStrategyData = {}
            const privateStrategyvalidations = {}

        // -------------------------------------------------------------------------------------------------------------
          
          const validationData = { 
                strategy_name : localStates.strategy_name.value, 
                broker        : localStates.broker.value, 
                start_time    : localStates.start_time.value,
                end_time      : localStates.end_time.value,

                start_time_hr : localStates.start_time_hr.value,
                start_time_mt : localStates.start_time_mt.value,
                start_time_fm : localStates.start_time_fm.value,

                end_time_hr : localStates.end_time_hr.value,
                end_time_mt : localStates.end_time_mt.value,
                end_time_fm : localStates.end_time_fm.value,
          };

          const validationRules = { 
              strategy_name : 'required|stringValidation|min:4|max:10', 
              broker        : 'required',

              start_time_hr : 'required|numeric|hoursValidation|min:0',
              start_time_mt : 'required|numeric|minutesValidation|min:0',
              start_time_fm : 'required',

              end_time_hr   : 'required|numeric|hoursValidation|min:0',
              end_time_mt   : 'required|numeric|minutesValidation|min:0',
              end_time_fm   : 'required',

          };

          const errorMessages = {
              
                "required.strategy_name"         : "Please describe strategy name",
                "min.strategy_name"              : "Strategy name should be minimum 4 charcters",
                "max.strategy_name"              : "Strategy name must be at most 10 characters",
                "stringValidation.strategy_name" : "Specify Strategy Name, which should not be more than 10 character and can contain underscore(_)",

                "required.broker"        : "Please describe broker for the strategy",

              "required.start_time_hr"  : "Please describe strategy start time hours",
              "required.start_time_mt"  : "Please describe strategy start time minutes",
              "required.start_time_fm"  : "Please describe strategy start time format",

              "min.start_time_hr"  : "The start time hour must be at least 0",
              "min.start_time_mt"  : "The start time minute must be at least 0",

              "min.end_time_hr"  : "The end time hour must be at least 0",
              "min.end_time_mt"  : "The end time minute must be at least 0",


              "required.end_time_hr"   : "Please describe strategy end time hours",
              "required.end_time_mt"   : "Please describe strategy end time minutes",
              "required.end_time_fm"   : "Please describe strategy end time format",

              "required.strategy_subject"          : "Please describe strategy subject",
              "stringValidation.strategy_subject"  : "Please describe valid strategy subject",
              "required.strategy_desc"             : "Please describe strategy description",

              "required.strategy_price_monthly"    : "Please describe strategy monthly price",
              "required.strategy_price_quarterly"  : "Please describe strategy quarterly price",
              "required.strategy_price_halfyearly" : "Please describe strategy halfyearly price",
              "required.strategy_price_annual"     : "Please describe strategy annual price",

              "numeric.strategy_price_monthly"    : "Please describe positive numeric number for monthly price",
              "numeric.strategy_price_quarterly"  : "Please describe positive numeric number for quarterly price",
              "numeric.strategy_price_halfyearly" : "Please describe positive numeric number for halfyearly price",
              "numeric.strategy_price_annual"     : "Please describe positive numeric number for annualy price",

              "min.strategy_price_monthly"    : "Strategy price should be minimum or equal to 500",
              "min.strategy_price_quarterly"  : "Strategy price should be minimum or equal to 500",
              "min.strategy_price_halfyearly" : "Strategy price should be minimum or equal to 500",
              "min.strategy_price_annual"     : "Strategy price should be minimum or equal to 500",
          }

          const formData        = Object.assign(validationData,  strategyType === 'private_strategy' ? privateStrategyData : publicStrategyData );
          const formValidations = Object.assign(validationRules, strategyType === 'private_strategy' ? privateStrategyvalidations : publicStrategyvalidations );

          const validation = new validator(formData, formValidations,errorMessages);
          const userState  = { ...localStates }
          const validationResponse = { submitStatus : true, postData : formData  }

          if(validation.fails()) validationResponse.submitStatus = false;

            for (const name in userState) { 
 
              userState[name].error = validation.errors.get(name).length > 0 ? true : false;
              userState[name].errorMessage = validation.errors.first(name) || '' 
            }  
            
            updateStates({ ...userState }) // update errors and error messages //

          return validationResponse;
    }

  // -----------------------------------------------------------------------------------------------------------

  // @methods---------------------------------------------------------------------------------------------------

    const changeHandler = ({ name , value }) => { 
        
        updateStates({ ...localStates,[name]:{ value : value , error: false, errorMessage: '' } })  
    }

    const submitHandler = () => {

        const { submitStatus , postData  } = formValidation();

        if(submitStatus === false) return false

            // date logic  ------------------------------------------------------------------------------------------------------------------------ */

                postData.start_time_hr = Number(postData.start_time_hr); // convert string to number
                postData.start_time_mt = Number(postData.start_time_mt); // convert string to number

                postData.end_time_hr = Number(postData.end_time_hr); // convert string to number
                postData.end_time_mt = Number(postData.end_time_mt); // convert string to number


                if(postData.start_time_fm === 'PM') postData.start_time_hr += 12 // time formate is pm then add 12 to make 24 hours time format

                if(postData.end_time_fm === 'PM') postData.end_time_hr += 12 // time formate is pm then add 12 to make 24 hours time format


                postData.start_time_mt += postData.start_time_hr * 60 // convert hours into minutes and assign to minutes
                postData.end_time_mt += postData.end_time_hr * 60 // convert hours into minutes and assign to minutes


                postData.start_time = postData.start_time_mt * 60 // finally multiply the total minutes to 60
                postData.end_time   = postData.end_time_mt * 60 // finally multiply the total minutes to 60

                if(postData.start_time_fm === "AM" && postData.start_time >= 43200) {
                    return updateStates({ ...localStates,start_time_hr: {
                        value : localStates.start_time_hr.value , error: true, errorMessage: 'Start time should between 09:15 AM and 11:59 PM' 
                   }})
                }


                if(postData.start_time < 33300) return updateStates({ ...localStates,start_time_hr: {
                     value : localStates.start_time_hr.value , error: true, errorMessage: 'Start time should grater then 9 : 15 AM' 
                }})

                if(postData.end_time > 86340) return updateStates({ ...localStates,end_time_hr: {
                    value : localStates.end_time_hr.value , error: true, errorMessage: 'End time should less then 11 : 59 PM' 
                }})

                if(postData.end_time < postData.start_time) return updateStates({ ...localStates,end_time_hr: {
                    value : localStates.end_time_hr.value , error: true, errorMessage: 'End time should greater than closing time' 
                }})


            /* generate payload data ----------------------------------------------------------------------------------------------------------------- */

               const payload = {};

                    payload.strategy_name = postData.strategy_name
                    payload.broker        = postData.broker
                    payload.start_time    = postData.start_time
                    payload.end_time      = postData.end_time
                    payload.strategy_type = strategyType === 'private_strategy' ? 'PRIVATE' : 'PUBLIC'
                    payload.strategy_price = [ { 'monthly'    : 0 },  { 'quarterly'  : 0 }, { 'halfyearly' : 0 }, { 'annual'     : 0 }]

                    if(payload.strategy_type === 'PUBLIC') {

                        payload.strategy_subject = postData.strategy_subject
                        payload.strategy_desc    = postData.strategy_desc

                        payload.strategy_price[0].monthly    = postData.strategy_price_monthly 
                        payload.strategy_price[1].quarterly  = postData.strategy_price_quarterly 
                        payload.strategy_price[2].halfyearly = postData.strategy_price_halfyearly 
                        payload.strategy_price[3].annual     = postData.strategy_price_annual 

                    }

                props.stepValidator('stepOne','stepOneValidationComplete',payload); // send data to main function

                toggleValidationButton(false) // hide validation button of step one

            // --------------------------------------------------------------------------------------------------- //
    }

    const changeStrategyType = (strategy) => {

        updateStates({ ...localStates,...publicStrategyState,...privateStrategyState})

        switchStrategyType(strategy) // 
    }

  //------------------------------------------------------------------------------------------------------------


  return (
    <div className={toggleClass}><div className="step-one"> 

        <StepHeading text="Strategy details" content="Specify Strategy Name, which should not be more than 10 character and can contain underscore(_) or dash(-). Pick a broker, where you wants to place the order."  id="strategy-details"></StepHeading>     
   
          <FormDetails>

              { props.updateStrategy.status === true  && <SpanTag text={localStates.strategy_name.value}  md={6} label="Strategy Name"></SpanTag>}
              { props.updateStrategy.status === false && <TextField method={changeHandler} value={localStates.strategy_name.value} error={localStates.strategy_name.error} errorMessage={localStates.strategy_name.errorMessage} name="strategy_name" md={6} label="Strategy Name"></TextField>}              
              
              <DropDownField method={changeHandler} value={localStates.broker.value}        error={localStates.broker.error}        errorMessage={localStates.broker.errorMessage}        name="broker"        md={6} label="Broker" data={brokers}></DropDownField>
          </FormDetails>

        <StepHeading text="Duration and strategy type" content="Mention Strategy Execution eg: Strategy Should run between 9.15 AM to 10.30 AM.If you wish to list your strategy in market place, than select strategy type as Public." id="duration-strategy-details"></StepHeading>   
        
            <FormDetails>
                <TimeField method={changeHandler} state={localStates} name="start_time" label="Start time" ></TimeField>
                <TimeField method={changeHandler} state={localStates} name="end_time"   label="End time"   ></TimeField>
                
                <SelectStrategyType 
                    userDetails={userDetails}
                    strategyType={strategyType} 
                    changeStrategyType={changeStrategyType}
                ></SelectStrategyType>

            </FormDetails>

            <NotificationSystem
                dismissible={false}
                ref={notificationSystem}
                style={NOTIFICATION_SYSTEM_STYLE}
            ></NotificationSystem>

        { strategyType === 'public_strategy'  &&  <PublicStrategy  method={changeHandler} localStates={localStates}></PublicStrategy>   }
        { strategyType === 'private_strategy' &&  <PrivateStrategy method={changeHandler} localStates={localStates}></PrivateStrategy> }
      
        <div className="button-group">
            { validationButton && <button id="next" className="button" type="button" onClick={submitHandler}>Next</button> }
        </div>
    </div></div>
  );
};

export default StepOne;

