import React, { Component } from 'react'

// @import-svg-files ------------------------------------------------------------------------------------------------------

        import Logo      from '../assets/error-page-icons/Group 2004.png'
        import ErrorLogo from '../assets/error-page-icons/clarity_error-solid.png'
   
// -----------------------------------------------------------------------------------------------------

export default class Error500 extends Component {

    home(){ this.props.history.push('/dashboard') }

    render() { return (
        <div>
          <section>
              <div class="error">
                  <div class="error-head">
                      <nav class="navbar">
                          <a class="navbar-brand" href="/">
                              <img src={Logo} alt="there is an error" />
                          </a>
                      </nav>
                  </div>
          
                  <div class="error-content">
                      <div class="error-heading">
                          <h3>OOPS! Some problem <br />has occurred</h3>
                      </div>
                      <div class="error-message">
                          <p><img src={ErrorLogo} alt="there is an error" />Something went wrong.</p>
                          <p>Please Go back to dashboard.</p>
                      </div>
                      <div class="error-btn">
                          <button class="btn btn-group" onClick={() => this.home()}>
                              <i class="fa fa-arrow-left">&nbsp;&nbsp; Back</i>
                              &nbsp;&nbsp; 
                          </button>
                      </div>
                  </div>
              </div>
          </section>
        </div>
    )}
}
