import React from 'react'

// @import-components ------------------------------------------------
     
   import StepHeading from '../StepHeading'
   import FormDetails from '../FormDetails';
   import TextField        from '../TextField';
   import RadioButtonField from '../RadioButtonField';

// -------------------------------------------------------------------

const EntryPrice = (props) => {

  return (
    <React.Fragment>
        <StepHeading text="Entry Price" id="step-three-entry-price" content="Define entery price in point and percentage.Eg: for 30 points, mention 30 and for percentange mention 0.5 which is 0.5%"></StepHeading>

        <FormDetails>
            <RadioButtonField md={6} 
                label="Type of entry value" name="entrypoint_type"  method={props.changeHandler}
                error={props.state.entrypoint_type.error} value={props.state.entrypoint_type.value}
                errorMessage={props.state.entrypoint_type.errorMessage}
                textOne="Point"  textTwo="Percentage"
                valueOne="point" valueTwo="percentage"
            ></RadioButtonField>
            
            <TextField md={6} label="Entry value" name="entrypoint_value" method={props.changeHandler}
                error={props.state.entrypoint_value.error}  value={props.state.entrypoint_value.value}
                errorMessage={props.state.entrypoint_value.errorMessage}
            ></TextField>
        </FormDetails>
    </React.Fragment>
  )
}

export default EntryPrice