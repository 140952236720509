import axios from 'axios'
import store from 'store'
var expirePlugin = require('store/plugins/expire')
store.addPlugin(expirePlugin)

const axios_obj = axios.create({
  //timeout: 1000,
  crossDomain: true,
  method: 'post',
  responseType: 'json',
  // headers:{"Access-Control-Allow-Origin:":"*"}
});

/** coder-kabir */

  axios_obj.interceptors.request.use(function (config) {

    const publicRoutes = ['/trade-api/indicators-list','/trade-api/brokers-list','/trade-api/top-strategy','/trade-api/user/login','/trade-api/user/forgot-password']
    const redirectURL = `${window.location.origin}/login`

    if(publicRoutes.includes(config.url) === true) return config;

    if(store.get('user_data') === undefined) return window.location.replace(redirectURL) 

    return config;

  },(error) => { return Promise.reject(error); 
});

export const tradeService = {
  authLogin,
  forgotPassword,
  getIIFLAuth,
  saveAccessToken,
  saveBrokerConfig,
  getBrokerConfig,
  getUserConfig,
  getUserPnl,
  getBrokerAuthUrl,
  getBrokerAuthCallback,
  getUsers,
  updateUserStatus,
  getStats,
  getTradeLog,
  getTradePostions,
  buildStrategy,
  execStrategy,
  deployStrategy,
  activeSubscribers,
  publicStrategyPayment,
  undeployStrategy,
  getStrategy,
  getProfile,
  getTopStrategy,
  updateNotifyInfo,
  updatedUserDetails,
  getUserStrategy,
  getUserMembership,
  notifyUser,
  getUserPaymentHistory,
  getMarketPlaceStrategy,
  subscribeStrategy,
  confirmSubscription,
  getdownloadData,
  startDataFeed,
  stopJobs,
  manageServer,
  isUserSessionActive,
  symbolList,
  indicatorList,
  updateSymbols,
  closeTrade,
  repairStrategy,
  updateConvergenceData,
  checkIntradayData,
  deployedStrategy,
  getConvergence,
  getRSI,
  getCOI,
  submitContactUs,
  strategyStats,
  verifyMembershipRenewal,
  renewMembership,
  getBrokersList,
  getIndicatorsList,
  cleanOpenTrade,
  cleanRedis
};


// let config_data = (store.get('user_data') === undefined) ? null : store.get("user_data").broker_config;
// let iifl_trading_link;
//     config_data = JSON.parse(config_data)
//     if(config_data !== null){
//       iifl_trading_link = config_data.iifl_trading_link;
//       //iifl_data_link = config_data.iifl_data_link;

//     }

//Setting Authorization header in case of auth token received after login
let token = (store.get('user_data') === undefined) ? null :store.get('user_data').token;
if(token !==null){
 axios_obj.defaults.headers.common['Authorization'] = "Bearer "+token;
}


/* ====== API call for user module ========*/

/**
 * @abstract Method to validate user credentials for login process
 * @param {string} uname
 * @param {string} pass
 */
async function authLogin(uname,pass){
    return await axios_obj({
          method: 'post',
          url: '/trade-api/user/login',
          data: {
            username:uname,
            password:pass
          }
        }).then(function (response) {
          //console.log(response)
          //console.log(error);response.data);

          let user_data = {};
          let username = response.data.data.username
          let auth_type = response.data.data.auth
          user_data.username = username;
          user_data.auth = auth_type
          // user_data.broker_config = JSON.stringify(response.data.data.broker_resp);
          let token = JSON.stringify(response.data.data.token);
          token = token.replaceAll('"',"").trim();
          user_data.token = token;
          store.set('user_data',user_data,new Date().getTime() + (1*60*60*1000)) //hour*minute*seconds*1000
          //Setting Default Auth Header after login
          axios_obj.defaults.headers.common['Authorization'] = "Bearer "+token;
          return response.data;
        })
        .catch(function (error) {
          //console.log(error);error);
          return "error_occured";
        });
}

async function cleanOpenTrade(){

  return await axios_obj({
      method: 'post',
      url: '/trade-api/admin/clean-open-trade',
      data: {}
  }).then(function (response) { return response.data;})
    .catch(function (error) {
      return "error_occured";
  });
}

async function cleanRedis(){

  return await axios_obj({
      method: 'post',
      url: '/trade-api/admin/flush-redis-database',
      data: {}
  }).then(function (response) { return response.data;})
    .catch(function (error) {
      return "error_occured";
  });
}

async function forgotPassword(uname,email){

    return await axios_obj({
      method: 'post',
      url: '/trade-api/user/forgot-password',
      data: {
        username:uname,
        email:email
      }
    }).then(function (response) {
      //console.log(response)
      //console.log(error);response.data);
      return response.data;
    })
    .catch(function (error) {
      //console.log(error);error);
      return "error_occured";
    });

}




async function verifyMembershipRenewal(payload){

  return await axios_obj({
      method: 'post',
      url: '/trade-api/payment-status-app',
      data: payload
  })
  .then(function (response) { return response.data; })
  .catch(function (error) { return "error_occured"; });
}

async function renewMembership(payload){

  return await axios_obj({
      method: 'post',
      url: '/trade-api/user/renew-membership',
      data: payload
  })
  .then(function (response) { return response.data; })
  .catch(function (error) { return "error_occured"; });
}

async function strategyStats(payload){

    return await axios_obj({
        method: 'post',
        url: '/trade-api/get-strategy-stats',
        data: payload
    })
    .then(function (response) { return response.data; })
    .catch(function (error) { return "error_occured"; });
}

async function getIIFLAuth(info){

   let iifl_base_url;
  // if(iifl_trading_link === undefined){
  //   let config_data = store.get('user_data').broker_config;
  //   config_data = JSON.parse(config_data);
  //   iifl_base_url = config_data.iifl_trading_link
  // }else{
  //   iifl_base_url = iifl_trading_link
  // }

  return await axios_obj({
    method: 'post',
    url: iifl_base_url + '/user/session',
    data: info
    }).then(function (response) {
      //console.log(error);response);
      if(response.data.type === 'success'){
        store.set("iifl_access",{"iifl_access_token":response.data.result.token},new Date().getTime() + (1*60*60*1000)) //hour*minute*seconds*1000
        saveAccessToken(); // Saving user session token
      }
      return response.data;
    })
    .catch(function (error) {
      //console.log(error);error);
      return "error_occured";
    });

}


/**
 * @abstract Save method  for access token
 * @author JM
 * @version 1.0.0
 */
async function saveAccessToken(){
  let token_data = {};
  let requestData = {};

  if(store.get("zerodha_access") !== undefined){
    token_data['zerodha_token'] = store.get("zerodha_access").zerodha_req_token;
  }


  if(store.get("iifl_access") !== undefined){
    //console.log(error);"Add IIFL Token");
    token_data['iifl_token'] = store.get("iifl_access").iifl_access_token;
  }

  requestData['token_data'] = token_data
  requestData['username'] = store.get("user_data").username;
  //requestData['last_updated_time'] = "default"

  return await axios_obj({
    method: 'post',
    url: '/trade-api/user/saveToken',
    data: requestData
  }).then(function (response) {
    //console.log(error);response);
    return response.data;
  })
  .catch(function (error) {
    //console.log(error);error);
    return "error_occured";
  });

}


/**
 * @abstract Method to save broker configuration
 * @author JM
 * @version 1.0.0
 * @param form_data
 */
async function saveBrokerConfig(form_data){
  return await axios_obj({
        method: 'post',
        url: '/trade-api/user/saveBrokerConfig',
        data:form_data
      }).then(function (response) {
        let user_data = store.get("user_data")
        user_data.broker_config = JSON.stringify(form_data);
        store.set('user_data',user_data,new Date().getTime() + (1*60*60*1000)) //hour*minute*seconds*1000
        return response.data;
      })
      .catch(function (error) {
        return "error_occured";
      });
}

/**
 * @abstract Method to get broker configuration for user
 * @author JM
 * @version 1.0.0
 */
async function getBrokerConfig(){
  return await axios_obj({
    method: 'post',
    url: '/trade-api/user/getBrokerConfig',
  }).then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    //console.log(error);error);
    return "error_occured";
  });
}
/**
 * @abstract Method to get logged user configurations
 * @author JM
 * @version 1.0.0
 */
async function getUserConfig(){
  return await axios_obj({
    method: 'post',
    url: '/trade-api/user/config',
  }).then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    return "error_occured";
  });
}
/**
 * @abstract Method to get broker auth url
 * @author JM
 * @version 1.0.0
 */
 async function getBrokerAuthUrl(payload){
  //const params = new URLSearchParams(payload).toString();
  //console.log(params)
  return await axios_obj({
    method: 'post',
    url: `/trade-api/broker/auth`,
    data:payload,
  }).then(function (response) {
    return response.data;
  }).catch(function (error) {
    return "error_occured";
  });
}
/**
 * @abstract Method to get broker auth url
 * @author JM
 * @version 1.0.0
 */
 async function getBrokerAuthCallback(payload){
  // const params = new URLSearchParams(payload).toString();
  // console.log(params)
  return await axios_obj({
    method: 'post',
    url: `/trade-api/broker/callback`,
    data:payload,
  }).then(function (response) {
    return response.data;
  }).catch(function (error) {
    return "error_occured";
  });
}
/**
 * @abstract Method to get Trade log for user
 * @author JM
 * @version 1.0.0
 * @param user_info
 */
async function getTradeLog(user_info){
  return await axios_obj({
    method: 'post',
    url: '/trade-api/get-tradelog',
    data: user_info
  }).then(function (response) {
    //console.log(error);response);
    return response.data;
  })
  .catch(function (error) {
    //console.log(error);error);
    return "error_occured";
  });
}

/**
 * @abstract Method to get Stats for user
 * @author JM
 * @version 1.0.0
 * @param user_info
 */
async function getStats(user_info){
  return await axios_obj({
    method: 'post',
    url: '/trade-api/get-stats',
    data: user_info
  }).then(function (response) {
    //console.log(error);response);
    return response.data;
  })
  .catch(function (error) {
    //console.log(error);error);
    return "error_occured";
  });
}

async function updateUserStatus(info){
  return await axios_obj({
    method: 'post',
    url: '/trade-api/update-user-status',
    data: info
  }).then(function (response) {
    //console.log(error);response);
    return response.data;
  })
  .catch(function (error) {
    //console.log(error);error);
    return "error_occured";
  });
}

/**
 * @abstract Method to get user trade positions
 * @author JM
 * @version 1.0.0
 * @param user_info
 */
async function getTradePostions(user_info){
  return await axios_obj({
    method: 'post',
    url: '/trade-api/get-position',
    data: user_info
  }).then(function (response) {
    //console.log(error);response);
    return response.data;
  })
  .catch(function (error) {
    //console.log(error);error);
    return "error_occured";
  });
}


/**
 * @abstract Method to build strategy
 * @author JM
 * @version 1.0.0
 * @param form_data
 */
async function buildStrategy(form_data){
  return await axios_obj({
        method: 'post',
        url: '/trade-api/build-strategy',
        data:form_data
      }).then(function (response) {
        //console.log(error);response);
        return response.data;
      })
      .catch(function (error) {
        //console.log(error);error);
        return "error_occured";
      });
}


/**
 * @abstract Method to execute strategy
 * @author JM
 * @version 1.0.0
 * @param form_data
 */
async function execStrategy(form_data){

  return await axios_obj({
        method: 'post',
        url: '/trade-api/exe-strategy',
        data:form_data
      }).then(function (response) {
        //console.log(error);response);
        return response.data;
      })
      .catch(function (error) {
        //console.log(error);error);
        return "error_occured";
      });
}

/**
 * @abstract Method to deploy strategy
 * @author JM
 * @version 1.0.0
 * @param form_data
 */
async function deployStrategy(form_data){

  return await axios_obj({
        method: 'post',
        url: '/trade-api/deploy-strategy',
        data:form_data
      }).then(function (response) {
        //console.log(error);response);
        return response.data;
      })
      .catch(function (error) {
        //console.log(error);error);
        return "error_occured";
      });
}

/**
 * @abstract Method to undeploy strategy
 * @author JM
 * @version 1.0.0
 * @param form_data
 */
async function undeployStrategy(form_data){

  return await axios_obj({
        method: 'post',
        url: '/trade-api/undeploy-strategy',
        data:form_data
      }).then(function (response) {
        //console.log(error);response);
        return response.data;
      })
      .catch(function (error) {
        //console.log(error);error);
        return "error_occured";
      });
}


async function getMarketPlaceStrategy(form_data){

   return await axios_obj({
       method: 'post',
       url: '/trade-api/get-public-strategy',
       data:form_data
     }).then(function (response) {
       //console.log(error);response);
       return response.data;
     })
     .catch(function (error) {
       //console.log(error);error);
       return "error_occured";
     });
}

async function subscribeStrategy(form_data){

  return await axios_obj({
      method: 'post',
      url: '/trade-api/subscribe-mp',
      data:form_data
    }).then(function (response) {
      //console.log(error);response);
      return response.data;
    })
    .catch(function (error) {
      //console.log(error);error);
      return "error_occured";
    });
}

async function confirmSubscription(form_data){

  return await axios_obj({
      method: 'post',
      url: '/trade-api/cnf-mp-subscription',
      data:form_data
    }).then(function (response) {
      //console.log(error);response);
      return response.data;
    })
    .catch(function (error) {
      //console.log(error);error);
      return "error_occured";
    });
}

/**
 * @abstract Deploy Data Feed Job
 * @author JM
 * @version 1.0.0
 * @param {*} form_data
 */
async function startDataFeed(){

  return await axios_obj({
        method: 'post',
        url: '/trade-api/hist-data-dump',
        data : {
          "exchange":"future"
          }
      }).then(function (response) {
        //console.log(error);response);
        return response.data;
      })
      .catch(function (error) {
        //console.log(error);error);
        return "error_occured";
      });
}

/**
 * @abstract Stop all Job
 * @author JM
 * @version 1.0.0
 * @param {*} form_data
 */
async function stopJobs(){

  return await axios_obj({
        method: 'get',
        url: '/trade-api/stop-jobs',
      }).then(function (response) {
        //console.log(error);response);
        return response.data;
      })
      .catch(function (error) {
        //console.log(error);error);
        return "error_occured";
      });
}

/**
 * @abstract Update Symbols 
 * @author JM
 * @version 1.0.0
 * @param {*} form_data
 */
 async function updateSymbols(){

  return await axios_obj({
        method: 'post',
        url: '/trade-api/fetch-symbols',
      }).then(function (response) {
        //console.log(error);response);
        return response.data;
      })
      .catch(function (error) {
        //console.log(error);error);
        return "error_occured";
      });
}


/**
 * @abstract Update Convergence Data 
 * @author JM
 * @version 1.0.0
 * @param {*} form_data
 */
 async function updateConvergenceData(){

  return await axios_obj({
        method: 'post',
        url: '/trade-api/find-convergence',
      }).then(function (response) {
        //console.log(error);response);
        return response.data;
      })
      .catch(function (error) {
        //console.log(error);error);
        return "error_occured";
      });
}

async function checkIntradayData(){

  return await axios_obj({
        method: 'post',
        url: '/trade-api/admin/intraday-data-check',
  }).then(function (response) { return response.data; })
    .catch(function (error)   { return "error_occured"; });
}

async function deployedStrategy(){

  return await axios_obj({
        method: 'post',
        url: '/trade-api/admin/deployed-strategy',
  }).then(function (response) { return response.data; })
    .catch(function (error)   { return "error_occured"; });
}

async function activeSubscribers(){

  return await axios_obj({
        method: 'post',
        url: '/trade-api/admin/active-subscribers',
  }).then(function (response) { return response.data; })
    .catch(function (error)   { return "error_occured"; });
}
async function publicStrategyPayment(){

  return await axios_obj({
        method: 'post',
        url: '/trade-api/admin/public-strategy-payment',
  }).then(function (response) { return response.data; })
    .catch(function (error)   { return "error_occured"; });
}

/**
 * @abstract Update Convergence Data 
 * @author JM
 * @version 1.0.0
 * @param {*} form_data
 */
 async function getConvergence(){

  return await axios_obj({
        method: 'post',
        url: '/trade-api/get-convergence',
      }).then(function (response) {
        //console.log(error);response);
        return response.data;
      })
      .catch(function (error) {
        //console.log(error);error);
        return "error_occured";
      });
}


/**
 * @author JM
 * @version 1.0.0
 * @param {*} form_data
 */
 async function getRSI(){

  return await axios_obj({
        method: 'post',
        url: '/trade-api/get-rsi',
      }).then(function (response) {
        //console.log(error);response);
        return response.data;
      })
      .catch(function (error) {
        //console.log(error);error);
        return "error_occured";
      });
}


/**
 * @author JM
 * @version 1.0.0
 * @param {*} form_data
 */
 async function getCOI(){

  return await axios_obj({
        method: 'post',
        url: '/trade-api/get-coi',
      }).then(function (response) {
        //console.log(error);response);
        return response.data;
      })
      .catch(function (error) {
        //console.log(error);error);
        return "error_occured";
      });
}


/**
 * @abstract Method to build strategy
 * @author JM
 * @version 1.0.0
 * @param form_data
 */
async function getStrategy(form_data){

  return await axios_obj({
        method: 'post',
        url: '/trade-api/load-user-strategy',
        data:form_data
      }).then(function (response) {
        //console.log(error);response);
        return response.data;
      })
      .catch(function (error) {
        //console.log(error);error);
        return "error_occured";
      });
}

/**
 * @abstract Method to load top strategies
 * @author JM
 * @version 1.0.0
 * @param form_data
 */
 async function getTopStrategy(){

  return await axios_obj({
        method: 'post',
        url: '/trade-api/top-strategy',
      }).then(function (response) {
        //console.log(error);response);
        return response.data;
      })
      .catch(function (error) {
        //console.log(error);error);
        return "error_occured";
      });
}

/**
 * @abstract Method to load users
 * @author JM
 * @version 1.0.0
 */
async function getUsers(){

  return await axios_obj({
        method: 'get',
        url: '/trade-api/users',
      }).then(function (response) {
        //console.log(error);response);
        return response.data;
      })
      .catch(function (error) {
        //console.log(error);error);
        return "error_occured";
      });
}

/**
 * @abstract Method to load users
 * @author JM
 * @version 1.0.0
 */
async function getUserMembership(info){

  return await axios_obj({
        method: 'post',
        url: '/trade-api/fetch-membership',
        data:info
      }).then(function (response) {
        //console.log(error);response);
        return response.data;
      })
      .catch(function (error) {
        //console.log(error);error);
        return "error_occured";
      });
}

async function notifyUser(info){

  return await axios_obj({
       method: 'post',
       url: '/trade-api/renewal-notify',
       data:info
     }).then(function (response) {
       //console.log(error);response);
       return response.data;
     })
     .catch(function (error) {
       //console.log(error);error);
       return "error_occured";
     });
}

/**
 * @abstract Method to load users
 * @author JM
 * @version 1.0.0
 */
async function getUserPaymentHistory(info){

  return await axios_obj({
        method: 'post',
        url: '/trade-api/fetch-payments',
        data:info
      }).then(function (response) {
        //console.log(error);response);
        return response.data;
      })
      .catch(function (error) {
        //console.log(error);error);
        return "error_occured";
      });
}

/**
 * @abstract Method to send command to manage server
 * @author JM
 * @version 1.0.0
 * @param form_data
 */
async function manageServer(action){

  return await axios_obj({
        method: 'post',
        url: '/trade-api/admin/manageDroplet',
        data:{"command":action}
      }).then(function (response) {
        //console.log(error);response);
        return response.data;
      })
      .catch(function (error) {
        //console.log(error);error);
        return "error_occured";
      });
}

/**
 * @abstract Get User Details to UI form
 * @author SS
 * @version 1.0.0
 * @param form_data
 */
async function getProfile(form_data){
  return await axios_obj({
        method: 'post',
        url: '/trade-api/user/getUser',
        data:form_data
      }).then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        //console.log(error);error);
        return "error_occured";
      });
}

/**
 * @abstract Get User PNL
 * @author @coder-kabir
 * @version 1.0.0
 * @param form_data
 */
 async function getUserPnl(form_data){
  return await axios_obj({
        method: 'post',
        url: '/trade-api/user/getUserPnl',
        data:form_data
     
     }).then(function (response) { return response.data; })
       .catch(function (error) { return "error_occured"; });
}


 async function getBrokersList(form_data){
  return await axios_obj({
        method: 'post',
        url: '/trade-api/brokers-list',
        data:form_data
     })
    .then(function (response) { return response.data; })
    .catch(function (error) { return "error_occured"; });
}

async function getIndicatorsList(form_data){
  return await axios_obj({
        method: 'post',
        url: '/trade-api/indicators-list',
        data:form_data
     })
    .then(function (response) { return response.data; })
    .catch(function (error) { return "error_occured"; });
}



/**
 * @abstract Method to updated user details
 * @author SS
 * @version 1.0.0
 * @param form_data
 */
async function updatedUserDetails(form_data){
   return await axios_obj({
         method: 'post',
         url: '/trade-api/user/update-profile',
         data:form_data
       }).then(function (response) {
         return response.data;
       })
       .catch(function (error) {
         //console.log(error);error);
         return "error_occured";
       });
 }

 async function submitContactUs(form_data){

    return await axios_obj({
      method: 'post',
      url: '/trade-api/enquiry',
      data:form_data
    }).then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      //console.log(error);error);
      return "error_occured";
    });  

}


 /**
 * @abstract Method to updated user notification details
 * @author JM
 * @version 1.0.0
 * @param form_data
 */
async function updateNotifyInfo(form_data){
  return await axios_obj({
        method: 'post',
        url: '/trade-api/user/update-notify',
        data:form_data
      }).then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        //console.log(error);error);
        return "error_occured";
      });
}

 async function getUserStrategy(form_data){
  return await axios_obj({
        method: 'post',
        url: '/trade-api/get-strategy',
        data:form_data
      }).then(function (response) {
        return response;
      })
      .catch(function (error) {
        //console.log(error);error);
        return "error_occured";
      });
}

async function getdownloadData(form_data){
  return await axios_obj({
        method: 'post',
        //url: '/trade-api/download-data',
        url: '/trade-api/backtest-strategy',
        data:form_data,
        //responseType: 'blob' // important
      }).then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        //console.log(error);error);
        return "error_occured";
      });
}


async function indicatorList(){
   return await axios_obj({
         method: 'post',
         url: '/trade-api/get-indicators',
       }).then(function (response) {
        let ind_arr = []
         if(response.data.statusCode === "200"){
           let list = response.data.data
           // list.forEach(function(val) {
           //    ind_arr.push({
           //     "value":val,
           //     "label":val.toUpperCase()
           //    })
           // });
           return list;
         }
         return ind_arr;
       })
       .catch(function (error) {
         //console.log(error);error);
         return "error_occured";
       });
}

async function symbolList(data){
   return await axios_obj({
         method: 'post',
         url: '/trade-api/get-symbols',
         data:data,
       }).then(function (response) {
        let symbol_arr = []
         if(response.data.statusCode==="200"){
           let list = response.data.message
           list.forEach(function(val) {
              symbol_arr.push({
               "value":val,
               "label":val
              })
           });
         }
         return symbol_arr;
       })
       .catch(function (error) {
         //console.log(error);error);
         return "error_occured";
       });
}

/**
 * @abstract Method to close the open trade
 * @author JM
 * @version 1.0.0
 */
 async function closeTrade(payload){
  //const params = new URLSearchParams(payload).toString();
  return await axios_obj({
    method: 'post',
    url: `/trade-api/close-order`,
    data:payload,
  }).then(function (response) {
    return response.data;
  }).catch(function (error) {
    return "error_occured";
  });
}
/**
 * @abstract Method to repaire existing strategy
 * @author JM
 * @version 1.0.0
 */
 async function repairStrategy(payload){
  //const params = new URLSearchParams(payload).toString();
  return await axios_obj({
    method: 'post',
    url: `/trade-api/repair-order`,
    data:payload,
  }).then(function (response) {
    return response.data;
  }).catch(function (error) {
    return "error_occured";
  });
}

function isUserSessionActive(){

    let check = (store.get('user_data') === undefined) ? false : true;
    return check;
}
