import React from 'react';
import store from 'store';

// @import-components && logos ------------------------------------------------------------------

    import logo200Image from 'assets/img/logo/logo.svg';
    import SidebarFooter from 'components/SidebarFooter';
    import icons from 'data/sidebarIcons';

    import { IoMdBuild } from 'react-icons/io';
    import { MdDashboard, MdKeyboardArrowDown } from 'react-icons/md';
    import { SiGoogleanalytics } from 'react-icons/si';
    import NotificationSystem from 'react-notification-system';
    import { Link, NavLink } from 'react-router-dom';
    import { Collapse,Nav,Navbar,NavItem,NavLink as BSNavLink, } from 'reactstrap';

// @import-hooks && helpers ----------------------------------------------------------------------

    import bn from 'utils/bemnames';
    import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';

// -----------------------------------------------------------------------------------------------

// @sidebar-menu-links && groups -----------------------------------------------------------------

    const adminOperations = [
        { to: '/admin-ops', name: 'admin operations', exact: true, Icon: MdDashboard },
    ];

    const adminOperationsMain = [
        {
            to: '/manage-users',
            name: 'Manage Users',
            exact: false,
            Icon: IoMdBuild,
            src: icons.operationBuildStrategy,
        },
        {
            to: '/deployed-strategy',
            name: 'Deployed Strategy',
            exact: false,
            Icon: IoMdBuild,
            src: icons.operationBuildStrategy,
        },
        {
            to: '/active-subscribers',
            name: 'Active Subscribers',
            exact: false,
            Icon: IoMdBuild,
            src: icons.operationBuildStrategy,
        },
        {
            to: '/public-strategy-payment',
            name: 'Public Strategy Payment',
            exact: false,
            Icon: IoMdBuild,
            src: icons.operationBuildStrategy,
        }
    ]

    const manageUsers = [
        {
          to: '/build-strategy',
          name: 'View',
          exact: false,
          Icon: IoMdBuild,
          src: icons.operationBuildStrategy,
        },
        {
          to: '/manage-strategy',
          name: 'Add',
          exact: false,
          Icon: SiGoogleanalytics,
          src: icons.operationManageStrategy,
        },
      ];
 
// -----------------------------------------------------------------------------------------------

const bem = bn.create('sidebar');

class AdminSidebar extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
            isOpenComponents: true,
            isOpenContents: true,
            isOpenPages: true,
            userData: store.get('user_data'),
        };

        this.style = {
            padding: 0,
            transform: this.state.isOpenFirst ? 'rotate(0deg)' : 'rotate(-90deg)',
            transitionDuration: '0.3s',
            transitionProperty: 'transform',
        };

        this.linkHandler = this.linkHandler.bind(this);
    }

    toggleNavGroup = navGroup => { this.setState({ [`isOpen${navGroup}`]: !this.state[`isOpen${navGroup}`] });  };

    toogleNavStyle = navGroup => { return {
        padding: 0,
        transform: navGroup ? 'rotate(0deg)' : 'rotate(-90deg)',
        transitionDuration: '0.3s',
        transitionProperty: 'transform',
    }};

    linkHandler(event) {
    
        if (window.innerWidth <= 425) { document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open'); }
        if (this.state.userData !== undefined) return true;

        event.preventDefault(); // prevent redirection to the page
        event.stopPropagation();

        this.notificationSystem.addNotification({
            title: <i className="fa fa-shield"></i>,
            message: 'Access Denied. Please login',
            level: 'info',
            position: 'br',
        });
    }

  render() { return (
        <aside className={bem.b('main-header')}>
            <div className={bem.e('content')}>
                <Navbar>
                    <Link to="/" className="navbar-brand d-flex">
                        <img src={logo200Image} className="pr-2" alt="" />
                    </Link>
                </Navbar>

                <Nav className="respo-sidebar">
                    
                    {adminOperations.map(({ to, name, exact, Icon }, index) => (
                        <NavItem key={index} className={bem.e('nav-item')}>
                            <BSNavLink tag={NavLink} to={to} exact={exact}
                                id={`navItem-${name}-${index}`}
                                activeClassName="active" className="text-uppercase"
                            >
                                <Icon className={bem.e('nav-item-icon')} />
                                <span className="">{name}</span>
                            </BSNavLink>
                        </NavItem>
                    ))}

                    <NavItem className={bem.e('nav-item')} onClick={() => this.toggleNavGroup('AdminOperation')}>
                        <BSNavLink className={bem.e('nav-item-collapse')}>
                            <div className="d-flex">
                                <span className=" align-self-start">Operation</span>
                            </div>
                            <MdKeyboardArrowDown
                                className={bem.e('nav-item-icon')}
                                style={this.toogleNavStyle(this.state.isOpenAdminOperation)}
                            ></MdKeyboardArrowDown>
                        </BSNavLink>
                    </NavItem>

                    <Collapse isOpen={this.state.isOpenAdminOperation}>
                        { adminOperationsMain.map(({ to, name, exact, Icon, src }, index) => (
                        <NavItem key={index} className={bem.e('nav-item')}>
                            <BSNavLink
                                id={`navItem-${name}-${index}`} to={to} onClick={this.linkHandler} exact={exact} tag={NavLink}
                                activeClassName="active"
                                className="text-uppercase" 
                            >
                                <Icon className={bem.e('nav-item-icon')} />
                                <span className="inner-menu">{name}</span>
                            </BSNavLink>
                        </NavItem>
                     ))}
                    </Collapse>
                    
                    <NavItem className={bem.e('nav-item')} onClick={() => this.toggleNavGroup('Operation')}>
                        <BSNavLink className={bem.e('nav-item-collapse')}>
                            <div className="d-flex">
                                <span className=" align-self-start">Manage Users</span>
                            </div>
                            <MdKeyboardArrowDown
                                className={bem.e('nav-item-icon')}
                                style={this.toogleNavStyle(this.state.isOpenOperation)}
                            ></MdKeyboardArrowDown>
                        </BSNavLink>
                    </NavItem>
                        
                    <Collapse isOpen={this.state.isOpenOperation}>
                        { manageUsers.map(({ to, name, exact, Icon, src }, index) => (
                        <NavItem key={index} className={bem.e('nav-item')}>
                            <BSNavLink
                                id={`navItem-${name}-${index}`} to={to} onClick={this.linkHandler} exact={exact} tag={NavLink}
                                activeClassName="active"
                                className="text-uppercase" 
                            >
                                <Icon className={bem.e('nav-item-icon')} />
                                <span className="inner-menu">{name}</span>
                            </BSNavLink>
                        </NavItem>
                     ))}
                    </Collapse>
                </Nav>

            <SidebarFooter></SidebarFooter>

            <NotificationSystem
                dismissible={true}
                ref={notificationSystem => (this.notificationSystem = notificationSystem) }
                style={NOTIFICATION_SYSTEM_STYLE}
            ></NotificationSystem>
        </div>
      </aside>
    );
  }
}

export default AdminSidebar;
