import React from 'react';
import { Navbar, Nav, NavItem } from 'reactstrap';

const Footer = () => {

const date = new Date(); 
  return (
    <Navbar>
      <Nav navbar>
        <NavItem>Copyright Qodrr.com © { date.getFullYear() } | <a href="https://qodrr.com/term-service.html" rel="noopener noreferrer" target="_blank">Terms & Condition</a>
        | <a href="https://qodrr.com/privacy-policy.html" rel="noopener noreferrer" target="_blank">Privacy Policy</a>
        </NavItem>
      </Nav>
    </Navbar>
  );
};

export default Footer;
