import React from 'react'
import store from 'store';
import 'assets/popover.css'

// @import-components ---------------------------------------------------------------------------------

    import { Link } from 'react-router-dom';
    import {  Button, ButtonGroup,Popover, PopoverBody, } from 'reactstrap';

//@import-icons ---------------------------------------------------------------------------------------

    import M1 from 'assets/img/icon/m1.svg';
    import M2 from 'assets/img/icon/m2.svg';
    import M3 from 'assets/img/icon/m3.svg';
    import M4 from 'assets/img/icon/m4.svg';
    import M5 from 'assets/img/icon/m5.svg';

// ----------------------------------------------------------------------------------------------------

// @static-components ---------------------------------------------------------------------------------

    const date = new Date();
    const todayDate = `${date.getUTCDate()}-${date.getUTCMonth() + 1}-${date.getUTCFullYear()}`
    const userDetails = store.get('user_data')

    const MountComponent = ({ data , children }) => {

        if(data.deploy_status !== 'NA') return <></>

        return <div className="col-md-12">{ children }</div>
    }


    const SecureComponent = ({ data , children }) => {

        if(userDetails.auth === 'S6E1') return children /* if user is admin return children */

        if(data.strategy_type === "PRIVATE" && userDetails.expiry_date >= todayDate) {

            return children /* if expiry date is greater than current date and strategy type is private */ 
        }

        else if(data.strategy_type === "PUBLIC") { 

            if(userDetails.public_strategies === undefined) return <React.Fragment></React.Fragment> /* if public strategy is undefined */ 

            if(userDetails.public_strategies.length === 0) return <React.Fragment></React.Fragment> /* if public strategy array is empty */ 

            const strategy = userDetails.public_strategies.filter((strategy) => strategy.code === data.original_code)[0]

                if(strategy === undefined) return <React.Fragment></React.Fragment>

                if(strategy.expiry_date >= todayDate) return children /* if expiry date is greater than current date  */ 
        } 

        return <React.Fragment></React.Fragment>
   }


// ----------------------------------------------------------------------------------------------------

const CustomPopover = (props) => { return (
   
    <React.Fragment>
        <div onClick={() => props.toggle(props.popoverTarget)}  className={ props.showPopover ? 'active-popover-wrapper' : 'inactive-popover-wrapper' }>
            <Popover  placement="left" isOpen={props.showPopover} target={props.popoverTarget} >
                <PopoverBody>
                    <ButtonGroup>
                        <div className="row">

                                <div className="col-md-12">
                                    <Button className="manage-btn" onClick={() => props.tradeSignal(Object.keys(props.data).length > 0 && props.data.code )} title="Check Current Signal">
                                        <img src={M1} alt="..." />&nbsp;
                                        <span>Check Current Signal</span>
                                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                    </Button>
                                </div>

                            {/* <SecureComponent data={props.data}> */}
                                <MountComponent data={props.data}>
                                    <Button className="manage-btn" onClick={() => props.deployStrategy(props.data)} title="Deploy Strategy">
                                        <img src={M2} alt="..." />&nbsp;
                                        <span>Deploy Strategy</span>
                                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                    </Button>
                                </MountComponent>
                            {/* </SecureComponent> */}

                            {  props.data.deploy_status !== "NA" &&
              
                                <div className="col-md-12">
                                    <Button className="manage-btn" onClick={() => props.unDeployStrategy(Object.keys(props.data).length > 0 && props.data.code )} title="Undeploy Strategy">
                                        <img src={M2} alt="..." />&nbsp;
                                        <span>Undeploy Strategy</span>
                                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                    </Button>
                                </div>
                            }

                                <MountComponent data={props.data}>
                                    <Button className="manage-btn" title="Update Strategy">
                                        <Link className="manage-link" to={{ pathname: '/build-strategy', search: '&st=' + escape(Object.keys(props.data).length > 0 && props.data.code ), }} >
                                            <img src={M5} alt="..." />&nbsp;
                                            <span>Update Strategy</span>
                                            <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                        </Link>
                                    </Button>
                                </MountComponent>
  
                                <MountComponent data={props.data}>
                                    <Button className="manage-btn" onClick={() => props.repair(Object.keys(props.data).length > 0 && props.data )} title="Repair Strategy">
                                        <img src={M3} alt="..." />&nbsp;
                                        <span>Repair Strategy</span>
                                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                    </Button>
                                </MountComponent>

                            {/* <SecureComponent data={props.data}>                  */}
                                <MountComponent data={props.data}>
                                    { (props.data.segment !== 'fut-opt' && props.data.segment !== 'opt') &&
                                    <Button className="manage-btn" onClick={() => props.handleBTOpen(props.data)} title="BackTest Strategy">
                                        <img src={M4} alt="..." />&nbsp;
                                        <span>Backtest Strategy</span>
                                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                    </Button>
                                    }
                                </MountComponent>
                            {/* </SecureComponent> */}

                                <div className="col-md-12">
                                    <Button className="manage-btn" onClick={() => props.strategyStats(props.data)} title="Check Strategy Stats">
                                        <img src={M1} alt="..." />&nbsp;
                                        <span>Check Performance</span>
                                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                    </Button>
                                </div>
                        </div>
                    </ButtonGroup>
                </PopoverBody>
            </Popover>
        </div>
    </React.Fragment>
    
  )}

export default CustomPopover