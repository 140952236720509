import React from "react"
import { FormGroup, Label, Input ,Col } from 'reactstrap';

const TimeField = (props) => {

    const style = {
        width: '100%',
        marginTop: '0.25rem',
        fontSize: '0.9em',
        color: '#AB0003',
        fontWeight: 'bold'
    }

    const hoursError   = props.name === 'start_time' ? props.state.start_time_hr.error : props.state.end_time_hr.error ? true : false ;
    const minutesError = props.name === 'start_time' ? props.state.start_time_mt.error : props.state.end_time_mt.error ? true : false ;
    const formatError  = props.name === 'start_time' ? props.state.start_time_fm.error : props.state.end_time_fm.error ? true : false ;

    const ErrorMessage = (time) => {

        if(time === 'start_time') {

            if(props.state.start_time_hr.error) return props.state.start_time_hr.errorMessage

            else if(props.state.start_time_mt.error) return props.state.start_time_mt.errorMessage

            else return props.state.start_time_fm.errorMessage
        }

        if(props.state.end_time_hr.error) return props.state.end_time_hr.errorMessage

        else if(props.state.end_time_mt.error) return props.state.end_time_mt.errorMessage

        else return props.state.end_time_fm.errorMessage
    }

    return (
        <React.Fragment>
            <Col md={3}>
                <FormGroup>
                    <Label>{props.label} </Label>
                    <div className="row"><div className="co-md-12 d-flex justify-content-around">
                            <Input onChange={(event) => props.method(event.target)} invalid={hoursError}   value={props.name === 'start_time' ? props.state.start_time_hr.value : props.state.end_time_hr.value  } name={props.name === 'start_time' ? 'start_time_hr' : 'end_time_hr' }  className="first-show w-25 ml-2" placeholder="00" />
                            <p style={{ paddingLeft: '8px', paddingTop: '10px' }}>:</p>
                            <Input onChange={(event) => props.method(event.target)} invalid={minutesError} value={props.name === 'start_time' ? props.state.start_time_mt.value : props.state.end_time_mt.value } name={props.name === 'start_time' ? 'start_time_mt' : 'end_time_mt' }  className="first-show w-25 ml-2" placeholder="00" />
                            <Input onChange={(event) => props.method(event.target)} invalid={formatError}  value={props.name === 'start_time' ? props.state.start_time_fm.value : props.state.end_time_fm.value } name={props.name === 'start_time' ? 'start_time_fm' : 'end_time_fm' }  className="first-show w-50 ml-2" type="select" >
                                <option>AM</option>
                                <option>PM</option>
                            </Input>        
                     </div></div>
                     <p style={style}>
                        { props.name === 'start_time' ? ErrorMessage('start_time') : ErrorMessage('end_time') }
                    </p>
                </FormGroup>
            </Col><Col md={1}></Col>
        </React.Fragment>
    )
}

export default TimeField