import React from 'react';
import store from 'store';


// @import-components ----------------------------------------------------------------------

  import {  AdminSidebar,Content,Footer,Header, Sidebar, SuperAdminSidebar } from 'components/Layout';
  import NotificationSystem from 'react-notification-system';
  import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';
  import Qodrr from 'hooks/QodrrContext';

// -----------------------------------------------------------------------------------------

class MainLayout extends React.Component {
  static isSidebarOpen() {
    return document
      .querySelector('.cr-sidebar')
      .classList.contains('cr-sidebar--open');
  }

  constructor(props) {
      super(props);

      this.state = {};
      this.state.auth = store.get('user_data') === undefined ? '' : store.get('user_data').auth;
      this.state.sideBar  = 'default-sidebar';
      this.state.userData = store.get('user_data')

  }

  componentDidUpdate({ breakpoint }) {
    if (breakpoint !== this.props.breakpoint) {
      this.checkBreakpoint(breakpoint);
    }
  }

  componentDidMount() {

      if(this.state.userData !== undefined) { this.context.updateConfig(this.state.userData); }
      if (this.state.auth.includes('S6E1')) { this.setState({ sideBar: 'super-admin-sidebar' }); }
      if (this.state.auth.includes('BSN6')) { this.setState({ sideBar: 'admin-sidebar' }); }

      this.checkBreakpoint(this.props.breakpoint);
  }

  // close sidebar when
  handleContentClick = event => {
    // close sidebar if sidebar is open and screen size is less than `md`
    if (
      MainLayout.isSidebarOpen() &&
      (this.props.breakpoint === 'xs' ||
        this.props.breakpoint === 'sm' ||
        this.props.breakpoint === 'md')
    ) {
      this.openSidebar('close');
    }
  };

  checkBreakpoint(breakpoint) {
    switch (breakpoint) {
      case 'xs':
      case 'sm':
      case 'md':
        return this.openSidebar('close');

      case 'lg':
      case 'xl':
      default:
        return this.openSidebar('open');
    }
  }

  openSidebar(openOrClose) {
    if (openOrClose === 'open') {
      return document
        .querySelector('.cr-sidebar')
        .classList.add('cr-sidebar--open');
    }
    document.querySelector('.cr-sidebar').classList.remove('cr-sidebar--open');
  }

  render() {
    return (
      <main className="cr-app bg-light">
        {this.state.sideBar === 'admin-sidebar' && (
          <AdminSidebar></AdminSidebar>
        )}
        {this.state.sideBar === 'default-sidebar' && <Sidebar></Sidebar>}
        {this.state.sideBar === 'super-admin-sidebar' && (
          <SuperAdminSidebar></SuperAdminSidebar>
        )}

        <Content fluid onClick={this.handleContentClick}>
          <Header></Header>
          <div className="main-layout">{this.props.children}</div>
          <Footer></Footer>
        </Content>

        <NotificationSystem
          dismissible={false}
          ref={notificationSystem =>
            (this.notificationSystem = notificationSystem)
          }
          style={NOTIFICATION_SYSTEM_STYLE}
        ></NotificationSystem>
      </main>
    );
  }
}

MainLayout.contextType = Qodrr

export default MainLayout;
