import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import StepHeading from '../StepHeading';

const StrategyParameters = props => {
  return (
    <React.Fragment>
      <StepHeading
        text="Strategy Parameter(s)"
        id="step-two-strategy-parameters"
        content="Define your strategy parameters.Select what kind of strategy will it be? Eg: formula based as RSI(14) CROSSES Above 65 BUY and CROSSES 35 Below SELL."
      ></StepHeading>
      <div className="row form-detail">
        <div className="col-md-4">
          <FormGroup check style={{paddingLeft:'0px !important'}}>
            <Label> Strategy Condition Type</Label>
            <div className="checker col-md-12">
              <div className="row">
                {/* <div className="col-md-6 mob-respo">
                                    <Input
                                        type="radio"
                                        name="strategy_loss_value"
                                        defaultValue="level_based_strategy"
                                        checked={props.strategyType === 'level_based_strategy'}
                                        onChange={() =>
                                            props.changeStrategyType('level_based_strategy')
                                        }
                                    />{' '}
                            <span>Level based strategy</span>
                        </div> */}
                <div className="col-md-10 mob-respo">
                  <Input
                    type="radio"
                    style={{ paddingLeft:'0px !important' }}
                    name="strategy_cnd_type"
                    defaultValue="formula_based_strategy"
                    checked={props.strategyType === 'formula_based_strategy'}
                    onChange={() =>
                      props.changeStrategyType('formula_based_strategy')
                    }
                  />{' '}
                  <span>Formula based strategy</span>
                </div>
              </div>
            </div>
          </FormGroup>
        </div>
      </div>
    </React.Fragment>
  );
};

export default StrategyParameters;
