import logo from 'assets/img/logo/logo.svg';
import React, { Component } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link, Redirect } from 'react-router-dom';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap';
import { tradeService } from 'services/trade_service';
import store from 'store';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formValues: {
        username: '',
        password: '',
      },
      loginError: '',
      captchaVal: '',
      loading: false, // will be true when ajax request is running
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.captchaValue = this.captchaValue.bind(this);
  }

  captchaValue(value) {
    this.setState({ captchaVal: value });
  }

  handleChange({ value, name }) {
    this.setState({ formValues: { ...this.state.formValues, [name]: value } });
  }

  async handleSubmit(event) {
    
    event.preventDefault();

    this.setState({ loading: true, loginError: '' });

    try {
      
      if (this.state.captchaVal === '' || this.state.captchaVal === null) {
          this.setState({
              loading: false,
              loginError: 'Please verify that you are not a robot.',
          });

        return true;
      }

      const response = await tradeService.authLogin(this.state.formValues['username'],this.state.formValues['password']);

      if (String(response.statusCode) === '200' && String(response.message) === 'SUCCESS') {

          const serverResponse = await tradeService.getIndicatorsList();
        
          if(serverResponse.statusCode === "200") {

              store.set('formulas_list',serverResponse.data, new Date().getTime() + 8 * 60 * 60 * 1000);
          }
        this.setState({ loading : false });
        this.props.history.push('/dashboard');
      } 
      else {
        this.setState({ loginError: 'Invalid Login Credentials !!' });
      }
    
    } catch (error) {
      
      this.setState({ loginError: 'An error occurred while connecting to API',});
      console.log('error occurred while calling API');
      console.log(error);
    }
  }

  render() {
    if (store.get('user_data') !== undefined)
      return <Redirect to="/dashboard"></Redirect>;

    return (
      // <LoadingOverlay
      //   active={this.state.loading}
      //   spinner={<BounceLoader />}
      //   text="Loading content..."
      //   styles={{ background:'orange' }}
      // >
      <div className="app flex-row align-items-center">
        <Container>
          <div className="login">
            <div className="login-page">
              <Row className="justify-content-center">
                <Col md="8" className="shade">
                  <CardGroup>
                    <Card className="p-4">
                      <CardBody>
                        <Form name="login-form" onSubmit={this.handleSubmit}>
                          <h1 className="text-center">Login</h1>
                          <p className="text-muted text-center">
                            Sign In to your account
                          </p>

                          {this.state.loginError && (
                            <Row>
                              <Alert color="danger">
                                {this.state.loginError}
                              </Alert>
                            </Row>
                          )}

                          <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fa fa-user"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="text"
                              name="username"
                              value={this.state.formValues.username}
                              onChange={e => this.handleChange(e.target)}
                              placeholder="Username"
                              required
                            />
                          </InputGroup>
                          <InputGroup className="mb-4">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fa fa-lock"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="password"
                              name="password"
                              autoComplete="on"
                              value={this.state.formValues['password']}
                              onChange={e => this.handleChange(e.target)}
                              placeholder="Password"
                              required
                            />
                          </InputGroup>

                          <Row>
                            <Col xs="6">
                              <ReCAPTCHA
                                sitekey="6Lf7V6kaAAAAAKpDJc1Fy177vNwzOOYZ73t1ze_M"
                                onChange={this.captchaValue}
                              />
                            </Col>
                          </Row>
                          <hr />
                          <Row>
                            <Col xs="5">
                              <Button
                                color="primary"
                                className="px-4 login-btn"
                              >
                                Login
                              </Button>
                            </Col>

                            <Col xs="7" className="text-right">
                              <Link to="/forgot-password">
                                <Button
                                  color="link"
                                  className="px-0 forgot-btn"
                                  active
                                  tabIndex={-1}
                                >
                                  Forgot password ?
                                </Button>
                              </Link>
                            </Col>
                          </Row>
                        </Form>
                      </CardBody>
                    </Card>
                    <Card className="text-white py-5 d-md-down-none back-log">
                      <CardBody className="text-left">
                        <div className="logo-center">
                          <img
                            src={logo}
                            alt="Logo"
                            className="img-avatar"
                            style={{ borderRadius: '0em' }}
                          />
                          <p className="font-weight-bold">
                            By Signing-In you agree to abide by Qodrr.com  
                            &nbsp;<a href="https://qodrr.com/privacy-policy.html" rel="noopener noreferrer" target="_blank">
                              <Button color="link" active className="px-0 forgot-btn" tabIndex={-1}>
                                Term & Services
                              </Button>
                              </a>
                            &nbsp;and it's 
                            <a href="https://qodrr.com/privacy-policy.html" rel="noopener noreferrer" target="_blank">
                              <Button color="link" active className="px-0 forgot-btn" tabIndex={-1}>
                                            Privacy Policy
                              </Button>
                              </a>
                          </p>
                          <div className="signup-detail">
                            <span>
                              Not have an account yet.{' '}
                              <a href="https://qodrr.com/pricing.html">
                                Click here to register.
                              </a>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </CardGroup>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
      // </LoadingOverlay>
    );
  }
}

export default Login;
