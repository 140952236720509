import React from 'react';
import * as validator   from 'validatorjs';

// @import-components && icons -------------------------------------------------------------------------

    import EntryPrice    from './strategyComponents/stepThree/EntryPrice';
    import StopLossPrice from './strategyComponents/stepThree/StopLossPrice';

// -----------------------------------------------------------------------------------------------------

const StepThree = (props) => {

  const toggleClass = props.activeStep === 'stepThree' ? 'step step3' : 'step step3 hidden'

  const [validationButton,toggleValidationButton] = React.useState(true);

  React.useEffect(() => { toggleValidationButton(true); return () => {} },[props.activeStep])
  React.useEffect(() => { 

    if(props.updateStrategy.status) updatestrategy(props.updateStrategy.payload);

  },[props.updateStrategy.status])


  // @states -----------------------------------------------------------------------------

      const initialState = {

        entrypoint_type  : { value: '', error: false, errorMessage: '' },
        entrypoint_value : { value: '', error: false, errorMessage: '' },

          sl_type      : { value: '', error: false, errorMessage: '' },
          sl_value     : { value: '', error: false, errorMessage: '' },
          target_type  : { value: '', error: false, errorMessage: '' },
          target_value : { value: '', error: false, errorMessage: '' },
      }

      const [localStates,updateStates] = React.useState(initialState)
      
      const updatestrategy = (data) => { 

          updateStates((state) => {

              state.entrypoint_type.value  = data.entrypoint === undefined ? state.entrypoint_type.value : data.entrypoint.type
              state.entrypoint_value.value = data.entrypoint === undefined ? state.entrypoint_value.value : data.entrypoint.value

              state.sl_type.value      = data.exitpoint === undefined ? state.sl_type.value      : data.exitpoint.type_sl
              state.sl_value.value     = data.exitpoint === undefined ? state.sl_value.value     : data.exitpoint.value_sl
              state.target_type.value  = data.exitpoint === undefined ? state.target_type.value  : data.exitpoint.type_profit
              state.target_value.value = data.exitpoint === undefined ? state.target_value.value : data.exitpoint.value_profit
 
              return state;
          })
      }  

  // @custom-validation ---------------------------------------------------------------------------------------

      const validationData = {

        entrypoint_type  : localStates.entrypoint_type.value, 
        entrypoint_value : localStates.entrypoint_value.value,   

          sl_type      : localStates.sl_type.value, 
          sl_value     : localStates.sl_value.value, 
          target_type  : localStates.target_type.value,
          target_value : localStates.target_value.value,
      };

      const validationRules = { 

        entrypoint_type  : 'required', 
        entrypoint_value : 'required|numeric',

          sl_type      : 'required',
          sl_value     : 'required|numeric',
          target_type  : 'required',
          target_value : 'required|numeric',
      };

      const errorMessages = {
        "required.entrypoint_type"  : "Please describe entry point type",
        "required.entrypoint_value" : "Please describe entry point value for default eg: 0",

        "required.sl_type"      : "Please describe stop loss type",
        "required.sl_value"     : "Please describe stop loss value for default eg: 0",
        "required.target_type"  : "Please describe target type",
        "required.target_value" : "Please describe target value for default eg: 0",
      }

      const formValidation = () => {

          const validation = new validator(validationData, validationRules,errorMessages);
          const userState  = { ...localStates }
          const validationResponse = { submitStatus : true, postData : validationData  }

            if(validation.fails()) validationResponse.submitStatus = false;

              for (const name in userState) { 

                userState[name].error = validation.errors.get(name).length > 0 ? true : false;
                userState[name].errorMessage = validation.errors.first(name) || '' 
              }  
          
          updateStates({ ...userState }) // update errors and error messages //

        return validationResponse;
      }

  // @methods---------------------------------------------------------------------------------------------------

      const changeHandler = ({ name , value }) => { 
        
          updateStates({ ...localStates,[name]:{ value : value , error: false, errorMessage: '' } })  
      }

      const submitHandler = () => {

        const { submitStatus , postData  } = formValidation();

        if(submitStatus === false) return false

          /* generate payload data ----------------------------------------------------------------------------------------------------------------- */

            const payload = {};

              payload.exitpoint  = { 
                  type_sl      : postData.sl_type,
                  value_sl     : postData.sl_value,
                  type_profit  : postData.target_type,
                  value_profit : postData.target_value
              }

              payload.entrypoint = { type: postData.entrypoint_type, value: postData.entrypoint_value }

            props.stepValidator('stepThree','stepThreeValidationComplete',payload); // send data to main function

        toggleValidationButton(false) // hide validation button of step one
    }

    /* --------------------------------------------------------------------------------------------------------------------------------------------- */

    
  return (
    <div className={toggleClass}><div className="step-three">
        <EntryPrice    
            changeHandler={changeHandler} 
            state={localStates}
        ></EntryPrice>

        <StopLossPrice 
            changeHandler={changeHandler} 
            state={localStates}
        ></StopLossPrice>

        <div className="button-group">
            <button id="previous" className="button" type="button" onClick={(e) => props.previous(e)} >Back</button>
            { validationButton && <button id="next" className="button" type="button" onClick={submitHandler}>Next</button> }
        </div><br></br>

    </div></div>
  );
};

export default StepThree;
