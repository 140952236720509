
import React from 'react';
import Select from 'react-select';
import { FormGroup, Label , Col } from 'reactstrap';

const SearchSelectFields = (props) => {

    const style = {
        width: '100%',
        marginTop: '0.25rem',
        fontSize: '0.9em',
        color: '#AB0003',
        fontWeight: 'bold'
    }

    const selectStyles = {
        control: (base, state) => ({
                ...base,
            background: "#fff",
            borderRadius: state.isFocused ? "10px !important" : "10px !important",
            border:((props) => {

                if(props.error === true) { return "2.5px solid #ab0003 !important"; }

                return "1px solid #ced4da !important"

            })(props),

            boxShadow: ((state,props) => {

                if(state.isFocused === true && props.error === true) return '0 0 0 0.2rem rgb(248 80 50 / 25%) !important'

                else if (state.isFocused === true) return '0 0 0 0.2rem rgb(106 130 251 / 25%) !important'

                return null

            })(state,props)

        })
    };

    const changeHandler = (allOptions,info) =>  props.method({ name : info.name , value : allOptions })

    return(
      <Col md={props.md}>
          <FormGroup>
              <Label htmlFor={props.id}>{props.label}</Label>
                <Select className="first-show" onChange={changeHandler} closeMenuOnSelect={true}
                        options={props.data} value={props.value} name={props.name} isMulti
                        styles={selectStyles}
                ></Select>
                <p style={style}>{ props.error === true && props.errorMessage }</p>
          </FormGroup>
        </Col>
    )
}

export default SearchSelectFields