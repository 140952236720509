import wallet from 'assets/img/icon/wallet.svg';
import React from 'react';

// @static-components ----------------------------------------------------------------------

  const MaxLoosingTrade = (props) => {
    return (
      <div className="row">
          <div className="col-md-8 mob-acc"><div className="chart-data">
              <div className="icons"><div className="prog-5"></div></div>
              <div className="state">
                  <span>Max Loosing trade</span>
              </div>
          </div></div>

          <div className="col-md-4 mob-a"><div className="num">
              <span style={{ color: 'red' }}>
                  <i className="fa fa-inr"></i>{props.info.max_loss}
              </span>
          </div></div>
      </div>
    )
  }

  const LossingTrade = (props) => {
    return (
      <div className="row">
          <div className="col-md-8 mob-acc"><div className="chart-data">
              <div className="icons text-danger"><div className="prog-4"></div></div>
              <div className="state text-danger">
                  <span>Loosing trade</span>
              </div>
          </div></div>

          <div className="col-md-4 mob-a"><div className="num">
              <span style={{ color: 'red' }}>{props.info.no_l_trade}</span>
          </div></div>
      </div>
    )
  }

  const MaxProfitTrade = (props) => {
    return (
      <div className="row">
          <div className="col-md-8 mob-acc"><div className="chart-data">
              <div className="icons"><div className="prog-3"></div></div>
              <div className="state">
                  <span>Max Profitable trade</span>
              </div>
          </div></div>

          <div className="col-md-4 mob-a"><div className="num text-success">
              <span><i className="fa fa-inr"></i>{props.info.max_win}</span>
          </div></div>
      </div>
  )}

  const WiningTrade = (props) => {
    return (
      <div className="row">
          <div className="col-md-8 mob-acc"><div className="chart-data">
              <div className="icons"><div className="prog-2"></div></div>
              <div className="state">
                  <span>Winning trade</span>
              </div>
          </div></div>

          <div className="col-md-4 mob-a"><div className="num">
              <span>{props.info.no_w_trade}</span>
          </div></div>
      </div>
  )}

// -----------------------------------------------------------------------------------------

const AccountInfo = props => {
  return (
    <React.Fragment>
      <div className="col-md-5 h-50">
              <div className="chart-detail">
                  
            <div className="top-account"><div className="row">
                <div className="col-md-6 mob-respo"><div className="head">
                    <h5>Account Information</h5>
                </div></div>
                <div className="col-md-6 mob-respo"><div className="right-head">
                    <p><span>{props.info.no_trade}</span> Total trade</p>
                </div></div>
            </div></div>

          <div className="wrap-info">
            <div className="chart-head">
              <div className="row">
                <div className="col-md-8">
                  <div className="c-head">
                    <div className="icon-wallet">
                      <img src={wallet} alt="..." />
                    </div>
                    <div className="chart-rate">
                      <h2>
                        <i className="fa fa-inr"></i>
                        {props.info.pnl}
                      </h2>
                    </div>
                    <div className="c-text">
                      <span>Current P&L</span>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="chart-ratio">
                    <div className="ratio-no">
                      <span>{props.info.win_ratio}%</span>
                    </div>
                    <div className="ratio-win">
                      <span>Win Ratio</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="progress">
                <div className="p-one prog"></div>
                <div className="p-two prog"></div>
                <div className="p-three prog"></div>
                <div className="p-four prog"></div>
                <div className="p-five prog"></div>
            </div>

            <div className="chart-details"><div className="chart-d">
                <ul>
                  <li><WiningTrade {...props}></WiningTrade></li>
                  <li><MaxProfitTrade {...props }></MaxProfitTrade></li>
                  <li><LossingTrade {...props }></LossingTrade></li>
                  <li><MaxLoosingTrade { ...props }></MaxLoosingTrade></li>
                </ul>
            </div></div>            
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AccountInfo;
