// @import-components && logos ------------------------------------------------------------------
import logo200Image from 'assets/img/logo/logo.svg';
// import Link from 'components/Link';
import SidebarFooter from 'components/SidebarFooter';
import icons from 'data/sidebarIcons';
import React from 'react';
import { BsClipboardData, BsGraphUp } from 'react-icons/bs';
import { FaBriefcase } from 'react-icons/fa';
import { GoGraph } from 'react-icons/go';
import { IoMdBuild } from 'react-icons/io';
import {
  MdDashboard,
  MdGraphicEq,
  MdKeyboardArrowDown,
  MdPayment,
  MdSubscriptions,
} from 'react-icons/md';
import { SiGoogleanalytics, SiMarketo } from 'react-icons/si';
import NotificationSystem from 'react-notification-system';
import { Link, NavLink } from 'react-router-dom';
import {
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import store from 'store';
// -----------------------------------------------------------------------------------------------
// @import-hooks && helpers ----------------------------------------------------------------------
import bn from 'utils/bemnames';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';

// -----------------------------------------------------------------------------------------------

// @sidebar-menu-links && groups -----------------------------------------------------------------

const navOperation = [
  {
    to: '/build-strategy',
    name: 'Build Strategy',
    exact: false,
    Icon: IoMdBuild,
    src: icons.operationBuildStrategy,
  },
  {
    to: '/manage-strategy',
    name: 'Your Strategies',
    exact: false,
    Icon: SiGoogleanalytics,
    src: icons.operationManageStrategy,
  },
  {
    to: '/trade-logs',
    name: 'Trade Log(s)',
    exact: false,
    Icon: BsClipboardData,
    src: icons.operationTradeLog,
  },
];

const navScreeners = [
  {
    to: '/convergence-status',
    name: 'Convergence Screener',
    exact: false,
    Icon: BsGraphUp,
  },
  {
    to: '/rsi-screener',
    name: 'RSI Screener',
    exact: false,
    Icon: GoGraph,
  },
  {
    to: '/coi-screener',
    name: 'Open Interest Screener',
    exact: false,
    Icon: MdGraphicEq,
  },
];

const navFirst = [
  {
    to: '/marketplace',
    name: 'Marketplace',
    exact: false,
    Icon: SiMarketo,
    src: icons.operationMarketPlace,
  },
];

const navSubscription = [
  {
    to: '/membership-plans',
    name: 'Membership Plans',
    exact: false,
    Icon: MdSubscriptions,
  },
  {
    to: '/manage-subscription',
    name: 'Subscription Details',
    exact: false,
    Icon: MdSubscriptions,
  },
  {
    to: '/payment-history',
    name: 'Payment History',
    exact: false,
    Icon: MdPayment,
  },
];

const navConfiguration = [
  {
    to: '/broker-configuration',
    name: 'Configure Broker(s)',
    exact: false,
    Icon: FaBriefcase,
  },
];
const navResources = [
  {

    to: '/quick-resources',
    name: 'Quick Resources',
    exact: false,
    Icon: FaBriefcase,
  },
  {
    to: '/help-center',
    name: 'Help Center',
    exact: false,
    Icon: FaBriefcase,
  },
  {
    to: '/contact-us',
    name: 'Contact Us',
    exact: false,
    Icon: FaBriefcase,
  },
];
const navBackoffice = [
  {
    to: '/admin-ops',
    name: 'Admin Ops',
    exact: false,
    Icon: FaBriefcase,
  },
  {
    to: '/contact-us',
    name: 'Manage Users',
    exact: false,
    Icon: FaBriefcase,
  },
  {
    to: '/contact-us',
    name: 'Add Users',
    exact: false,
    Icon: FaBriefcase,
  },
];
const navItems = [
  { to: '/dashboard', name: 'dashboard', exact: true, Icon: MdDashboard },
];

// -----------------------------------------------------------------------------------------------

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenComponents: true,
      isOpenContents: true,
      isOpenPages: true,
      userData: store.get('user_data'),
    };

    this.style = {
      padding: 0,
      transform: this.state.isOpenFirst ? 'rotate(0deg)' : 'rotate(-90deg)',
      transitionDuration: '0.3s',
      transitionProperty: 'transform',
    };

    this.linkHandler = this.linkHandler.bind(this);
  }

  toggleNavGroup = navGroup => {
    this.setState({ [`isOpen${navGroup}`]: !this.state[`isOpen${navGroup}`] });
  };

  toogleNavStyle = navGroup => {
    return {
      padding: 0,
      transform: navGroup ? 'rotate(0deg)' : 'rotate(-90deg)',
      transitionDuration: '0.3s',
      transitionProperty: 'transform',
    };
  };

  linkHandler(event,name = undefined) {

    if(name !== undefined && name === '/help-center') { 
      
        event.preventDefault();

        return window.open('https://www.help.qodrr.com/', '_blank', 'noopener,noreferrer');
    }


    if (window.innerWidth <= 425) {
      document
        .querySelector('.cr-sidebar')
        .classList.toggle('cr-sidebar--open');
    }

    if (this.state.userData !== undefined) return true;

    event.preventDefault(); // prevent redirection to the page

    event.stopPropagation();

    this.notificationSystem.addNotification({
      title: <i className="fa fa-shield"></i>,
      message: 'Access Denied. Please login',
      level: 'info',
      position: 'br',
    });
  }

  render() {
    return (
      <aside className={bem.b('main-header')}>
        <div className={bem.e('content')}>
          <Navbar>
            <Link to="/" className="navbar-brand d-flex">
              <img src={logo200Image} className="pr-2" alt="" />
            </Link>
          </Navbar>

          <Nav className="respo-sidebar">
            {navItems.map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e('nav-item')}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  className="text-uppercase"
                  tag={NavLink}
                  to={to}
                  activeClassName="active"
                  exact={exact}
                >
                  <Icon className={bem.e('nav-item-icon')} />
                  <span className="">{name}</span>
                </BSNavLink>
              </NavItem>
            ))}

            <NavItem
              className={bem.e('nav-item')}
              onClick={() => this.toggleNavGroup('First')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <span className=" align-self-start">Explore Strategies</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={this.toogleNavStyle(this.state.isOpenFirst)}
                ></MdKeyboardArrowDown>
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenFirst}>
              {navFirst.map(({ to, name, exact, Icon, src }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    to={to}
                    onClick={this.linkHandler}
                    tag={NavLink}
                    exact={exact}
                    className="text-uppercase"
                    activeClassName="active"
                  >
                    {/* <img src={src} alt="..." /> */}

                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="inner-menu">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>

            <NavItem
              className={bem.e('nav-item')}
              onClick={() => this.toggleNavGroup('Operation')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <span className=" align-self-start">Manage Strategies</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={this.toogleNavStyle(this.state.isOpenOperation)}
                ></MdKeyboardArrowDown>
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenOperation}>
              {navOperation.map(({ to, name, exact, Icon, src }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    to={to}
                    onClick={this.linkHandler}
                    exact={exact}
                    activeClassName="active"
                    className="text-uppercase"
                    tag={NavLink}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    {/* <img src={src} alt="..." /> */}
                    <span className="inner-menu">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>

            <NavItem
              className={bem.e('nav-item')}
              onClick={() => this.toggleNavGroup('Screeners')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <span className=" align-self-start">Screeners</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={this.toogleNavStyle(this.state.isOpenScreeners)}
                ></MdKeyboardArrowDown>
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenScreeners}>
              {navScreeners.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    to={to}
                    onClick={this.linkHandler}
                    exact={exact}
                    activeClassName="active"
                    className="text-uppercase"
                    tag={NavLink}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="inner-menu">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>

            <NavItem
              className={bem.e('nav-item')}
              onClick={() => this.toggleNavGroup('Subscription')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <span className=" align-self-start">
                    Subscription & Payment
                  </span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={this.toogleNavStyle(this.state.isOpenSubscription)}
                ></MdKeyboardArrowDown>
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenSubscription}>
              {navSubscription.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    to={to}
                    onClick={this.linkHandler}
                    exact={exact}
                    activeClassName="active"
                    className="text-uppercase"
                    tag={NavLink}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="inner-menu">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>

            <NavItem
              className={bem.e('nav-item')}
              onClick={() => this.toggleNavGroup('Configuration')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <span className=" align-self-start">
                    Account Configuration
                  </span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={this.toogleNavStyle(this.state.isOpenConfiguration)}
                ></MdKeyboardArrowDown>
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenConfiguration}>
              {navConfiguration.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    to={to}
                    onClick={this.linkHandler}
                    exact={exact}
                    activeClassName="active"
                    className="text-uppercase"
                    tag={NavLink}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="inner-menu">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>
        
            <NavItem
              className={bem.e('nav-item')}
              onClick={() => this.toggleNavGroup('Resources')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <span className=" align-self-start">Resources</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={this.toogleNavStyle(this.state.isOpenResources)}
                ></MdKeyboardArrowDown>
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenResources}>
              {navResources.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    to={to}
                    onClick={(event) => {this.linkHandler(event,to)}}
                    exact={exact}
                    activeClassName="active"
                    className="text-uppercase"
                    tag={NavLink}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="inner-menu">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>
          </Nav>

          <SidebarFooter></SidebarFooter>

          <NotificationSystem
            dismissible={true}
            ref={notificationSystem =>
              (this.notificationSystem = notificationSystem)
            }
            style={NOTIFICATION_SYSTEM_STYLE}
          ></NotificationSystem>
        </div>
      </aside>
    );
  }
}

export default Sidebar;
