import React from 'react'
import StepHeading   from 'components/BuildStrategies/strategyComponents/StepHeading'
import FormDetails   from 'components/BuildStrategies/strategyComponents/FormDetails'
import TextField     from 'components/BuildStrategies/strategyComponents/TextField'
import DropDownField from 'components/BuildStrategies/strategyComponents/DropDownField'


const RestrictedStep = (props) => { 

    // @declare-states -----------------------------------------------------------------------------------------------------------

        const initialState = {
            strategy_name : { value: props.updateStrategy.payload.strategy_name, error: false, errorMessage: '' },
            broker        : { value: props.updateStrategy.payload.broker,        error: false, errorMessage: '' },
        }

        const [localStates,updateStates] = React.useState(initialState)

        const toggleClass = props.activeStep === 'stepFive' ? 'step step1 hidden' : 'step step1'

    // ---------------------------------------------------------------------------------------------------------------------------

        const changeHandler = ({ name , value }) => { 

            if(value === '') return false;
        
            updateStates({ ...localStates,[name]:{ value : value , error: false, errorMessage: '' } })  
        }

        const submitHandler = () => { 
         
            /* generate payload ------------------------------------------------------ */

                const payload = { 
                    stepOne   : {},
                    stepTwo   : {},
                    stepThree : {},
                    stepFour  : {},
                }

                payload.stepOne = { ...props.updateStrategy.payload }

                payload.stepOne.broker = localStates.broker.value;

                props.submitBuildStrategy(payload); /* submit strategy details */

        }

        const brokers = ((brokers) => { 
    
            if(Object.keys(props.brokers).length === 0) return [] 
        
            return Object.keys(props.brokers).map((data,idx) => { return { label:brokers[data].label,value:brokers[data].key } });
          
        })(props.brokers)

    
    // ---------------------------------------------------------------------------------------------------------------------------

    return (
        <div className={toggleClass}><div className='step-one'>
            <StepHeading text="Strategy details" content="Specify Strategy Name, which should not be more than 10 character and can contain underscore(_) or dash(-). Pick a broker, where you wants to place the order."  id="strategy-details"></StepHeading>     
            <FormDetails>
                <TextField     method={() => {}}      value={localStates.strategy_name.value} error={localStates.strategy_name.error} errorMessage={localStates.strategy_name.errorMessage} name="strategy_name" md={6} label="Strategy Name"></TextField>
                <DropDownField method={changeHandler} value={localStates.broker.value}        error={localStates.broker.error}        errorMessage={localStates.broker.errorMessage}        name="broker"        md={6} label="Broker" data={brokers}></DropDownField>
            </FormDetails>
            <div className="button-group">
                <button id="next" className="button" type="button" onClick={() => submitHandler()}>Submit</button> 
            </div>
        </div></div>
  )}

export default RestrictedStep