import Profile from 'assets/img/users/profiles.svg';
import React from 'react';
// @import-components && logos ------------------------------------------------------------------
import { Link, useHistory } from 'react-router-dom';
import store from 'store';

// ----------------------------------------------------------------------------------------------

const UserProfile = ({ logout, data }) => {
  return (
    <React.Fragment>
      <div className="profile-name">
        <h3>Profile</h3>
      </div>
      <div className="profile-details">
        <div className="pro-img">
          <img src={Profile} alt="..." />
        </div>
        <div className="pro-detail">
          <h5>{data.username}</h5>
          <Link to="/profile" className="btn btn-group">
            View Profile
          </Link>
        </div>
      </div>
      <Link to="/" onClick={logout}>
        <div className="logout-btn">
          <i className="fa fa-sign-out"></i>&nbsp;Log out
        </div>{' '}
      </Link>
    </React.Fragment>
  );
};

const LoginProfile = () => {
  return (
    <React.Fragment>
      <div className="logout-btn">
        <Link to="/login">
          <i className="fa fa-sign-out"></i>&nbsp;Login
        </Link>
      </div>
    </React.Fragment>
  );
};

const SidebarFooter = () => {
  const history = useHistory();
  const userDaga = store.get('user_data');

  const handleLogout = event => {
    event.preventDefault();

    store.clearAll(); // clear store js data aka local storage

    history.push('/login'); // redirect to login page
  };

  return (
    <div className="profile">
      {userDaga === undefined && <LoginProfile></LoginProfile>}

      {userDaga !== undefined && (
        <UserProfile logout={handleLogout} data={userDaga}></UserProfile>
      )}

      <div className="version-head">
        <h5>Version 2.0</h5>
      </div>
    </div>
  );
};

export default SidebarFooter;
