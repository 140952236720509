import React from 'react';
import { Col, FormGroup, Input, Label } from 'reactstrap';


// @methods ------------------------------------------------------------------------------------------

  const allowPublicStrategy = (userDetails) => {
    
      if(userDetails === undefined) { userDetails = { auth : '', user_subscription : '' } }

      const todayDate = ((date) => {
          
          const month = String(date.getUTCMonth() + 1);
          const day = String(date.getUTCDate())
          
        return `${date.getUTCFullYear()}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
        
      })(new Date())

      if(userDetails.user_subscription === undefined) return false

      if(userDetails.auth === 'S6E1') return true /* if logged in user is admin */ 

      if(userDetails.user_subscription.includes('WIZARD_') || userDetails.user_subscription.includes('MASTER_')){

        /* if user membership is one of master of wizard */

          if(userDetails.expiry_date >= todayDate) return true 
      }

      return false
  }

// @static-components --------------------------------------------------------------------------------

  const PublicStrategy = (props) => { 

    if(allowPublicStrategy(props.userDetails) === false) return <></>

    return (
      <div className="col-md-6 mob-respo align-right">
          <Input
              type="radio"
              onChange={() => props.changeStrategyType('public_strategy')}
              checked={props.strategyType === 'public_strategy'}
              name="strategy_type"
              defaultValue="public_strategy"
          />{' '}
          <span>Public</span>
      </div>
  )}

  const PrivateStrategy = (props) => {
    return (
      <div className="col-md-6 mob-respo">
          <Input
              type="radio"
              onChange={() => props.changeStrategyType('private_strategy')}
              checked={props.strategyType === 'private_strategy'}
              name="strategy_type"
              defaultValue="private_strategy"
          />{' '}
          <span>Private</span>
      </div>
    )}


//----------------------------------------------------------------------------------------------------

const SelectStrategyType = props => {
  return (
    <React.Fragment>
        <Col md={4}>
            <FormGroup check>
                <Label>Select Strategy type</Label>
                <div className="checker col-md-12"><div className="row">
                    <PrivateStrategy {...props}></PrivateStrategy>
                    <PublicStrategy  {...props}></PublicStrategy>
                </div></div>
            </FormGroup>
      </Col>
    </React.Fragment>
  )};

export default SelectStrategyType;
