import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText,Row } from 'reactstrap';
import {tradeService} from 'services/trade_service'
import LoadingOverlay from 'react-loading-overlay';
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from 'react-router-dom';
import logo from 'assets/img/logo/logo.svg';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';





class ForgotPassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formValues   : { username : '' , email : '' },
      errorMessage : '',
      captchaVal   : '',
      loader       : false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.captchaValue = this.captchaValue.bind(this);
  }


  captchaValue(value) { this.setState({captchaVal:value}) }

  handleChange({name , value}) { this.setState({ formValues : { ...this.state.formValues , [name] : value } }) }

  async handleSubmit(event) {
  
      event.preventDefault(); this.setState({ loader : true }); const notification = { message : '' }

      try {

          if(this.state.captchaVal === "" || this.state.captchaVal === null) throw new Error('Please verify that you are not a robot.')

            const serverResponse = await  tradeService.forgotPassword(this.state.formValues.username,this.state.formValues.email)

              if (serverResponse.statusCode === "200" && serverResponse.message === "SUCCESS") notification.message = "Your password is changed, Its sent to your email address"

              else throw new Error('User Details Not Matched');
      
      } catch (error) { notification.message = error.message }
    

      this.setState({ loader : false });
      this.notificationSystem.addNotification({
          title: <i className="fa fa-shield"></i>,
          message: notification.message,
          level: 'info',
          position: 'br',
      });
  }

    render() { return (

        <LoadingOverlay active={this.state.loader} spinner text="Loading content...">
            <div className="app flex-row align-items-center">
                <Container>
                    <div className="login">
              <div className="login-page">
                <Row className="justify-content-center">
                  <Col md="8" className="shade">
                    <CardGroup>
                      
                      
                <Card className="p-4">
                    <CardBody>
                        <Form name="login-form" onSubmit={this.handleSubmit}>
                            <h2 className="text-center">Forgot Password</h2>
                            <p className="text-muted text-center">Retrieve Your Account Password</p>
  
                            <InputGroup className="mb-3">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText className="forgot-password-icon"><i className="fa fa-user"></i></InputGroupText>
                                </InputGroupAddon>
                                <Input name="username" className="forgot-password-input" type="text" required placeholder="Enter your username"
                                    value={this.state.formValues.username} 
                                    onChange={e => this.handleChange(e.target)}
                                ></Input>
                            </InputGroup>

                            <InputGroup className="mb-4">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText className="forgot-password-icon"><i className="fa fa-envelope"></i></InputGroupText>
                                </InputGroupAddon>
                                <Input type="email" name="email" className="forgot-password-input" autoComplete="on" placeholder="Enter your Email" required
                                    value={this.state.formValues.email}
                                    onChange={e => this.handleChange(e.target)}
                                ></Input>
                            </InputGroup>
  
                            <Row>
                                <Col xs="6">
                                    <ReCAPTCHA sitekey="6Lf7V6kaAAAAAKpDJc1Fy177vNwzOOYZ73t1ze_M" onChange={this.captchaValue} />
                                </Col>
                            </Row><hr />
                            
                            <Row>
                                <Col xs="5">
                                    <Button color="primary" className="px-4 login-btn">Login</Button>
                                </Col>
  
                                <Col xs="7" className="text-right">
                                    <Link to="/login">
                                        <Button color="link" active className="px-0 forgot-btn" tabIndex={-1}>
                                            Click Here to login ?
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>
                        </Form>
                    </CardBody>
                </Card>
                      
                <Card className="text-white py-5 d-md-down-none back-log">
                    <CardBody className="text-left">
                        <div className="logo-center">
                            <img src={logo} alt="Logo" className="img-avatar" style={{ borderRadius: '0em' }} />
                            <p className="font-weight-bold">
                            By Accessing Qodrr App you agree to abide by Qodrr.com  
                            &nbsp;<a href="https://qodrr.com/privacy-policy.html" rel="noopener noreferrer" target="_blank">
                              <Button color="link" active className="px-0 forgot-btn" tabIndex={-1}>
                                Term & Services
                              </Button>
                              </a>
                            &nbsp;and it's &nbsp;
                            <a href="https://qodrr.com/privacy-policy.html" rel="noopener noreferrer" target="_blank">
                              <Button color="link" active className="px-0 forgot-btn" tabIndex={-1}>
                                            Privacy Policy
                              </Button>
                              </a>
                          </p>
                          </div>
                    </CardBody>
                </Card>

                <NotificationSystem
                    dismissible={true}
                    ref={notificationSystem => (this.notificationSystem = notificationSystem)}
                    style={NOTIFICATION_SYSTEM_STYLE}
                ></NotificationSystem>
                   
                   
                    </CardGroup>
                  </Col>
                </Row>
              </div>
            </div>
          </Container>
        </div>
         </LoadingOverlay>
      );
  }
}

export default ForgotPassword
