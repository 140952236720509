
import React from 'react';
import 'assets/buildStrategies.css';
import store from 'store';
import LoadingOverlay from 'react-loading-overlay';
import parseQueryString from 'query-string';

// @import-page-components-----------------------------------------------------------

import StepOne    from 'components/BuildStrategies/StepOne';
import StepTwo    from 'components/BuildStrategies/StepTwo';
import StepThree  from 'components/BuildStrategies/StepThree';
import StepFour   from 'components/BuildStrategies/StepFour'
import StepFive   from 'components/BuildStrategies/StepFive';

import RestrictedStep from 'components/BuildStrategies/RestrictedStep';

import Header from 'components/BuildStrategies/Header';
import Footer from 'components/BuildStrategies/Footer';

// ----------------------------------------------------------------------------------

// @import-dependencies -------------------------------------------------------------

    import { tradeService } from 'services/trade_service';
    import * as Brokers from 'data/brokers.json';

    import Qodrr  from 'hooks/QodrrContext'

    LoadingOverlay.propTypes = undefined // prevent overlay error

// ----------------------------------------------------------------------------------

class BuildStrategy extends React.Component {

  static contextType = Qodrr

  constructor(props) {
      super(props);

      this.userData = store.get('user_data');
      this.brokers = Brokers.default
      this.initialStepsState = {
        activeStep : 'stepOne', currentStep: 'stepOne', nextStep: 'stepTwo',previousStep:undefined,
        steps: ['stepOne','stepTwo','stepThree','stepFour','stepFive'],
        nextStepAccess:false,
        stepOneValidationComplete:false,
        stepTwoValidationComplete:false,
        stepThreeValidationComplete: false,
        stepFourValidationComplete: false,
        index:0,
        status:false,
        responseMessage:'Your strategy is been successfully created!',
        loader  : false,
      }

      this.payload = { stepOne: {},stepTwo: {} , stepThree:{} , stepFour: {} }
      this.state = {
          auth     : undefined,
          updateID : undefined,
          steps : { ...this.initialStepsState },
          mountSteps:false,
          symbolData: [],
          indicators: [],
          userData  : {},
          updateStrategy:{ status : false , payload : {} },
          brokers : (() => {
               
            if(store.get('brokers_list') === undefined) return {}

            return store.get('brokers_list');
          })(),

          formulas:(() => {
               
            if(store.get('formulas_list') === undefined) return {}

            return store.get('formulas_list');
          })()
      };

      
    this.submitBuildStrategy = this.submitBuildStrategy.bind(this);
    this.nextStep     = this.nextStep.bind(this)
    this.previousStep = this.previousStep.bind(this);

    this.redirectToDashboard = this.redirectToDashboard.bind(this)
    this.resetSteps          = this.resetSteps.bind(this)
    this.stepValidator       = this.stepValidator.bind(this);

  }



  componentDidMount() { 

    if(this.userData === undefined) this.props.history.push('/login');

    if(store.get('brokers_list') === undefined){ this.getBrokersList(); }

    if(store.get('formulas_list') === undefined){ this.getFormulasList(); }

      this.loadSymbols('future'); this.loadIndicators(); this.forceUpdate();

      const strategy = parseQueryString.parse(this.props.location.search).st

      if(strategy !== undefined) this.getUserStrategy({username: this.userData.username, code: strategy }); // fetch user existing strategy

      else this.setState({ mountSteps : true });
  }

  
  // @api-call --------------------------------------------------------------------------------------------------------------------------------
     
      async getFormulasList(){

          try {

            const serverResponse = await tradeService.getIndicatorsList();
        
            if(serverResponse.statusCode !== "200" || serverResponse.data === undefined) throw Error('');
    
            if(typeof serverResponse.data !== 'object') throw Error('');

              store.set('formulas_list',serverResponse.data, new Date().getTime() + 8 * 60 * 60 * 1000);
    
              this.setState({ formulas : serverResponse.data });

              this.loadIndicators(); // update the indicator select array after api call
          
         } catch (error) { console.log('An error occurred while connecting to API'); }
      }

      async getBrokersList(){

          try {

            const serverResponse = await tradeService.getBrokersList()
        
            if(serverResponse.statusCode !== "200" || serverResponse.data === undefined) throw Error('');
    
            if(typeof serverResponse.data !== 'object') throw Error('');
    
              this.setState({ brokerConfig : serverResponse.data })
    
              store.set('brokers_list',serverResponse.data, new Date().getTime() + 8 * 60 * 60 * 1000);
      
         } catch (error) { console.log('An error occurred while connecting to API'); }
      }
      
      async getUserStrategy(data){

        this.setState({ loader : true });

        try {

          const serverResponse = await tradeService.getUserStrategy(data);

            if(serverResponse.data.statusCode === "200") this.setState({ 
                mountRestrictedStep : serverResponse.data.data.isSubscribe === 'YES' ? true : false, /** restricted step will mount if user is subscribed */
                mountSteps : serverResponse.data.data.isSubscribe === 'YES' ? false : true, /** normal step will mount if user is not subscribed */
                loader : false, updateID : serverResponse.data.data._id,
                steps : { ...this.state.steps, index : serverResponse.data.data.isSubscribe === 'YES' ? 3 : 0, }, /* update index value so submit can load success step */
                updateStrategy      : { status : true , payload : serverResponse.data.data.payload }, /** store strategy information */

              });

            this.forceUpdate(); 

          } catch (error) { this.props.history.push('/manage-strategy'); console.log('error occurred while calling API') }
      }
  
      loadSymbols(value) { 
          tradeService.symbolList({'exchange': value})
              .then((result) => { this.setState({ symbolData: result }); })
              .catch((error) => { console.log('error occurred while calling API') });
       }

       loadIndicators() {

          const indicators = (() => { 

              if(store.get('formulas_list') === undefined) return [];

              if(Object.keys(store.get('formulas_list')).length === 0) return [] 
      
              return Object.keys(store.get('formulas_list')).map((data,idx) => { return { label:store.get('formulas_list')[data].label,value:store.get('formulas_list')[data].key } });
        
            })()

          return this.setState({ indicators })
      }

  // ------------------------------------------------------------------------------------------



  redirectToDashboard() { this.props.history.push('/dashboard'); }

  stepValidator(step,validationComplete,stepPayload) /* Steps Method Validation */ {

    this.payload = { ...this.payload, [step] : stepPayload } // store step data

    if(step !== 'stepFour') this.setState({ steps: { ...this.state.steps, [validationComplete] : true  } }) // unlock the next tab

    if(step === 'stepFour'){ this.submitBuildStrategy(this.payload) }

    else this.stepsHandler('next');
    
  }

  resetSteps() /* Steps Method */ { window.location.reload(); }

  stepsHandler(step) /* Steps Method */ {

      this.setState((state) => { 
        
          const index = state.steps.index
          const steps = state.steps.steps
          
            state.steps.currentStep  = step === 'previous' ? steps[index - 1] : steps[index + 1]
            state.steps.activeStep   = step === 'previous' ? steps[index - 1] : steps[index + 1] 
            state.steps.nextStep     = step === 'previous' ? steps[index]     : steps[index + 2] 
            state.steps.previousStep = step === 'previous' ? steps[index - 2] : steps[index] 
            state.steps.index        = step === 'previous' ? index - 1 : index + 1 ;

          return state;  
      });
  }

  previousStep() /* Steps Method */{

      if(this.state.steps.index === 0) return true;

      this.stepsHandler('previous');
  }

  nextStep() /* Steps Method */{
      
      if(this.state.steps.index === 4) return true;

      this.stepsHandler('next');
  }


  async submitBuildStrategy(strategy){

    this.setState({ loader : true });

    const payload = {
        username:store.get('user_data').username,
        deploy_status  : "NA",
        isSubscribe    : "NO",
        original_code  : "NA",
        ...strategy.stepOne,
        ...strategy.stepTwo,
        ...strategy.stepThree,
        ...strategy.stepFour,
    }

    try {
      
          if(this.state.updateID !== undefined) payload._id = this.state.updateID

          const serverResponse =  await tradeService.buildStrategy(payload)

              if (serverResponse.statusCode !== "200") throw Error('error');

              const tradeSignalData  = {
                  username  : store.get('user_data').username,
                  code      : serverResponse.data,
                  exec_type : '0',
              }

              const tradeSignal = await tradeService.execStrategy(tradeSignalData);

              if(tradeSignal.statusCode !== '200') throw Error('error');

          this.setState({ status : true , responseMessage : 'success' })
      
      } catch (error) { this.setState({ status : false , responseMessage : 'error occurred while calling API' }) }

        this.setState({ steps: { ...this.state.steps, stepFourValidationComplete : true } }) // unlock the next tab
        this.setState({ loader : false  })
        this.stepsHandler('next');
  }

  render() {

    return (
      <LoadingOverlay active={this.state.loader} spinner text="Loading content...">
        <div className="container-fluid">
        <form id="stepByStepForm" className="form">

            {  this.state.mountSteps === true &&

                <Header
                    stepOneValidationComplete={this.state.steps.stepOneValidationComplete}
                    stepTwoValidationComplete={this.state.steps.stepTwoValidationComplete}
                    stepThreeValidationComplete={this.state.steps.stepThreeValidationComplete}
                    stepFourValidationComplete={this.state.steps.stepFourValidationComplete}
                ></Header>
            }

            <div className="border-shadow">

                  { this.state.mountRestrictedStep === true && <RestrictedStep submitBuildStrategy={this.submitBuildStrategy} updateStrategy={this.state.updateStrategy} activeStep={this.state.steps.activeStep} brokers={this.state.brokers}></RestrictedStep> }
                 
                  { this.state.mountSteps === true &&  <StepOne   payload={this.payload} updateStrategy={this.state.updateStrategy} activeStep={this.state.steps.activeStep} stepValidator={this.stepValidator} brokers={this.state.brokers}    ></StepOne> }    {/* Strategy details */}
                  { this.state.mountSteps === true &&  <StepTwo   payload={this.payload} updateStrategy={this.state.updateStrategy} activeStep={this.state.steps.activeStep} stepValidator={this.stepValidator} symbols={this.state.symbolData} indicators={this.state.indicators} formulas={this.state.formulas} previous={this.previousStep}></StepTwo> }    {/* Data selection  */ }
                  { this.state.mountSteps === true &&  <StepThree payload={this.payload} updateStrategy={this.state.updateStrategy} activeStep={this.state.steps.activeStep} stepValidator={this.stepValidator} previous={this.previousStep} ></StepThree> }  {/* Entry Price     */ }
                  { this.state.mountSteps === true &&  <StepFour  payload={this.payload} updateStrategy={this.state.updateStrategy} activeStep={this.state.steps.activeStep} stepValidator={this.stepValidator} previous={this.previousStep} ></StepFour>  }  {/* Order Execution */ }

                  <StepFive updateStrategy={this.state.updateStrategy} activeStep={this.state.steps.activeStep} 
                            status={this.state.status} message={this.state.responseMessage} 
                            update={(this.state.updateID !== undefined)}
                  ></StepFive>

                  <Footer 
                      index={this.state.steps.index}
                      next={this.nextStep} 
                      submit={this.submitBuildStrategy} 
                      previous={this.previousStep}
                      redirect={this.redirectToDashboard}
                      reset={this.resetSteps}
                      nextStep={this.state.steps.nextStepAccess}
                      status={this.state.status}
                  ></Footer>
            </div>
        </form>
        </div>
      </LoadingOverlay>
    );
  }
}

export default BuildStrategy;


